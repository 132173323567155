var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "w-100" },
      [
        _vm.isActiveHeaderGlobalOption
          ? [
              _vm.isMountedHeaderLocation
                ? _c(
                    "div",
                    {
                      staticClass: "scroll-container",
                      attrs: { id: "headerBarOption" },
                      on: { scroll: _vm.handleHeaderScroll },
                    },
                    [
                      _c("div", {
                        staticClass: "scroll-item-sticky",
                        style: {
                          left: "0 !important",
                          width: `${_vm.accessChildrenRowWidthSet()}px`,
                        },
                        attrs: {
                          id: "row-header-hide-row-position-fixed-scroll",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "scroll-item-start",
                          style: {
                            width: `${_vm.accessChildrenRowWidthSetByLocation(
                              0
                            )}px`,
                          },
                          attrs: {
                            id: "row-header-hide-row-position-scroll-start-scroll",
                          },
                        },
                        [
                          _vm.checkNexDateWhenStartActive()
                            ? _c(
                                "button",
                                {
                                  attrs: {
                                    id: `popover-button-event-when-start-active`,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.handleHeaderOptionWhenStartActive.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v("\n\t\t\t\t\t\t+\n\t\t\t\t\t")]
                              )
                            : _vm._e(),
                          _vm.checkNexDateWhenStartActive()
                            ? _c(
                                "b-tooltip",
                                {
                                  ref: `tootlip-button-event-when-start-active`,
                                  attrs: {
                                    target: `popover-button-event-when-start-active`,
                                    placement: "right",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(
                                          1,
                                          "There are mask columns between these two dates"
                                        )
                                      ) +
                                      "\n\t\t\t\t\t"
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.checkNexDateWhenStartActive()
                            ? _c(
                                "b-popover",
                                {
                                  ref: `popover-button-event-when-start-active`,
                                  attrs: {
                                    "custom-class": "popover-header-option",
                                    target: `popover-button-event-when-start-active`,
                                    id: `popover-item-when-start-active`,
                                    placement: "top",
                                  },
                                },
                                [
                                  _c("list-hidden-column", {
                                    ref: `list-hidden-column-when-start-active`,
                                    on: {
                                      "popover:close-all":
                                        _vm.handleCloseOption,
                                      "type:close": _vm.handleCloseOption,
                                      "type:submitted": _vm.handleSubmitted,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._l(_vm.mapFields, function (item, _index) {
                        return _c(
                          "div",
                          {
                            key: _vm.generateSecureId(`option-${item.key}`),
                            class: {
                              "scroll-item": true,
                              "margin-before-last-header-active":
                                _index === _vm.mapFields.length - 2,
                              "margin-last-header-active":
                                _index === _vm.mapFields.length - 1,
                            },
                            style: {
                              width: `${_vm.accessChildrenRowWidthSetByLocation(
                                _index + 1
                              )}px`,
                            },
                            attrs: {
                              id: `row-header-hide-row-position-scroll-${_index}`,
                            },
                          },
                          [
                            _vm.checkNexDate(item, _index)
                              ? _c(
                                  "button",
                                  {
                                    attrs: {
                                      id: `popover-button-event-${item.securedId}`,
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.handleHeaderOption(item)
                                      },
                                    },
                                  },
                                  [_vm._v("\n\t\t\t\t\t\t+\n\t\t\t\t\t")]
                                )
                              : _vm._e(),
                            _vm.checkNexDate(item, _index)
                              ? _c(
                                  "b-tooltip",
                                  {
                                    ref: `tootlip-button-event-${item.securedId}`,
                                    refInFor: true,
                                    attrs: {
                                      target: `popover-button-event-${item.securedId}`,
                                      placement: "right",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.FormMSG(
                                            1,
                                            "There are mask columns between these two dates"
                                          )
                                        ) +
                                        "\n\t\t\t\t\t"
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.checkNexDate(item, _index)
                              ? _c(
                                  "b-popover",
                                  {
                                    ref: `popover-button-event`,
                                    refInFor: true,
                                    attrs: {
                                      "custom-class": "popover-header-option",
                                      target: `popover-button-event-${item.securedId}`,
                                      id: `popover-item-${item.key}`,
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c("list-hidden-column", {
                                      ref: `list-hidden-column`,
                                      refInFor: true,
                                      attrs: { keyOption: _index },
                                      on: {
                                        "popover:close-all":
                                          _vm.handleCloseOption,
                                        "type:close": _vm.handleCloseOption,
                                        "type:submitted": _vm.handleSubmitted,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "scroll-item-sticky",
                          style: { right: "0 !important" },
                          attrs: { id: "option-fixed-end" },
                        },
                        [
                          _vm.isActiveHeaderGlobalOption
                            ? _c(
                                "button",
                                {
                                  attrs: {
                                    id: `popover-button-event-global-option`,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.handleHeaderGlobalOption.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    _vm.getLucideIcon(_vm.ICONS.LIST_TODO.name),
                                    {
                                      tag: "component",
                                      attrs: {
                                        color: _vm.ICONS.LIST_TODO.color,
                                        size: 20,
                                      },
                                    }
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isActiveHeaderGlobalOption
                            ? _c(
                                "b-tooltip",
                                {
                                  ref: `tootlip-button-event-global-option`,
                                  attrs: {
                                    target: `popover-button-event-global-option`,
                                    placement: "right",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(
                                          400,
                                          "Configuration of all hidden columns"
                                        )
                                      ) +
                                      "\n\t\t\t\t\t"
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.isActiveHeaderGlobalOption
                            ? _c(
                                "b-popover",
                                {
                                  ref: `popover-button-event-global-option`,
                                  attrs: {
                                    "custom-class": "popover-header-option",
                                    target: `popover-button-event-global-option`,
                                    id: `popover-item-global-option`,
                                    placement: "top",
                                  },
                                },
                                [
                                  _c("list-hidden-column", {
                                    ref: `list-hidden-column-global-option`,
                                    on: {
                                      "popover:close-all":
                                        _vm.handleCloseOption,
                                      "type:close": _vm.handleCloseOption,
                                      "type:submitted": _vm.handleSubmitted,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ]
          : _vm._e(),
        [
          _vm.tsfields.length !== 0
            ? _c(
                "b-table-simple",
                {
                  directives: [
                    {
                      name: "click-outside",
                      rawName: "v-click-outside",
                      value: _vm.onOutsideClick,
                      expression: "onOutsideClick",
                    },
                  ],
                  ref: "my-table",
                  style: { "min-height": _vm.minHeight + "px" },
                  attrs: {
                    small: "",
                    "caption-top": "",
                    responsive: "",
                    "sticky-header": `${_vm.getHeaderHeight}px`,
                    "sticky-column": "first",
                  },
                },
                [
                  _c(
                    "b-thead",
                    [
                      _c(
                        "b-tr",
                        { attrs: { id: "headBar" } },
                        [
                          _c(
                            "b-th",
                            {
                              directives: [
                                {
                                  name: "column-resize-right",
                                  rawName: "v-column-resize-right",
                                },
                              ],
                              staticClass:
                                "text-center middle row-header-fixed resizable",
                              style: { left: 0 },
                              attrs: { id: "headRefLeft" },
                              on: { mouseover: _vm.handleStopDragging },
                            },
                            [
                              _c("header-row-tables", {
                                attrs: {
                                  isFixedStart: true,
                                  isShowSomme: false,
                                  initCustomFilter: _vm.initCustomFilter,
                                  customFilterValue: _vm.customFilterValue,
                                  handleAddNewUser: _vm.handleAddNewUser,
                                  widthCell: _vm.widthCell,
                                  sizeCell: _vm.sizeCell,
                                  isFixedColumn: true,
                                  isProduction: _vm.isProduction,
                                },
                                on: {
                                  "filter-handle-user": _vm.handleFilterUser,
                                  "handle-reset-init-custom-filter":
                                    _vm.handleResetInitCustomFilter,
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._l(_vm.mapFields, function (item, _index) {
                            return _c(
                              "b-th",
                              {
                                directives: [
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.bottom.html",
                                    value:
                                      _vm.isToday(item.key) &&
                                      _vm.returnHeaderRow(),
                                    expression:
                                      "isToday(item.key) && returnHeaderRow()",
                                    modifiers: {
                                      hover: true,
                                      bottom: true,
                                      html: true,
                                    },
                                  },
                                  {
                                    name: "column-hover",
                                    rawName: "v-column-hover",
                                  },
                                  {
                                    name: "column-resize",
                                    rawName: "v-column-resize",
                                  },
                                ],
                                key: _vm.generateSecureId(`${item.key}`),
                                class: {
                                  "text-center middle row-header-locations resizable": true,
                                  "bg-header-today": _vm.isToday(item.key),
                                  "default-location": true,
                                },
                                attrs: { id: `headRefLocation-${_index}` },
                                on: { mouseover: _vm.handleStopDragging },
                              },
                              [
                                _c("header-row-tables", {
                                  ref: `row-header-${_index}`,
                                  refInFor: true,
                                  attrs: {
                                    item: item,
                                    index: _index,
                                    isFixedStart: false,
                                    isShowSomme: false,
                                    onActiveHideColumn: (item) =>
                                      _vm.hideColumn(item, _index),
                                    onOpenFlagHeader: (item) =>
                                      _vm.handleOpenFlagHeader(item),
                                    widthCell: _vm.widthCell,
                                    sizeCell: _vm.sizeCell,
                                    isFixedColumn: false,
                                    isProduction: _vm.isProduction,
                                  },
                                }),
                              ],
                              1
                            )
                          }),
                          _c(
                            "b-th",
                            {
                              directives: [
                                {
                                  name: "column-resize-left",
                                  rawName: "v-column-resize-left",
                                },
                              ],
                              staticClass:
                                "text-center middle row-header-fixed resizable",
                              style: { right: 0 },
                              attrs: { id: "headRefRight" },
                            },
                            [
                              _c("header-row-tables", {
                                attrs: {
                                  isShowSomme: true,
                                  onHandleShowContentVerified:
                                    _vm.handleShowContentVerified,
                                  showVerified: _vm.showVerified,
                                  widthCell: _vm.widthCell,
                                  sizeCell: _vm.sizeCell,
                                  isFixedColumn: true,
                                  isProduction: _vm.isProduction,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-tbody",
                    {
                      ref: "tableRef",
                      on: {
                        mousedown: _vm.startScrollingLeft,
                        mouseup: _vm.stopScrollingLeft,
                      },
                    },
                    [
                      _vm.mapPrestations.length === 0
                        ? _c(
                            "b-tr",
                            [
                              _c(
                                "b-td",
                                {
                                  staticClass:
                                    "text-center border-left init-background default-data-empty",
                                  attrs: {
                                    colspan:
                                      _vm.tsfields.length === 0
                                        ? 2
                                        : _vm.tsfields.length + 1,
                                  },
                                },
                                [
                                  _c("div", [
                                    _c(
                                      "div",
                                      { staticClass: "text-muted fs-14 w-100" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            this.FormMSG(2, "No data available")
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._l(_vm.mapPrestations, function (item, rowIndex) {
                        return _c(
                          "b-tr",
                          {
                            key: rowIndex,
                            staticStyle: { background: "#f3f4f5" },
                          },
                          [
                            _c(
                              "b-td",
                              {
                                directives: [
                                  { name: "row-hover", rawName: "v-row-hover" },
                                ],
                                staticClass:
                                  "sticky-column-body-left default-data-no-empty",
                              },
                              [
                                _c("cell-fixed-tables-member", {
                                  attrs: {
                                    item: item,
                                    isCheckValidatedAll:
                                      _vm.checkValidatedAll(item),
                                    isHaveSolved: _vm.haveSolved(item),
                                    isProduction: _vm.isProduction,
                                    isCheckNotSubmitted:
                                      _vm.checkNotSubmitted(item),
                                    onValidateAllItem: (data) =>
                                      _vm.validateAllTsDays(data, rowIndex),
                                    onSendAllForValidation: (data) =>
                                      _vm.sendAllForValidation(data, rowIndex),
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._l(_vm.mapFields, function (data, columnIndex) {
                              return _c(
                                "b-td",
                                {
                                  key: _vm.secureIdsForItem(data.key, item),
                                  class: _vm.getCellClass(
                                    data.key,
                                    item.tsDays
                                  ),
                                  attrs: {
                                    id: _vm.secureIdsForItem(data.key, item),
                                  },
                                  on: {
                                    contextmenu: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.onActiveContextMenu(
                                        $event,
                                        data,
                                        item,
                                        rowIndex,
                                        columnIndex
                                      )
                                    },
                                    dblclick: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return ((e) =>
                                        _vm.handleDoubleClick(
                                          e,
                                          item,
                                          data.key,
                                          data,
                                          rowIndex,
                                          columnIndex
                                        )).apply(null, arguments)
                                    },
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return ((e) =>
                                        _vm.handleAllActionPlanning(
                                          e,
                                          item,
                                          data.key,
                                          data,
                                          rowIndex,
                                          columnIndex
                                        )).apply(null, arguments)
                                    },
                                    mousedown: (e) => _vm.startDrag(e),
                                    mouseover: (e) =>
                                      _vm.selectCell(
                                        e,
                                        item,
                                        data.key,
                                        data,
                                        rowIndex,
                                        columnIndex
                                      ),
                                    mouseup: (e) => _vm.endDrag(e),
                                  },
                                },
                                [
                                  _c("cell-info", {
                                    ref: `cell-info-${columnIndex}`,
                                    refInFor: true,
                                    attrs: {
                                      id: `item-cell-info-${columnIndex}`,
                                      isRefCellInfo: `cell-info`,
                                      generateElementHeaderRow:
                                        _vm.generateElementHeaderRow(
                                          columnIndex
                                        ),
                                      isCheckCell: _vm.checkCell(
                                        data.key,
                                        item.tsDays
                                      ),
                                      labelStatus: _vm.getLabelStatus(
                                        _vm.getStatus(data.key, item.tsDays)
                                      ),
                                      labelStatusColor: _vm.getStatusColor(
                                        _vm.getStatus(data.key, item.tsDays)
                                      ),
                                      isPerDiem: _vm.isPerDiem,
                                      isAnalyTicalCode: _vm.isAnalyTicalCode,
                                      isPrestations: _vm.isPrestations,
                                      currentValidationLevel:
                                        _vm.currentValidationLevel,
                                      isProduction: _vm.isProduction,
                                      fimalacCellItem: _vm.fimalacCellItem(
                                        data.key,
                                        item.tsDays
                                      ),
                                      isShowBarStatus: _vm.isShowBarStatus(
                                        data.key,
                                        item.tsDays
                                      ),
                                      keyCell: data.key,
                                      arrayTsDays: item.tsDays,
                                      widthCell: _vm.widthCell,
                                      sizeCell: _vm.sizeCell,
                                      indexField: columnIndex,
                                      uniqueID: _vm.secureIdsForItem(
                                        data.key,
                                        item
                                      ),
                                      setTextInfoBullWorkFlow: (text) =>
                                        _vm.setTextInfoBullWorkFlow(text),
                                      isGestionnaryOfPaie:
                                        _vm.isGestionnaryOfPaie,
                                    },
                                    on: {
                                      "on-refresh-planning":
                                        _vm.onRefreshParent,
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                            _vm.showVerified
                              ? _c(
                                  "b-td",
                                  {
                                    staticClass:
                                      "sticky-column-body-right default-body-right",
                                    attrs: { id: "bodyRight" },
                                  },
                                  [
                                    _c("cell-fixed-tables-paie", {
                                      attrs: {
                                        item: item,
                                        isProduction: _vm.isProduction,
                                        isGrouping: false,
                                        widthCell: _vm.widthCell,
                                        sizeCell: _vm.sizeCell,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.showVerified === false
                              ? _c(
                                  "b-td",
                                  {
                                    staticClass:
                                      "sticky-column-body-right-init default-row-some",
                                    attrs: { id: "bodyRight" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.handleShowContentVerified.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("div", {
                                      staticClass:
                                        "d-flex flex-row justify-content-center align-items-center",
                                      staticStyle: { width: "27px" },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          2
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-row justify-content-center align-items-center card-gray text-muted fs-14 w-100",
                },
                [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(this.FormMSG(2, "No data available")) +
                      "\n\t\t\t"
                  ),
                ]
              ),
          _vm.tsfields.length !== 0 && _vm.isBusy
            ? _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-center align-items-center",
                  staticStyle: { height: "20px", gap: "5px" },
                },
                [
                  _c("div", { staticClass: "loading" }, [
                    _vm._v(_vm._s(this.FormMSG(9, "Loading"))),
                  ]),
                  _c("div", { staticClass: "bars-5" }),
                ]
              )
            : _vm.tsfields.length !== 0
            ? _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-row justify-content-center align-items-center",
                  staticStyle: { height: "20px", position: "relative" },
                },
                [
                  _c("div", { staticClass: "end-of-list" }, [
                    _vm._v(_vm._s(_vm.sizeOfUsers())),
                  ]),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "show-total" },
            [
              _c("div", { staticClass: "w-75" }),
              _c("cell-fixed-tables-paie", {
                attrs: {
                  totalGrouping: _vm.totalGrouping,
                  isProduction: _vm.isProduction,
                  isGrouping: true,
                  showVerified: _vm.showVerified,
                  widthCell: _vm.widthCell,
                  sizeCell: _vm.sizeCell,
                },
              }),
            ],
            1
          ),
        ],
        [_c("context-menu", { ref: "ContextMenu" })],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }