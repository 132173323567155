<template>
	<div class="d-flex flex-column">
		<div class="d-flex flex-row" style="background-color: #f3f4f5">
			<div class="d-flex flex-column justify-center align-items-center w-18">
				<div class="content-c1 gap-10">
					<div>
						<MonthSelect :DateDefaultSelect="setDefaultDate" @setDateDefaultSelect="setDateDefaultSelect" />
					</div>
					<div>
						<b-button variant="outline-primary" @click="handleClickReference">
							<span class="text-btn">{{ FormMSG(9, 'Preference') }}</span>
						</b-button>
					</div>
					<div class="d-flex flex-wrap mr-4 ml-4 active">
						<treeselect
							:options="mapCode"
							v-model="selected_code"
							:value="selected_code"
							:disable-branch-nodes="true"
							:clearable="clearable"
							@input="handleChangeCode"
							@select="codeChanged"
						>
							<div slot="value-label" slot-scope="{ node }" :title="node.label">
								<div>{{ setTextCategoriesWithActivities(node.label) }}</div>
							</div>
							<div slot="option-label" slot-scope="{ node }">
								<div class="treeselect-label" :title="node.label">{{ node.label }}</div>
							</div>
						</treeselect>
					</div>
				</div>
			</div>
			<div class="divider-vertical w-82">
				<div class="d-flex align-items-start p-2 flex-column w-100">
					<div class="d-flex flex-row w-100" style="height: 50px">
						<div class="d-flex flex-wrap flex-row w-80 p-2">
							<div class="d-flex flex-row flex-wrap p-2 w-75 active">
								<div class="w-85">
									<treeselect
										:options="mapCategories"
										v-model="selected_categorie"
										:value="selected_categorie"
										:disable-branch-nodes="true"
										:placeholder="FormMSG(2, 'Select an categories')"
										@input="handleChangeCategories"
										@select="categoriesChanged"
										:default-expand-level="1"
									>
										<div slot="value-label" slot-scope="{ node }" :title="node.label">
											<div>{{ setTextCategoriesWithActivities(node.label) }}</div>
										</div>
										<div slot="option-label" slot-scope="{ node }">
											<div class="treeselect-label" :title="node.label">{{ node.label }}</div>
										</div>
									</treeselect>
								</div>
								<div class="d-flex flex-wrap mt-0 w-15 active">
									<button class="btn-category" v-if="showAllMapCategorie" @click="handleShowMadCategories">
										<span v-if="!isLargeDeskTop" class="item-text w-trimestre">{{ FormMSG(35, 'By trimestre') }}</span>
										<div v-else v-b-tooltip.hover.top:title="FormMSG(35, 'By trimestre')">
											<component :is="getLucideIcon(ICONS.CLOCK.name)" color="#FFFFFF" :size="20" />
										</div>
									</button>
									<button class="btn-category" v-else @click="handleShowMadCategories">
										<span v-if="!isLargeDeskTop" class="item-text w-show-all">{{ FormMSG(34, 'Show all') }}</span>
										<div v-else v-b-tooltip.hover.top:title="FormMSG(34, 'Show all')">
											<component :is="getLucideIcon(ICONS.VIEW.name)" color="#FFFFFF" :size="20" />
										</div>
									</button>
								</div>
							</div>
							<div class="d-flex flex-wrap p-2 w-25 active">
								<treeselect
									:options="mapActivities"
									:value="activity"
									:placeholder="FormMSG(3, 'Select an activities')"
									@input="handleChangeActivities"
									@select="activitiesChanged"
								>
									<div slot="option-label" slot-scope="{ node }">
										<div class="treeselect-label" :title="node.label">{{ node.label }}</div>
									</div>
								</treeselect>
							</div>
						</div>
						<div class="d-flex align-items-start w-20 p-2">
							<div class="d-flex justify-content-end mt-1 w-100" ref="headerFlagItem">
								<b-button
									@click="onOpenModalToAllDelete"
									:disabled="isRazSelection"
									variant="danger"
									size="sm"
									class="btn-custom mr-2"
									:style="defaultStyle(false)"
									v-b-tooltip.hover.bottom.html="`${isLargeDeskTop ? getTextLabel() + ' ' + countItemToDeletes(dataPlanningSelect) : ''}`"
								>
									<component id="lock-planning-info" :is="getLucideIcon(ICONS.TRASH.name)" color="#FFFFFF" :size="20" />
									<span v-if="!isLargeDeskTop" class="text-btn">{{ getTextLabel() }} {{ countItemToDeletes(dataPlanningSelect) }}</span>
									<div
										v-if="!isLargeDeskTop && containsZeroAndOne"
										v-b-tooltip.hover.bottom.html="
											`${FormMSG(
												55,
												'The elements that you have selected contain elements that have been sent to pay, if you delete it, certain element must go to ask for delete'
											)}`
										"
									>
										<component id="contains-zero-and-one" :is="getLucideIcon(ICONS.INFO.name)" color="#FFFFFF" :size="20" />
									</div>
								</b-button>
							</div>
						</div>
					</div>
					<div class="d-flex flex-row w-100">
						<div v-if="!isGestionnaryOfPaie" class="d-flex flex-wrap flex-row w-80 p-2">
							<template v-if="flags_options_favoris.length === 0">
								<div class="d-flex justify-content-center align-items-center w-100 text-muted fs-14 p-2">
									{{ FormMSG(26, 'No favorite flags selected') }}
								</div>
							</template>
							<template v-else>
								<div class="d-flex flex-wrap p-2 gap-10" v-for="(option, index) of flags_options_favoris" :key="index" id="flagItem">
									<flag-items :option="option" @setFlags="setFlags" />
								</div>
							</template>
						</div>
						<div class="d-flex align-items-start w-20 p-2">
							<div class="d-flex justify-content-end mt-1 w-100">
								<b-button
									v-if="!isGestionnaryOfPaie"
									:disabled="checkUserAuthorization"
									variant="primary"
									class="btn-custom mr-2"
									:style="defaultStyle(false)"
									size="sm"
									@click="handleClickAddSelection"
									v-b-tooltip.hover.bottom.html="`${isLargeDeskTop ? FormMSG(5, 'Add to selection') : ''}`"
								>
									<slot name="button-label">
										<component :is="getLucideIcon(ICONS.PLUS_CIRCLE_II.name)" :color="ICONS.PLUS_CIRCLE_II.color" :size="20" />
										<span v-if="!isLargeDeskTop" class="text-btn">{{ FormMSG(5, 'Add to selection') }}</span>
									</slot>
								</b-button>
							</div>
						</div>
					</div>
					<div class="d-flex flex-row w-100 mb-1">
						<div class="d-flex flex-row w-100">
							<div class="d-flex flex-row align-items-center w-80">
								<div class="d-flex flex-wrap w-50 ml-3 active">
									<treeselect
										v-if="!isGestionnaryOfPaie"
										:options="mapOtherRubrique"
										:value="selected_no_favoris"
										:placeholder="FormMSG(6, 'Select other rubrique')"
										:disabled="mapOtherRubrique.length === 1"
										@input="handleSelectedNoFavoris"
										:multiple="true"
									>
										<div
											slot="value-label"
											slot-scope="{ node }"
											:title="setTextValue(node.label)"
											class="d-flex align-items-center justify-content-center"
											style="height: 12px !important"
											v-b-tooltip.hover.bottom.html="`${setTextGlobal(node.label)}`"
										>
											<div style="font-size: 10px !important">{{ setTextValue(node.label) }}</div>
										</div>
										<div slot="option-label" slot-scope="{ node }">
											<div class="treeselect-label" :title="setText(node.label)">{{ setText(node.label) }}</div>
										</div>
									</treeselect>
								</div>
							</div>
							<div class="d-flex flex-row align-items-start position-relative w-20">
								<div class="d-flex flex-row justify-content-end ml-1 p-2 w-100">
									<b-button
										variant="success"
										class="btn-custom mr-2"
										:style="defaultStyle(lockPlanning)"
										:disabled="lockPlanning"
										size="sm"
										id="menu-to-validate-all"
										tabindex="0"
										v-b-tooltip.hover.bottom.html="
											`${isLargeDeskTop ? (isProduction ? FormMSG(27, 'Validate all planning') : FormMSG(28, 'Send all planning')) : ''}`
										"
									>
										<slot name="button-label-validate-all">
											<div
												class="d-flex align-items-center justify-content-center w-100"
												:style="{
													width: !isLargeDeskTop ? `calc(${defaultStyle(lockPlanning).width} - 10px) !important` : '',
													maxWidth: !isLargeDeskTop ? '200px !important' : ''
												}"
											>
												<div
													:class="{
														'd-flex align-items-center justify-content-center': true,
														'w-30 ml--30px': !isLargeDeskTop && !lockPlanning,
														'w-32 ml--10px': !isLargeDeskTop && lockPlanning,
														'w-100': isLargeDeskTop
													}"
												>
													<component :is="getLucideIcon(ICONS.CHECK_SQUARE.name)" color="#FFFFFF" :size="20" />
												</div>
												<div
													:class="{
														'd-flex align-items-center justify-content-start': true,
														'w-70': !lockPlanning,
														'w-66': lockPlanning
													}"
													v-if="!isLargeDeskTop"
												>
													<div class="d-flex flex-row w-100">
														<div class="d-flex align-items-center justify-content-center w-75">
															{{ isProduction ? FormMSG(27, 'Validate all planning') : FormMSG(28, 'Send all planning') }}
														</div>
														<div class="d-flex align-items-center justify-content-center w-25">
															<component :is="getLucideIcon(ICONS.CHEVRON_DOWN.name)" color="#FFFFFF" :size="20" />
														</div>
													</div>
												</div>
											</div>
										</slot>
										<div v-if="lockPlanning" class="d-flex position-absolute">
											<component :is="getLucideIcon('Lock')" color="#000000" :size="25" :stroke-width="1.5" />
										</div>
									</b-button>
									<div
										v-if="lockPlanning"
										class="d-flex align-items-center justify-content-center cursor-pointer prx-10"
										v-b-tooltip.hover.bottom.html="
											isGestionnaryOfPaie ? FormMSG(201, 'Planning is locked, you can unlocked that') : FormMSG(200, 'Planning is locked')
										"
									>
										<b-button class="custom-but-unlock" v-if="isGestionnaryOfPaie" variant="light" @click.prevent.stop="unLockedPlanning">
											<div v-html="getLucideIconServer()"></div>
										</b-button>
										<component v-else :is="getLucideIcon(ICONS.INFO.name)" :color="ICONS.INFO.color" :size="20" />
									</div>
									<b-popover target="menu-to-validate-all" variant="light" triggers="focus" placement="bottom" custom-class="custom-popover">
										<button
											class="content-item-popover"
											v-if="showGrouping.INITIAL"
											:disabled="mapActive.INITIAL"
											@click="validateAllPlanning(ACTION_PLANNING_FLUX.INITIAL)"
										>
											<div class="popover-item-icon">
												<component :is="getLucideIcon('CalendarDays')" color="#06263e" :size="20" />
											</div>
											<div class="popover-item-text text-1">
												{{ FormMSG(42, 'Initial') }}
											</div>
										</button>
										<button
											v-if="showProgress"
											class="content-item-popover"
											:disabled="mapActive.INPROGRESS"
											@click="validateAllPlanning(ACTION_PLANNING_FLUX.INPROGRESS)"
										>
											<div class="popover-item-icon">
												<component :is="getLucideIcon('CalendarSearch')" color="#06263e" :size="20" />
											</div>
											<div class="popover-item-text text-1">
												{{ FormMSG(43, 'In progress') }}
											</div>
										</button>
										<button
											class="content-item-popover"
											:disabled="mapActive.FINAL"
											@click="validateAllPlanning(ACTION_PLANNING_FLUX.FINAL)"
										>
											<div class="popover-item-icon">
												<component :is="getLucideIcon('CalendarCheck')" color="#06263e" :size="20" />
											</div>
											<div class="popover-item-text text-1">
												{{ FormMSG(44, 'Final') }}
											</div>
										</button>
									</b-popover>
								</div>
							</div>
						</div>
					</div>
					<div v-if="mapPlanningStatus !== null" class="d-flex flex-column w-100 mb-1">
						<div class="ml-3 mr-3">
							<b-alert show variant="primary">
								<div v-html="mapPlanningStatus"></div>
							</b-alert>
						</div>
					</div>
				</div>
			</div>
		</div>
		<b-modal
			v-model="openModalDeleteAll"
			@hidden="handleCloseAllDeleteModal"
			no-close-on-backdrop
			:title="FormMSG(21, 'Do you want to remove these timesheets')"
			modal-class="mui-animation"
			:fade="false"
		>
			<div v-if="groupByData && groupByData.length !== 0" class="d-flex flex-column gap-1 conten-item">
				<div class="d-flex flex-row align-items-center w-100 border-rd p-2" v-for="(item, index) of groupByData" :key="index">
					<div class="w-50">
						<label>{{ item.fullName }}</label>
					</div>
					<div class="d-flex flex-column border-lf w-50">
						<div class="text-1" v-for="(children, index) of item.data" :key="index">{{ children.date }}</div>
					</div>
				</div>
			</div>

			<template #modal-footer>
				<div class="d-flex flex-row justify-content-end w-100 gap-1 w-1">
					<div>
						<b-button variant="outline-primary" size="sm" class="border-1" @click="handleCloseAllDeleteModal"> {{ FormMSG(22, 'No') }} </b-button>
					</div>
					<div>
						<b-button variant="outline-danger" size="sm" class="border-1" @click="handleDeleteAllPlanning"> {{ FormMSG(23, 'Yes') }} </b-button>
					</div>
				</div>
			</template>
		</b-modal>
	</div>
</template>
<script>
import { store } from '@/store';
import { removeAttributeTree } from '@/shared/utils';
import FlagItems from '../planning/FlagItems.vue';
import MonthSelect from '../planning/MonthSelect.vue';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import Treeselect from '@riophae/vue-treeselect';
import { deleteEntry } from '@/cruds/timesheets-efc.crud';
import { serviceLockOrUnlockPlanning } from '@/cruds/timesheet.crud';
import {
	STATUS_VALIDATE_PLANNING,
	PLANNING_LEVEL,
	formatDatesInString,
	VALIDATION_TYPE,
	BREAKPOINTS,
	INTERNAL_STATUS,
	TYPE_ASK_TO_DELETE,
	ACTION_PLANNING_FLUX
} from '@/shared/constants';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { generateID } from '@/utils/utils';
import moment from 'moment';
import _ from 'lodash';

export default {
	name: 'HeaderFlag',
	components: { MonthSelect, FlagItems, Treeselect },
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	props: {
		previousPlanningStatus: {
			type: Number || null,
			required: false,
			default: null
		},
		lockPlanning: {
			type: Boolean,
			required: false,
			default: false
		},
		statusPlanningValidateAll: {
			type: Number,
			required: false
		},
		fromComponent: {
			type: String,
			required: false
		},
		dataPlanningSelect: {
			type: Array,
			required: false,
			default: () => []
		},
		dataGlobal: {
			type: Array,
			default: () => [],
			required: false
		},
		DateDefaultSelect: {
			type: Object,
			required: false,
			immediate: true
		},
		favoriteProjectFlagItems: {
			type: Array,
			required: false,
			default: () => []
		},
		isNotFavoriteProjectFlagItems: {
			type: Array,
			required: false,
			default: () => []
		},
		activities: {
			type: Array,
			required: false,
			default: () => []
		},
		categories: {
			type: Array,
			required: false,
			default: () => []
		},
		currentValidationLevel: {
			type: Number,
			required: false,
			default: () => {
				return 0;
			}
		},
		trimestre: {
			type: String,
			required: false,
			default: ''
		},
		isGestionnaryOfPaie: {
			type: Boolean,
			required: false,
			default: () => false
		},
		manager: {
			type: String,
			required: false,
			default: ''
		},
		userValidationLevel: {
			type: Number || null,
			required: false,
			default: null
		},
		planningLevel: {
			type: Number || null,
			required: false,
			default: null
		},
		finalizationDate: {
			type: String,
			required: false,
			default: ''
		},
		planningOption: {
			type: Object || null,
			required: false,
			default: () => []
		}
	},
	data: () => {
		return {
			selected_categorie: null,
			selected_code: 1,
			check_no_favoris: false,
			selected_no_favoris: null,
			persist_no_favori_select: [],
			flags_options_favoris: [],
			flags_options_no_favoris: [],
			flags_selected: [],
			dataToDeleteAllPlanning: [],
			activity: null,
			category: null,
			openModalDeleteAll: false,
			payloadCategories: null,
			payloadCode: null,
			payloadActivities: null,
			fromPosition: 'FROM-HEADER',
			clearable: false,
			isAuthValidateAll: false,
			showAllMapCategorie: false,
			statusButtonAllPlanning: false,
			isLargeDeskTop: false,
			isAskText: false,
			containsZeroAndOne: false,
			ACTION_PLANNING_FLUX
		};
	},
	mounted() {
		window.addEventListener('resize', this.handleResize);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.handleResize);
	},
	created() {
		this.screenSizeFunc();
	},
	computed: {
		showGrouping() {
			const { MANAGER, CHARG_PROD, ADM_PROD } = PLANNING_LEVEL;
			const { DEFAULT_STATUS, INITIAL: STATUS_INITIAL, INPROGRESS: STATUS_INPROGRESS, FINAL: STATUS_FINAL } = STATUS_VALIDATE_PLANNING;
			const status = this.statusPlanningValidateAll;
			const validationLevel = this.currentValidationLevel;
			const defaultGrouping = { INITIAL: true, INPROGRESS: true, FINAL: true };
			if (this.currentValidationLevel !== ADM_PROD) {
				if (this.planningOption) {
					const { INITIAL, INPROGRESS, FINAL } = this.planningOption;
					const GROUPING_ACTIVE = { INITIAL, INPROGRESS, FINAL };
					if (validationLevel === MANAGER) {
						if (status === DEFAULT_STATUS) {
							return defaultGrouping;
						}
						if ([STATUS_INITIAL, STATUS_INPROGRESS, STATUS_FINAL].includes(status)) {
							return { ...defaultGrouping, INITIAL: false, INPROGRESS: true };
						}
						return { ...GROUPING_ACTIVE, INITIAL: false };
					}
					if (validationLevel === CHARG_PROD) {
						if (status === DEFAULT_STATUS) {
							return defaultGrouping;
						}
						if ([STATUS_INITIAL, STATUS_INPROGRESS, STATUS_FINAL].includes(status)) {
							return { ...defaultGrouping, INITIAL: false, INPROGRESS: true };
						}
						return { ...defaultGrouping, INITIAL: false };
					}
					return defaultGrouping;
				}
				return defaultGrouping;
			} else {
				return { INITIAL: false, INPROGRESS: false, FINAL: true };
			}
		},
		showProgress() {
			const { ADM_PROD } = PLANNING_LEVEL;
			const currentValidationLevel = this.currentValidationLevel;
			return currentValidationLevel === ADM_PROD ? false : this.showGrouping.INPROGRESS;
		},
		mapActive() {
			if (this.planningOption) {
				const { INITIAL, INPROGRESS, FINAL } = this.planningOption;
				return { INITIAL, INPROGRESS, FINAL };
			}
			return { INITIAL: true, INPROGRESS: true, FINAL: true };
		},
		mapPlanningStatus() {
			if (this.manager.length !== 0) {
				const langCurrent = this.currentLang;
				const typeOfActiveOfValidated = this.getTypePlanningLevel();
				const userLevel = this.userLevelType();
				return `${typeOfActiveOfValidated} ${userLevel} ${this.manager} ${this.FormMSG(14587, 'on')} <strong class="text-capitalize">${moment(
					this.finalizationDate
				)
					.utc()
					.locale(`${langCurrent}`)
					.format('DD MMMM YYYY')} ${this.FormMSG(14848, 'at')} ${moment(this.finalizationDate)
					.utc()
					.locale(`${langCurrent}`)
					.format('LT')}</strong>`;
			}
			return null;
		},
		setDefaultDate() {
			const defaultDate = {
				...this.DateDefaultSelect,
				text: moment(this.DateDefaultSelect.value, 'MM/YYYY').locale(this.currentLang).format('MMMM YYYY')
			};
			return defaultDate;
		},
		sizeButton() {
			const innerWidth = window.innerWidth;
			return Math.round(innerWidth / 19);
		},
		sizeTextBtn() {
			return 11;
		},
		groupByData() {
			if (this.openModalDeleteAll) {
				return this.dataToDeleteAllPlanning;
			}
		},
		isProduction() {
			return store.isAdmin();
		},
		checkUserAuthorization() {
			const { CHARG_PROD, ADM_PROD } = PLANNING_LEVEL;
			const isRazSelection = this.isGestionnaryOfPaie ? true : !this.lockPlanning;
			if (isRazSelection) {
				if (this.currentValidationLevel === CHARG_PROD) {
					return this.flags_selected.length !== 0 && this.category !== null && this.activity !== null && this.dataPlanningSelect.length !== 0
						? false
						: true;
				} else if (this.currentValidationLevel === ADM_PROD) {
					return true;
				} else {
					return this.flags_selected.length !== 0 && this.dataPlanningSelect.length !== 0 ? false : true;
				}
			}
			return true;
		},
		mapCode() {
			let options = [
				{ id: 0, label: this.FormMSG(32, 'Per rubrique') },
				{ id: 1, label: this.FormMSG(30, 'Analytical code') },
				{ id: 2, label: this.FormMSG(36, 'All') }
			];
			return options;
		},

		mapCategories() {
			const setUniqueMapCategories = (data) => {
				let uniqueCombinations = new Set();
				let uniqueData = data.filter((item) => {
					let combination = `${item.id}`;
					if (!uniqueCombinations.has(combination)) {
						uniqueCombinations.add(combination);
						return true;
					}
					return false;
				});
				return uniqueData;
			};
			const recursiveCategories = (data, targetValue, path = [], itemsId = new Set()) => {
				const paths = [];
				for (const item of data) {
					const _item = item.children
						? { ...item, children: [...item.children].filter((item) => item.label.toUpperCase().includes(targetValue.toUpperCase())) }
						: { ...item };
					const currentPath = [...path, _item];
					if (item.label.toUpperCase().includes(targetValue.toUpperCase()) && !itemsId.has(item.id)) {
						paths.push(currentPath);
						itemsId.add(item.id);
					}
					if (item.children) {
						const childPaths = recursiveCategories(item.children, targetValue, currentPath, itemsId);
						if (childPaths.length > 0) {
							paths.push(...childPaths);
						}
					}
				}
				return paths;
			};
			if (this.showAllMapCategorie === false) {
				let data = removeAttributeTree(this.categories, 'children', null);
				let globalCategories = [...recursiveCategories(data, this.trimestre)]
					.flat(Infinity)
					.filter((item) => item.children && item.label !== 'UNDEFINED -UNDEFINED ');
				let categories = [...setUniqueMapCategories(globalCategories)];
				let options = [{ id: generateID(), label: this.FormMSG(7, 'Select an categories'), isDisabled: true }, ...categories];
				if (options.length !== 0) {
					return options;
				}
				return [{ id: generateID(), label: this.FormMSG(41, 'No categorie'), isDisabled: true }];
			} else {
				let categories = [...removeAttributeTree(this.categories, 'children', null)].filter((item) => item.children);
				let options = [{ id: generateID(), label: this.FormMSG(7, 'Select an categories'), isDisabled: true }, ...categories].filter(
					(item) => item.label !== 'UNDEFINED -UNDEFINED '
				);
				return options;
			}
		},
		mapActivities() {
			let activities = this.activities.map((item) => ({ ...item, label: item.name + (item.short.length !== 0 ? `(${item.short})` : ``) }));
			let data = [{ id: generateID(), label: this.FormMSG(8, 'Select an activities'), isDisabled: true }, ...activities];
			return data;
		},
		mapOtherRubrique() {
			let otherRubrique = this.flags_options_no_favoris.map((item) => ({ ...item, label: item.short + '-' + item.name }));
			let data = [{ id: generateID(), label: this.FormMSG(6, 'Select other rubrique'), isDisabled: true }, ...otherRubrique];
			return data;
		},
		isRazSelection() {
			const { ADM_PROD } = PLANNING_LEVEL;
			const { FOR_LVL_ONE } = TYPE_ASK_TO_DELETE;
			const item = this.dataPlanningSelect.map(({ tsDay }) => tsDay).filter(Boolean);
			const sizeItemPlanning = item.length !== 0;
			const isExistTsDetailsAsk = item
				.map(({ tsDayDetails }) => {
					const childData = tsDayDetails;
					return childData.some(({ askToDelete }) => [FOR_LVL_ONE].includes(askToDelete));
				})
				.some((status) => status);
			const isExistAsks = item.every(({ askToDeletes }) => [FOR_LVL_ONE].includes(askToDeletes));
			const isAskED = isExistTsDetailsAsk ? false : isExistAsks;
			const isActiveRaz = sizeItemPlanning
				? this.isGestionnaryOfPaie
					? isAskED
					: !this.lockPlanning && this.currentValidationLevel !== ADM_PROD
				: false;
			if (isActiveRaz) {
				return false;
			}
			return true;
		}
	},
	watch: {
		dataGlobal: {
			handler(newVal) {
				const { NOT_SUBMITTED, REFUSED_LEVEL_1, VALIDATED_LEVEL_2 } = VALIDATION_TYPE;
				const { MANAGER, CHARG_PROD, ADM_PROD } = PLANNING_LEVEL;
				let responsesAll = [...newVal].map((item) => item.tsDays).flat(Infinity);
				if (responsesAll.length === 0) {
					this.statusButtonAllPlanning = true;
				} else {
					this.statusButtonAllPlanning = false;
				}
				let data = [...newVal]
					.map((item) => item.tsDays)
					.flat(Infinity)
					.map((item) => ({
						...item,
						isSolve: item.tsDayDetails
							.map((item) => {
								if (+item.activity.id === 0 || +item.category === 0) {
									return true;
								}
								return false;
							})
							.some((item) => item === false)
					}));
				if (this.isProduction) {
					let filterValue = null;
					if (this.currentValidationLevel === MANAGER) {
						filterValue = null;
						this.isAuthValidateAll = true;
					}
					if (this.currentValidationLevel === CHARG_PROD) {
						filterValue = 2;
					}
					if (this.currentValidationLevel === ADM_PROD) {
						filterValue = 8;
					}
					let result = data.filter(
						(item) =>
							item.validated !== NOT_SUBMITTED &&
							item.validated !== REFUSED_LEVEL_1 &&
							item.validated !== VALIDATED_LEVEL_2 &&
							item.validated !== filterValue &&
							item.askToDelete === false &&
							item.tsDayDetails.length !== 0
					);
					if (result.length === 0) {
						this.isAuthValidateAll = true;
					} else {
						let isHasSolved = result.map((item) => ({
							...item,
							isSolve: item.tsDayDetails
								.map((item) => {
									if (+item.activity.id === 0 || +item.category === 0) {
										return true;
									}
									return false;
								})
								.some((item) => item === false)
						}));
						if (isHasSolved.length !== 0) {
							this.isAuthValidateAll = false;
						} else {
							this.isAuthValidateAll = true;
						}
					}
				} else {
					let result = data.filter((item) => item.validated === NOT_SUBMITTED && item.askToDelete === false && item.tsDayDetails.length !== 0);
					if (result.length !== 0) {
						this.isAuthValidateAll = false;
					} else {
						this.isAuthValidateAll = true;
					}
				}
				return newVal;
			},
			immediate: true,
			deep: true
		},
		selected_code: {
			handler(newVal) {
				if (newVal === 1) {
					this.clearable = false;
				} else {
					this.clearable = true;
				}
				return newVal;
			},
			immediate: true,
			deep: true
		},
		favoriteProjectFlagItems: {
			handler(newVal) {
				this.flags_options_favoris = newVal.map((item) => ({
					...item,
					code: '#' + item.color,
					text: item.short.toString().toUpperCase()
				}));
				return newVal;
			},
			immediate: true,
			deep: true
		},
		isNotFavoriteProjectFlagItems: {
			handler(newVal) {
				let data = newVal.map((item) => ({
					...item,
					code: '#' + item.color,
					text: item.short.toString().toUpperCase()
				}));
				this.flags_options_no_favoris = data;
				return newVal;
			},
			immediate: true,
			deep: true
		},
		mapPlanningStatus: {
			handler(newVal) {
				return newVal;
			},
			immediate: true,
			deep: true
		},
		dataToDeleteAllPlanning: {
			handler(newVal) {
				if (newVal.length !== 0) {
					const { DEFAULT_INTERNAL, SENT_TO_PAY } = INTERNAL_STATUS;
					if (!this.isGestionnaryOfPaie) {
						const newItem = newVal
							.map(({ data }) => data)
							.flat()
							.map(({ item }) => item);
						const arrayStatus = newItem
							.map(({ internalStatus }) => {
								return internalStatus;
							})
							.filter((status) => !_.isNil(status));
						const checkED = arrayStatus.every((type) => type === SENT_TO_PAY);
						const containsZeroAndOne = (arr) => {
							return arr.includes(DEFAULT_INTERNAL) && arr.includes(SENT_TO_PAY);
						};
						this.containsZeroAndOne = containsZeroAndOne(arrayStatus);
						this.isAskText = checkED;
					}
				} else {
					this.isAskText = false;
					this.containsZeroAndOne = false;
				}
				return newVal;
			},
			immediate: true,
			deep: true
		}
	},
	methods: {
		unLockedPlanning() {
			const title = 'Please Confirm';
			const ok = 'YES';
			const cancel = 'NO';
			const message = 'Would you like to unlock planning';
			this.$bvModal
				.msgBoxConfirm(message, {
					title: title,
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: ok,
					cancelTitle: cancel,
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false,
					modalClass: 'mui-animation'
				})
				.then(async (value) => {
					if (value) {
						const date = formatDatesInString('01/' + this.DateDefaultSelect.value, 'YYYY-MM-DD') + 'T00:00:00Z';
						const lockPlanning = !this.lockPlanning;
						const response = await serviceLockOrUnlockPlanning(date, lockPlanning).then((res) => {
							if (res) {
								this.$emit('refersh-on-delete-not-submitted-all', true);
								return res;
							}
						});
						return response;
					}
				})
				.catch((err) => {
					throw new error(err);
				});
		},
		getLucideIconServer() {
			return `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-lock-keyhole-open"><circle cx="12" cy="16" r="1"/><rect width="18" height="12" x="3" y="10" rx="2"/><path d="M7 10V7a5 5 0 0 1 9.33-2.5"/></svg>`;
		},
		defaultStyle(lock) {
			const infoLockActive = document.getElementById('lock-planning-info');
			let widthLockInfoActive = 12;
			if (infoLockActive) {
				const { width } = infoLockActive.getBoundingClientRect();
				widthLockInfoActive += width;
			}
			const newWidth = lock ? this.sizeButton - widthLockInfoActive : this.sizeButton;
			return {
				width: `${newWidth + 100}px`,
				fontSize: `${this.sizeTextBtn}px`
			};
		},
		screenSizeFunc() {
			const { LARGE_DESKTOP } = BREAKPOINTS;
			const elementSideBar = document.getElementsByClassName('sidebar')[0];
			let sideBarWidth = 0;
			if (elementSideBar) {
				const { width: defaultWidthScreen } = elementSideBar.getBoundingClientRect();
				sideBarWidth = defaultWidthScreen;
			}
			const screenWidth = window.innerWidth - sideBarWidth;
			this.isLargeDeskTop = screenWidth <= LARGE_DESKTOP;
			return this.isLargeDeskTop;
		},
		handleResize(e) {
			e.preventDefault();
			this.screenSizeFunc();
			return this.sizeButton;
		},
		getTypePlanningLevel() {
			const { DEFAULT_STATUS, INITIAL, INPROGRESS, FINAL, CLOSED, LOCKED } = STATUS_VALIDATE_PLANNING;
			const max = Math.max(this.previousPlanningStatus, this.statusPlanningValidateAll);
			const status = max;
			const statusMap = {
				[DEFAULT_STATUS]: [50, 'Planning initialized by'],
				[INITIAL]: [50, 'Planning initialized by'],
				[INPROGRESS]: [52, 'Planning in progress by'],
				[FINAL]: [51, 'Planning finalized by'],
				[CLOSED]: [51, 'Planning finalized by'],
				[LOCKED]: [54, 'Planning locked by']
			};
			return statusMap[status] ? this.FormMSG(...statusMap[status]) : '';
		},
		userLevelType() {
			const { MANAGER, CHARG_PROD, ADM_PROD, PAI } = PLANNING_LEVEL;
			const status = this.userValidationLevel;
			const statusMap = {
				[MANAGER]: [500, 'manager'],
				[CHARG_PROD]: [501, 'production manager'],
				[ADM_PROD]: [502, 'production admin'],
				[PAI]: [503, 'payroll manager']
			};
			return statusMap[status] ? this.FormMSG(...statusMap[status]) : '';
		},
		handleShowMadCategories() {
			this.showAllMapCategorie = !this.showAllMapCategorie;
		},
		getTextLabel() {
			return this.isAskText ? this.FormMSG(60, 'ASK delete selection') : this.FormMSG(4, 'RAZ selection');
		},
		validateAllPlanning(value) {
			this.$emit('validateAllPlanning', value);
		},
		setText(text) {
			if (text !== undefined && text.length < 4) {
				return text + ' ...';
			}
			return text;
		},
		setTextValue(text) {
			if (text !== undefined) {
				if (text.length > 20) {
					return text.substring(0, 17) + ' ...';
				}
				return text.substring(0, 20);
			}
		},
		setTextGlobal(text) {
			if (text !== undefined) {
				return text;
			}
		},
		setTextCategoriesWithActivities(text) {
			if (this.payloadActivities !== null) {
				let short = this.payloadActivities.short;
				let textExact = text.toString().replace('$', short);
				return textExact;
			}
			return text;
		},
		handleCloseAllDeleteModal() {
			this.openModalDeleteAll = false;
			if (this.fromComponent === 'FROM-PLANNING') {
				this.$emit('init-persist-planning');
			}
		},
		onOpenModalToAllDelete() {
			this.openModalDeleteAll = true;
		},
		async handleDeleteAllPlanning(e) {
			this.$emit('on-show-is-loading', true);
			try {
				const tsDayID = this.dataToDeleteAllPlanning
					.map((item) => item.data)
					.flat()
					.map(({ id }) => +id);
				const undo = null;
				const result = await deleteEntry(tsDayID, undo)
					.then(() => {
						return true;
					})
					.catch(() => {
						return false;
					});
				return new Promise((resolve, _) => resolve(result)).then((res) => {
					const variant = res ? 'success' : 'warning';
					const content = {
						success: { title: [25, 'success'], message: [24, 'Timesheets item delete successfully'] },
						warning: { tittle: [250, 'Warning'], message: [40, 'Send Email an error'] }
					};
					this.$bvToast.toast(this.FormMSG(...content[variant].message), {
						title: this.FormMSG(...content[variant].title),
						variant,
						solid: true,
						toaster: 'b-toaster-top-center',
						autoHideDelay: 3000
					});
					return this.$emit('on-refresh-cell-planning-select');
				});
			} finally {
				this.openModalDeleteAll = false;
				this.$emit('on-close-is-loading', false);
			}
		},
		filterAuthItem(userInfo, arrays) {
			const { NOT_SUBMITTED } = VALIDATION_TYPE;
			if (!arrays.length) return [];

			const checkItem = (item) => +item.user.id === +userInfo.userId && formatDatesInString(item.date, 'DD/MM/YYYY') === userInfo.date;

			const baseFilter = (item) => checkItem(item) && item.tsDayDetails.length;
			const prodFilter = (item) =>
				(checkItem(item) && item.validated !== NOT_SUBMITTED && item.tsDayDetails.length) || (checkItem(item) && item.askToDelete);

			const filteredItems = arrays.filter(this.isProduction ? prodFilter : baseFilter);

			return filteredItems
				.map((item) => ({
					...item,
					solveStatus: this.isProduction ? this.isCheckTsDetails(item.tsDayDetails) : undefined
				}))
				.filter(Boolean)
				.map((item) => ({
					date: formatDatesInString(item.date, 'DD/MM/YYYY'),
					id: item.id,
					user: item.user,
					item
				}));
		},
		isCheckTsDetails(item) {
			if (item !== undefined) {
				let data = item.map((item) => {
					if (+item.activity.id === 0 && item.category === 0) {
						return true;
					}
					return false;
				});
				let response = data.some((status) => status === true);
				if (response) {
					return true;
				}
				return false;
			}
			return false;
		},
		countItemToDeletes(_data) {
			this.dataToDeleteAllPlanning = [];
			if (_data !== undefined && !this.isRazSelection) {
				let filterItem = _data.filter((item) => item.tsDay !== undefined);
				let data = filterItem.map((item) => ({
					date: item.date,
					userId: item.userId,
					fullName: item.fullName,
					data: item.dataAdd !== undefined ? item.dataAdd.tsDays : []
				}));
				let globalUserIdsUnique = [...new Set(data.map((item) => ({ userId: item.userId, date: item.date })))].map((item) => ({
					date: item.date,
					userId: item.userId
				}));
				let filterDataValid = this.dataGlobal.map((item) => item.tsDays).flat();
				let res = globalUserIdsUnique
					.map((item) => ({ ...item, data: this.filterAuthItem(item, filterDataValid) }))
					.map((item) => ({ ...item, fullName: item.data.length !== 0 ? item.data[0].user.name + ' ' + item.data[0].user.firstName : '' }))
					.filter((item) => item.data.length !== 0);
				let result = res.reduce((accumulator, current) => {
					let existingItem = accumulator.find((item) => item.userId === current.userId && item.fullName === current.fullName);
					if (existingItem) {
						existingItem.data.push(...current.data);
					} else {
						accumulator.push({
							data: [...current.data],
							userId: current.userId,
							fullName: current.fullName
						});
					}
					return accumulator;
				}, []);
				this.dataToDeleteAllPlanning = result;
				let size = result.map((item) => item.data).flat().length;
				return '(' + size.toString().padStart('2', '0') + ')';
			}
			return '(00)';
		},
		codeChanged(payload) {
			this.payloadCode = { ...payload };
		},
		categoriesChanged(payload) {
			let _payload = null;
			if (payload.data !== null) {
				_payload = { ...payload, id: +payload.data.ID };
			}
			this.payloadCategories = { ..._payload };
		},
		handleChangeCode(value) {
			if (value !== undefined) {
				this.selected_code = value;
				this.$emit('handle-change-affichage', this.selected_code);
			} else {
				setTimeout(() => {
					this.selected_code = 1;
					this.$emit('handle-change-affichage', this.selected_code);
				}, 10);
			}
		},
		handleChangeCategories(value, item) {
			if (value !== undefined) {
				this.category = value;
			} else {
				this.category = null;
			}
		},
		activitiesChanged(payload) {
			this.payloadActivities = { ...payload };
		},
		handleChangeActivities(value) {
			if (value !== undefined) {
				this.activity = value;
			} else {
				this.activity = null;
				this.payloadActivities = null;
			}
		},
		setDateDefaultSelect(_date) {
			this.$emit('changeDateDefaultSelect', _date);
		},
		handleClickAddSelection() {
			const data = {
				dataPlanningSelect: this.dataPlanningSelect,
				flags_selected: this.flags_selected,
				activity: this.payloadActivities,
				category: _.isNil(this.selected_categorie) ? null : this.payloadCategories
			};
			this.$emit('on-add-data:selection', data);
		},
		handleClickReference() {
			this.$emit('on-preference:open');
		},
		setFlags(flag) {
			if (flag.isCheck) {
				this.flags_selected = [...this.flags_selected, { ...flag }];
			} else {
				this.flags_selected = this.flags_selected.filter((_flag) => _flag.id !== flag.id);
			}
		},
		handleFilterNotFavoris(noFavoris) {
			if (noFavoris) {
				if (this.selected_no_favoris !== null) {
					let isFind = this.flags_options_no_favoris.find((item) => +item.id === +this.selected_no_favoris);
					if (isFind !== undefined) {
						this.flags_selected = [...this.flags_selected, isFind];
					}
				}
			} else {
				if (this.selected_no_favoris !== null) {
					let result = this.flags_selected.filter((_flag) => +_flag.id !== +this.selected_no_favoris);
					this.flags_selected = result;
				}
			}
		},
		handleSelectedNoFavoris(e) {
			if (e.length !== 0) {
				if (this.persist_no_favori_select.length !== 0) {
					for (let i = 0; i < e.length; i++) {
						if (this.persist_no_favori_select.length < e.length) {
							let data = this.persist_no_favori_select.find((_id) => +_id === +e[i]);
							if (data === undefined) {
								let isFind = this.flags_options_no_favoris.find((item) => +item.id === +e[i]);
								if (isFind !== undefined) {
									this.flags_selected = [...this.flags_selected, isFind];
								}
							}
						} else {
							let res = this.persist_no_favori_select
								.filter((x) => !e.includes(x))
								.concat(e.filter((x) => !this.persist_no_favori_select.includes(x)));
							for (let i = 0; i < res.length; i++) {
								this.flags_selected = this.flags_selected.filter((item) => +item.id !== +res[i]);
							}
						}
					}
					this.persist_no_favori_select = e;
				} else {
					for (let i = 0; i < e.length; i++) {
						let isFind = this.flags_options_no_favoris.find((item) => +item.id === +e[i]);
						if (isFind !== undefined) {
							this.flags_selected = [...this.flags_selected, isFind];
						}
					}
					this.persist_no_favori_select = e;
				}
			} else {
				for (let i = 0; i < this.persist_no_favori_select.length; i++) {
					this.flags_selected = this.flags_selected.filter((item) => item.id !== this.persist_no_favori_select[i]);
				}
				this.persist_no_favori_select = e;
			}
		}
	}
};
</script>
<style lang="scss">
.active {
	.vue-treeselect {
		.vue-treeselect__control {
			height: 26px !important;
			border-radius: 8px !important;
			.vue-treeselect__value-container {
				display: contents;
				.vue-treeselect__placeholder,
				.vue-treeselect__single-value {
					top: -4px;
				}
				.vue-treeselect__multi-value {
					.vue-treeselect__input-container {
						.vue-treeselect__input {
							height: 10px !important;
						}
					}
				}
			}
			.vue-treeselect__x-container {
				margin-right: 2px;
			}
		}
	}
}
#drop-down-menu button {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;
	background-color: #00af75 !important;
	border-color: #00af75 !important;
}
#drop-down-menu ul {
	z-index: 999 !important;
}
</style>
<style scoped lang="scss">
.custom-option {
	display: flex !important;
	height: 60px !important;
	padding: 10px !important;
	font-size: 14px;
	align-items: center;
}
.prx-10 {
	padding-right: 10px;
}
.btn-custom {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	gap: 10px;
}
.text-btn {
	display: flex;
	justify-content: center;
	align-items: start;
	width: 160px;
}
.ml--10px {
	margin-left: -10px;
}
.ml--30px {
	margin-left: -30px;
}
.w-30 {
	width: 30%;
}
.w-32 {
	width: 32%;
}
.w-66 {
	width: 66%;
}
.w-70 {
	width: 70%;
}
.w-36 {
	width: 36%;
}
.w-64 {
	width: 64%;
}
.w-18 {
	width: 18%;
}
.w-15 {
	width: 15%;
}
.w-82 {
	width: 82%;
}
.w-60 {
	width: 60%;
}
.w-65 {
	width: 65%;
}
.w-10 {
	width: 10%;
}
.w-40 {
	width: 40%;
}
.w-20 {
	width: 20%;
}
.w-80 {
	width: 80%;
}
.w-85 {
	width: 85%;
}
.w-15 {
	width: 15%;
}
.w-90 {
	width: 90%;
}

.divider-vertical {
	border-left: 2px solid #ffffff;
}
.gap-10 {
	gap: 10px;
}
.conten-item {
	max-height: 80svh;
	overflow: auto;
}
.content-c1 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
}
.vue-treeselect__control {
	height: 26px !important;
}

.gap-1 {
	gap: 10px;
}
.border-1 {
	border-radius: 5px;
	width: 60px;
}
.border-rd {
	border: 2px solid #dfdfdf;
	border-radius: 3px;
}
.border-lf {
	border-left: 2px solid #dfdfdf;
	padding: 0px 10px;
}
.text-1 {
	font-weight: 600;
	font-size: 14px;
	letter-spacing: 0.01em;
	color: rgba(6, 38, 62, 0.74);
}

.content-item-popover {
	display: flex;
	border: none;
	align-items: center;
	justify-content: start;
	gap: 10px;
	height: 40px;
	border-bottom: 1px solid beige;
	padding: 4px;
	width: 100%;
	background: #ffffff;
	&:hover {
		cursor: pointer;
		background-color: hsl(206, 96%, 90%);
		border-radius: 4px;
	}
	&:disabled {
		background-color: #dddddd;
		color: white;
		cursor: not-allowed;
		opacity: 0.8;
	}
}

.popover-item-icon {
	width: 25%;
}
.popover-item-text {
	display: flex !important;
	width: 75% !important;
	align-items: center !important;
	justify-content: flex-start !important;
}

.btn-category {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: -5px;
	border-radius: 0px 4px 4px 0px !important;
	border: none;
	background-color: #00a09c;
	color: #ffff;
	font-weight: 700;
}
.item-text {
	text-overflow: ellipsis;
	text-align: center;
	overflow: hidden;
	white-space: nowrap;

	padding-left: 10px;
}
.w-show-all {
	width: 70px;
}
.w-trimestre {
	width: 85px;
}
.bg-tertiaire {
	background-color: #126782ad;
}
.dropdown-menu .dropdown-menu-right .show {
	z-index: 999 !important;
}
</style>
<style lang="scss">
.custom-popover {
	width: 160px !important;
	.popover-body {
		padding: 0px !important;
	}
}
.custom-but-unlock {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	width: 24px;
	height: auto;
}
</style>
