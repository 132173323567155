var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        title: _vm.FormMSG(14, "Advanced edit"),
        "header-class": "header-class-modal-doc-package",
        size: "xxl",
        "hide-header-close": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "dialog-class": "dialog-custom",
        "modal-class": "mui-animation",
        fade: false,
      },
      on: { cancel: _vm.closeModal },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "w-100 d-flex justify-content-end align-items-center gap-1",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-center align-items-center",
                      staticStyle: { "margin-top": "5px" },
                    },
                    [
                      _c("div", { staticClass: "text-default p-2" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(
                              `${
                                _vm.haveAnEdit
                                  ? _vm.FormMSG(
                                      423,
                                      "In the process of being modified"
                                    )
                                  : ""
                              }`
                            ) +
                            "\n\t\t\t\t"
                        ),
                      ]),
                      _vm._v("\n\t\t\t\t" + _vm._s(" ") + "\n\t\t\t\t"),
                      _vm.haveAnEdit
                        ? _c("div", { staticClass: "bars-5" })
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "w-138-px mr-3",
                      staticStyle: { "margin-top": "5px" },
                      attrs: {
                        size: "md",
                        variant: "custom-outline-gray",
                        block: "",
                      },
                      on: { click: _vm.closeModal },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(100, "Close")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "div",
        { staticClass: "form container-layout" },
        [
          _c(
            "b-row",
            [
              _c("b-col", [
                _c(
                  "fieldset",
                  {
                    staticClass: "my-3 border-groove-blue-streak",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-blue-streak",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(132, "Selection")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "pt-3 pb-3" },
                      [
                        _c(
                          "b-row",
                          [
                            _c("b-col", [
                              _c("div", { staticClass: "fs-14 fw-700" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\t" +
                                    _vm._s(_vm.getFullName()) +
                                    " " +
                                    _vm._s(_vm.formatedDate()) +
                                    "\n\t\t\t\t\t\t\t\t\t"
                                ),
                                _vm.getLocation().id > 0
                                  ? _c("span", [
                                      _vm._v(_vm._s(_vm.getLocation().text)),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]),
                            _c("b-col", [
                              _c(
                                "div",
                                { staticClass: "fs-14 fw-700 ask-to-delete" },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.isAskToDelteItem
                                          ? _vm.messageAskInformation
                                          : ""
                                      ) +
                                      "\n\t\t\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-2" },
            [
              _c("b-col", [
                _c(
                  "fieldset",
                  {
                    staticClass: "my-3 border-groove-blue-streak",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-blue-streak",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(145, "Work detail")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "pt-3 pb-3" },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _vm.$screen.width >= 992
                                  ? _c("b-table", {
                                      ref: "documentPackageTable",
                                      staticStyle: { "text-align": "left" },
                                      attrs: {
                                        "selected-variant": "primary",
                                        hover: "",
                                        selectable: "",
                                        "select-mode": "single",
                                        responsive: "sm",
                                        items: _vm.dataList,
                                        fields: _vm.tableFields,
                                        bordered: "",
                                        striped: "",
                                        small: "",
                                        "head-variant": "dark",
                                        "empty-text": _vm.FormMSG(
                                          250,
                                          "No data found"
                                        ),
                                        "show-empty": "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "cell(rubric)",
                                            fn: function ({ item, index }) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-row justify-content-start align-items-center",
                                                  },
                                                  [
                                                    item.isEdit === false
                                                      ? _c("div", {
                                                          directives: [
                                                            {
                                                              name: "b-tooltip",
                                                              rawName:
                                                                "v-b-tooltip.hover.top.html",
                                                              value:
                                                                _vm.FormMSG(
                                                                  505,
                                                                  "The element has been modified"
                                                                ),
                                                              expression:
                                                                "FormMSG(505, 'The element has been modified')",
                                                              modifiers: {
                                                                hover: true,
                                                                top: true,
                                                                html: true,
                                                              },
                                                            },
                                                          ],
                                                          class: {
                                                            [_vm.generateAnimation(
                                                              item
                                                            )]:
                                                              !_vm.isMultipleEdit,
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    item.isEdit === false
                                                      ? _c("div", [
                                                          _vm._v("   "),
                                                        ])
                                                      : _vm._e(),
                                                    item.isEdit === false
                                                      ? _c("div", [
                                                          typeof item.rubric ===
                                                          "number"
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      item.flagItemName
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                          typeof item.rubric ===
                                                          "object"
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      item.rubric
                                                                        ? item
                                                                            .rubric
                                                                            .short
                                                                        : ""
                                                                    ) +
                                                                    " " +
                                                                    _vm._s(
                                                                      item.rubric
                                                                        ? item
                                                                            .rubric
                                                                            .value
                                                                        : ""
                                                                    ) +
                                                                    "H -\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      item.rubric
                                                                        ? item
                                                                            .rubric
                                                                            .name
                                                                        : ""
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ])
                                                      : _vm._e(),
                                                    item.isEdit === true
                                                      ? _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              {
                                                                staticClass:
                                                                  "mb-0",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex flex-row align-items-center",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-button",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "b-tooltip",
                                                                              rawName:
                                                                                "v-b-tooltip.hover.top.html",
                                                                              value: `${_vm.showNameRubricSelected(
                                                                                index
                                                                              )}`,
                                                                              expression:
                                                                                "`${showNameRubricSelected(index)}`",
                                                                              modifiers:
                                                                                {
                                                                                  hover: true,
                                                                                  top: true,
                                                                                  html: true,
                                                                                },
                                                                            },
                                                                          ],
                                                                        staticClass:
                                                                          "btn bg-transparent border-0",
                                                                        attrs: {
                                                                          size: "sm",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getLucideIcon(
                                                                            _vm
                                                                              .ICONS
                                                                              .INFO
                                                                              .name
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  _vm
                                                                                    .ICONS
                                                                                    .INFO
                                                                                    .color,
                                                                                size: 16,
                                                                                "stroke-width": 2.25,
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "treeselect",
                                                                      {
                                                                        class: {
                                                                          "is-invalid-treeselect":
                                                                            _vm
                                                                              .$v
                                                                              .dataList
                                                                              .$each
                                                                              .$iter[
                                                                              index
                                                                            ]
                                                                              .rubric
                                                                              .$invalid,
                                                                        },
                                                                        attrs: {
                                                                          multiple: false,
                                                                          options:
                                                                            _vm.flagItemsOptions,
                                                                          placeholder:
                                                                            _vm.FormMSG(
                                                                              87,
                                                                              "Please select"
                                                                            ),
                                                                          "open-direction":
                                                                            "bottom",
                                                                          clearable: true,
                                                                        },
                                                                        on: {
                                                                          select:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handleOptionSelected(
                                                                                $event,
                                                                                index
                                                                              )
                                                                            },
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "value-label",
                                                                                fn: function ({
                                                                                  node,
                                                                                }) {
                                                                                  return _c(
                                                                                    "div",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          title:
                                                                                            _vm.getNameRubric(
                                                                                              node
                                                                                            ),
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              _vm.getNameRubric(
                                                                                                node
                                                                                              )
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                },
                                                                              },
                                                                              {
                                                                                key: "option-label",
                                                                                fn: function ({
                                                                                  node,
                                                                                }) {
                                                                                  return _c(
                                                                                    "div",
                                                                                    {},
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "treeselect-label p-2",
                                                                                          attrs:
                                                                                            {
                                                                                              title:
                                                                                                _vm.getNameRubric(
                                                                                                  node
                                                                                                ),
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "d-flex flex-row align-items-center",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "div",
                                                                                                [
                                                                                                  _c(
                                                                                                    "b-button",
                                                                                                    {
                                                                                                      directives:
                                                                                                        [
                                                                                                          {
                                                                                                            name: "b-tooltip",
                                                                                                            rawName:
                                                                                                              "v-b-tooltip.hover.top.html",
                                                                                                            value: `${_vm.showTooltipTextRubric(
                                                                                                              node
                                                                                                            )}`,
                                                                                                            expression:
                                                                                                              "`${showTooltipTextRubric(node)}`",
                                                                                                            modifiers:
                                                                                                              {
                                                                                                                hover: true,
                                                                                                                top: true,
                                                                                                                html: true,
                                                                                                              },
                                                                                                          },
                                                                                                        ],
                                                                                                      staticClass:
                                                                                                        "btn bg-transparent border-0",
                                                                                                      attrs:
                                                                                                        {
                                                                                                          size: "sm",
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        _vm.getLucideIcon(
                                                                                                          _vm
                                                                                                            .ICONS
                                                                                                            .INFO
                                                                                                            .name
                                                                                                        ),
                                                                                                        {
                                                                                                          tag: "component",
                                                                                                          attrs:
                                                                                                            {
                                                                                                              color:
                                                                                                                _vm
                                                                                                                  .ICONS
                                                                                                                  .INFO
                                                                                                                  .color,
                                                                                                              size: 16,
                                                                                                              "stroke-width": 2.25,
                                                                                                            },
                                                                                                        }
                                                                                                      ),
                                                                                                    ],
                                                                                                    1
                                                                                                  ),
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                              _c(
                                                                                                "div",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      _vm.getNameRubric(
                                                                                                        node
                                                                                                      )
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                },
                                                                              },
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .$v
                                                                              .dataList
                                                                              .$each
                                                                              .$iter[
                                                                              index
                                                                            ]
                                                                              .rubric
                                                                              .$model,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .$v
                                                                                  .dataList
                                                                                  .$each
                                                                                  .$iter[
                                                                                  index
                                                                                ]
                                                                                  .rubric,
                                                                                "$model",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "$v.dataList.$each.$iter[index].rubric.$model",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm.$v.dataList
                                                                  .$each.$iter[
                                                                  index
                                                                ].rubric
                                                                  .$invalid
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "invalid-feedback margin-l-30",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                205,
                                                                                "Rubric is required"
                                                                              )
                                                                            ) +
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm.rowLineInvalid(
                                                                      index
                                                                    )
                                                                  ? _c("div", [
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                    ])
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "cell(amount)",
                                            fn: function ({ item, index }) {
                                              return [
                                                _vm.isManagerSalaryAccess(item)
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex flex-row align-items-center justify-content-end gap-1",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            class: {
                                                              "bg-solve-amount":
                                                                _vm.isSolveAmount(
                                                                  index
                                                                ),
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.getCurrency(
                                                                  item
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _vm.isMultipleEdit &&
                                                        !item.isEqualAmount
                                                          ? _c(
                                                              "div",
                                                              [
                                                                _c(
                                                                  "b-button",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "b-tooltip",
                                                                          rawName:
                                                                            "v-b-tooltip.hover.right.html",
                                                                          value:
                                                                            _vm.isExistEqualAmountToolTip(
                                                                              item.isEqualAmount
                                                                            ),
                                                                          expression:
                                                                            "isExistEqualAmountToolTip(item.isEqualAmount)",
                                                                          modifiers:
                                                                            {
                                                                              hover: true,
                                                                              right: true,
                                                                              html: true,
                                                                            },
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "btn bg-transparent border-0 mb-1",
                                                                    attrs: {
                                                                      size: "sm",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "alert-triangle",
                                                                      {
                                                                        attrs: {
                                                                          size: 16,
                                                                          color:
                                                                            "orange",
                                                                          "stroke-width": 2.25,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  : _c("div", [
                                                      item.isEdit === false
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-center justify-content-end gap-1",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  class: {
                                                                    "bg-solve-amount":
                                                                      _vm.isSolveAmount(
                                                                        index
                                                                      ),
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.getCurrency(
                                                                        item
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm.isMultipleEdit &&
                                                              !item.isEqualAmount
                                                                ? _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        "b-button",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "b-tooltip",
                                                                                rawName:
                                                                                  "v-b-tooltip.hover.right.html",
                                                                                value:
                                                                                  _vm.isExistEqualAmountToolTip(
                                                                                    item.isEqualAmount
                                                                                  ),
                                                                                expression:
                                                                                  "isExistEqualAmountToolTip(item.isEqualAmount)",
                                                                                modifiers:
                                                                                  {
                                                                                    hover: true,
                                                                                    right: true,
                                                                                    html: true,
                                                                                  },
                                                                              },
                                                                            ],
                                                                          staticClass:
                                                                            "btn bg-transparent border-0 mb-1",
                                                                          attrs:
                                                                            {
                                                                              size: "sm",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "alert-triangle",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  size: 16,
                                                                                  color:
                                                                                    "orange",
                                                                                  "stroke-width": 2.25,
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      item.isEdit === true
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "b-form-group",
                                                                {
                                                                  staticClass:
                                                                    "mb-0",
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-input-group",
                                                                    [
                                                                      _c(
                                                                        "b-form-input",
                                                                        {
                                                                          class:
                                                                            {
                                                                              "is-invalid":
                                                                                _vm.isRegissor
                                                                                  ? false
                                                                                  : _vm
                                                                                      .$v
                                                                                      .dataList
                                                                                      .$each
                                                                                      .$iter[
                                                                                      index
                                                                                    ]
                                                                                      .amount
                                                                                      .$invalid,
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .$v
                                                                                  .dataList
                                                                                  .$each
                                                                                  .$iter[
                                                                                  index
                                                                                ]
                                                                                  .amount
                                                                                  .$model,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .$v
                                                                                      .dataList
                                                                                      .$each
                                                                                      .$iter[
                                                                                      index
                                                                                    ]
                                                                                      .amount,
                                                                                    "$model",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "$v.dataList.$each.$iter[index].amount.$model",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "b-input-group-append",
                                                                        [
                                                                          _c(
                                                                            "b-input-group-text",
                                                                            [
                                                                              _c(
                                                                                "currency-svg",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        "#06263E",
                                                                                      opacity:
                                                                                        "0.85",
                                                                                      width:
                                                                                        "15",
                                                                                      height:
                                                                                        "15",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm.isRegissor ===
                                                                    false &&
                                                                  _vm.$v
                                                                    .dataList
                                                                    .$each
                                                                    .$iter[
                                                                    index
                                                                  ].amount
                                                                    .$invalid
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "invalid-feedback",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  202,
                                                                                  "Amount is required"
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm.rowLineInvalid(
                                                                        index
                                                                      )
                                                                    ? _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]),
                                              ]
                                            },
                                          },
                                          {
                                            key: "cell(quantity)",
                                            fn: function ({ item, index }) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-row align-items-center justify-content-center",
                                                  },
                                                  [
                                                    _vm.isManagerSalaryAccess(
                                                      item
                                                    )
                                                      ? _c(
                                                          "div",
                                                          [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  _vm.getQuantity(
                                                                    item
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                            _vm.isMultipleEdit &&
                                                            !item.isEqualQuantity
                                                              ? _c(
                                                                  "b-button",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "b-tooltip",
                                                                          rawName:
                                                                            "v-b-tooltip.hover.right.html",
                                                                          value:
                                                                            _vm.isExistEqualQuantityToolTip(
                                                                              item.isEqualQuantity
                                                                            ),
                                                                          expression:
                                                                            "isExistEqualQuantityToolTip(item.isEqualQuantity)",
                                                                          modifiers:
                                                                            {
                                                                              hover: true,
                                                                              right: true,
                                                                              html: true,
                                                                            },
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "btn bg-transparent border-0 mb-1",
                                                                    attrs: {
                                                                      size: "sm",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "alert-triangle",
                                                                      {
                                                                        attrs: {
                                                                          size: 16,
                                                                          color:
                                                                            "orange",
                                                                          "stroke-width": 2.25,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        )
                                                      : _c("div", [
                                                          item.isEdit === false
                                                            ? _c(
                                                                "div",
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.getQuantity(
                                                                          item
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                  _vm.isMultipleEdit &&
                                                                  !item.isEqualQuantity
                                                                    ? _c(
                                                                        "b-button",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "b-tooltip",
                                                                                rawName:
                                                                                  "v-b-tooltip.hover.right.html",
                                                                                value:
                                                                                  _vm.isExistEqualQuantityToolTip(
                                                                                    item.isEqualQuantity
                                                                                  ),
                                                                                expression:
                                                                                  "isExistEqualQuantityToolTip(item.isEqualQuantity)",
                                                                                modifiers:
                                                                                  {
                                                                                    hover: true,
                                                                                    right: true,
                                                                                    html: true,
                                                                                  },
                                                                              },
                                                                            ],
                                                                          staticClass:
                                                                            "btn bg-transparent border-0 mb-1",
                                                                          attrs:
                                                                            {
                                                                              size: "sm",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "alert-triangle",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  size: 16,
                                                                                  color:
                                                                                    "orange",
                                                                                  "stroke-width": 2.25,
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          item.isEdit === true
                                                            ? _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-group",
                                                                    {
                                                                      staticClass:
                                                                        "mb-0",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-input-group",
                                                                        [
                                                                          _c(
                                                                            "b-form-input",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  type: "number",
                                                                                  min: 1,
                                                                                  step: 1,
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm
                                                                                      .$v
                                                                                      .dataList
                                                                                      .$each
                                                                                      .$iter[
                                                                                      index
                                                                                    ]
                                                                                      .$model
                                                                                      .quantity,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        _vm
                                                                                          .$v
                                                                                          .dataList
                                                                                          .$each
                                                                                          .$iter[
                                                                                          index
                                                                                        ]
                                                                                          .$model,
                                                                                        "quantity",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "$v.dataList.$each.$iter[index].$model.quantity",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm.rowLineInvalid(
                                                                    index
                                                                  )
                                                                    ? _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ]),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "cell(locations)",
                                            fn: function ({ item, index }) {
                                              return [
                                                _c("div", [
                                                  item.isEdit === false
                                                    ? _c("div", [
                                                        _c("div", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.getLocationName(
                                                                item,
                                                                index
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ])
                                                    : _vm._e(),
                                                  item.isEdit === true
                                                    ? _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            "b-form-group",
                                                            {
                                                              staticClass:
                                                                "mb-0",
                                                            },
                                                            [
                                                              _c("v-select", {
                                                                class: {
                                                                  "is-invalid":
                                                                    _vm.$v
                                                                      .dataList
                                                                      .$each
                                                                      .$iter[
                                                                      index
                                                                    ].locations
                                                                      .$invalid,
                                                                },
                                                                attrs: {
                                                                  id: `id-${index}`,
                                                                  options:
                                                                    _vm.locationList,
                                                                  placeholder:
                                                                    _vm.FormMSG(
                                                                      452,
                                                                      "Select a decor"
                                                                    ),
                                                                  label:
                                                                    "setName",
                                                                  clearable: false,
                                                                  searchable: true,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.$v
                                                                      .dataList
                                                                      .$each
                                                                      .$iter[
                                                                      index
                                                                    ].locations
                                                                      .$model,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.$v
                                                                          .dataList
                                                                          .$each
                                                                          .$iter[
                                                                          index
                                                                        ]
                                                                          .locations,
                                                                        "$model",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "$v.dataList.$each.$iter[index].locations.$model",
                                                                },
                                                              }),
                                                              _vm.$v.dataList
                                                                .$each.$iter[
                                                                index
                                                              ].locations
                                                                .$invalid
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "invalid-feedback",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              55,
                                                                              "Decor is required"
                                                                            )
                                                                          ) +
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm.rowLineInvalid(
                                                                    index
                                                                  )
                                                                ? _c("div", [
                                                                    _vm._v(" "),
                                                                  ])
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]),
                                              ]
                                            },
                                          },
                                          {
                                            key: "cell(activity)",
                                            fn: function ({ item, index }) {
                                              return [
                                                _c("div", [
                                                  item.isEdit === false
                                                    ? _c(
                                                        "div",
                                                        {
                                                          class: {
                                                            "bg-solve-text":
                                                              _vm.isSolveText(
                                                                item.activity
                                                              ),
                                                          },
                                                        },
                                                        [
                                                          item.activity !== null
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-flex flex-row align-items-center gap-1",
                                                                },
                                                                [
                                                                  _c("div", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item
                                                                          .activity
                                                                          .short
                                                                      ) +
                                                                        " - " +
                                                                        _vm._s(
                                                                          item
                                                                            .activity
                                                                            .name
                                                                        )
                                                                    ),
                                                                  ]),
                                                                  _vm.isMultipleEdit &&
                                                                  !item.isEqualActivity
                                                                    ? _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "b-button",
                                                                            {
                                                                              directives:
                                                                                [
                                                                                  {
                                                                                    name: "b-tooltip",
                                                                                    rawName:
                                                                                      "v-b-tooltip.hover.right.html",
                                                                                    value:
                                                                                      _vm.isExistEqualActivitiesToolTip(
                                                                                        item.isEqualActivity
                                                                                      ),
                                                                                    expression:
                                                                                      "isExistEqualActivitiesToolTip(item.isEqualActivity)",
                                                                                    modifiers:
                                                                                      {
                                                                                        hover: true,
                                                                                        right: true,
                                                                                        html: true,
                                                                                      },
                                                                                  },
                                                                                ],
                                                                              staticClass:
                                                                                "btn bg-transparent border-0 mb-1",
                                                                              attrs:
                                                                                {
                                                                                  size: "sm",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "alert-triangle",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      size: 16,
                                                                                      color:
                                                                                        "orange",
                                                                                      "stroke-width": 2.25,
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  item.isEdit === true
                                                    ? _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            "b-form-group",
                                                            {
                                                              staticClass:
                                                                "mb-0",
                                                            },
                                                            [
                                                              _c("v-select", {
                                                                class: {
                                                                  "is-invalid":
                                                                    _vm.isRegissor
                                                                      ? false
                                                                      : _vm.$v
                                                                          .dataList
                                                                          .$each
                                                                          .$iter[
                                                                          index
                                                                        ]
                                                                          .activity
                                                                          .$invalid ||
                                                                        _vm
                                                                          .dataList[
                                                                          index
                                                                        ]
                                                                          .activity ===
                                                                          null,
                                                                },
                                                                attrs: {
                                                                  options:
                                                                    _vm.activityOptions,
                                                                  placeholder:
                                                                    _vm.FormMSG(
                                                                      98,
                                                                      "Select a activity ..."
                                                                    ),
                                                                  clearable: true,
                                                                  searchable: true,
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "selected-option",
                                                                        fn: function ({
                                                                          name,
                                                                          short,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "div",
                                                                              [
                                                                                _vm._v(
                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                    _vm._s(
                                                                                      `${
                                                                                        short
                                                                                          ? short
                                                                                          : ""
                                                                                      } - ${name}`
                                                                                    ) +
                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                      {
                                                                        key: "option",
                                                                        fn: function ({
                                                                          name,
                                                                          short,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "div",
                                                                              [
                                                                                _vm._v(
                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                    _vm._s(
                                                                                      `${
                                                                                        short
                                                                                          ? short
                                                                                          : ""
                                                                                      } - ${name}`
                                                                                    ) +
                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                                model: {
                                                                  value:
                                                                    _vm.$v
                                                                      .dataList
                                                                      .$each
                                                                      .$iter[
                                                                      index
                                                                    ].activity
                                                                      .$model,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.$v
                                                                          .dataList
                                                                          .$each
                                                                          .$iter[
                                                                          index
                                                                        ]
                                                                          .activity,
                                                                        "$model",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "$v.dataList.$each.$iter[index].activity.$model",
                                                                },
                                                              }),
                                                              (
                                                                _vm.isRegissor
                                                                  ? false
                                                                  : _vm.$v
                                                                      .dataList
                                                                      .$each
                                                                      .$iter[
                                                                      index
                                                                    ].activity
                                                                      .$invalid ||
                                                                    _vm
                                                                      .dataList[
                                                                      index
                                                                    ]
                                                                      .activity ===
                                                                      null
                                                              )
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "invalid-feedback",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              82,
                                                                              "Activity is required"
                                                                            )
                                                                          ) +
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm.rowLineInvalid(
                                                                    index
                                                                  )
                                                                ? _c("div", [
                                                                    _vm._v(" "),
                                                                  ])
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]),
                                              ]
                                            },
                                          },
                                          {
                                            key: "cell(category)",
                                            fn: function ({ item, index }) {
                                              return [
                                                _c("div", [
                                                  item.isEdit === false
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-row align-items-center gap-1",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              class: {
                                                                "bg-solve-text":
                                                                  _vm.isSolveText(
                                                                    _vm.setTextCategoriesWithActivities(
                                                                      item.labelCategory,
                                                                      index
                                                                    )
                                                                  ),
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.setTextCategoriesWithActivities(
                                                                      item.labelCategory,
                                                                      index
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm.isMultipleEdit &&
                                                          !item.isEqualCategories
                                                            ? _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-button",
                                                                    {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "b-tooltip",
                                                                            rawName:
                                                                              "v-b-tooltip.hover.right.html",
                                                                            value:
                                                                              _vm.isExistEqualCategoriesToolTip(
                                                                                item.isEqualCategories
                                                                              ),
                                                                            expression:
                                                                              "isExistEqualCategoriesToolTip(item.isEqualCategories)",
                                                                            modifiers:
                                                                              {
                                                                                hover: true,
                                                                                right: true,
                                                                                html: true,
                                                                              },
                                                                          },
                                                                        ],
                                                                      staticClass:
                                                                        "btn bg-transparent border-0 mb-1",
                                                                      attrs: {
                                                                        size: "sm",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "alert-triangle",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              size: 16,
                                                                              color:
                                                                                "orange",
                                                                              "stroke-width": 2.25,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  item.isEdit === true
                                                    ? _c("div", [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex flex-row tax-item",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "w-70 category-input",
                                                              },
                                                              [
                                                                _c(
                                                                  "treeselect",
                                                                  {
                                                                    class: {
                                                                      "is-invalid-treeselect":
                                                                        _vm.isRegissor
                                                                          ? false
                                                                          : _vm
                                                                              .dataList[
                                                                              index
                                                                            ]
                                                                              .category ===
                                                                              0 ||
                                                                            _vm
                                                                              .dataList[
                                                                              index
                                                                            ]
                                                                              .category ===
                                                                              null ||
                                                                            _vm
                                                                              .$v
                                                                              .dataList
                                                                              .$each
                                                                              .$iter[
                                                                              index
                                                                            ]
                                                                              .category
                                                                              .$invalid,
                                                                    },
                                                                    attrs: {
                                                                      multiple: false,
                                                                      options:
                                                                        _vm
                                                                          .mapCategories[
                                                                          `menu-${item.id}-${index}`
                                                                        ]
                                                                          .options ||
                                                                        [],
                                                                      "default-expand-level": 1,
                                                                      "disable-branch-nodes": true,
                                                                      placeholder:
                                                                        _vm.FormMSG(
                                                                          52,
                                                                          "Select a category ..."
                                                                        ),
                                                                      "open-direction":
                                                                        "bottom",
                                                                      clearable: true,
                                                                    },
                                                                    on: {
                                                                      select:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handleSelectedCategory(
                                                                            $event,
                                                                            index
                                                                          )
                                                                        },
                                                                    },
                                                                    scopedSlots:
                                                                      _vm._u(
                                                                        [
                                                                          {
                                                                            key: "value-label",
                                                                            fn: function ({
                                                                              node,
                                                                            }) {
                                                                              return _c(
                                                                                "div",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      title:
                                                                                        node.label,
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm.setTextCategoriesWithActivities(
                                                                                            node.label,
                                                                                            index
                                                                                          )
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            },
                                                                          },
                                                                          {
                                                                            key: "option-label",
                                                                            fn: function ({
                                                                              node,
                                                                            }) {
                                                                              return _c(
                                                                                "div",
                                                                                {},
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "treeselect-label",
                                                                                      attrs:
                                                                                        {
                                                                                          title:
                                                                                            node.label,
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          node.label
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                    model: {
                                                                      value:
                                                                        _vm.$v
                                                                          .dataList
                                                                          .$each
                                                                          .$iter[
                                                                          index
                                                                        ]
                                                                          .category
                                                                          .$model,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .$v
                                                                              .dataList
                                                                              .$each
                                                                              .$iter[
                                                                              index
                                                                            ]
                                                                              .category,
                                                                            "$model",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "$v.dataList.$each.$iter[index].category.$model",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex flex-wrap mt-0 w-30",
                                                              },
                                                              [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn-category w-100",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          $event.preventDefault()
                                                                          return _vm.handleShowMapCategories(
                                                                            `menu-${item.id}-${index}`,
                                                                            index
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "content-btn-category",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm
                                                                              .mapCategories[
                                                                              `menu-${item.id}-${index}`
                                                                            ]
                                                                              .text ||
                                                                              ""
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        (
                                                          _vm.isRegissor
                                                            ? false
                                                            : _vm.dataList[
                                                                index
                                                              ].category ===
                                                                0 ||
                                                              _vm.dataList[
                                                                index
                                                              ].category ===
                                                                null ||
                                                              _vm.$v.dataList
                                                                .$each.$iter[
                                                                index
                                                              ].category
                                                                .$invalid
                                                        )
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "invalid-feedback",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        204,
                                                                        "Category is required"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm.rowLineInvalid(
                                                              index
                                                            )
                                                          ? _c("div", [
                                                              _vm._v(" "),
                                                            ])
                                                          : _vm._e(),
                                                      ])
                                                    : _vm._e(),
                                                ]),
                                              ]
                                            },
                                          },
                                          {
                                            key: "cell(edit)",
                                            fn: function ({ item, index }) {
                                              return [
                                                _c("div", [
                                                  item.isEdit === false
                                                    ? _c(
                                                        "div",
                                                        { staticClass: "pt-1" },
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              staticClass:
                                                                "text-color-blue-streak",
                                                              attrs: {
                                                                disabled:
                                                                  _vm.isAuthorizeEdit,
                                                                variant:
                                                                  "custom-transparent",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleClickToEdit(
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                _vm.getLucideIcon(
                                                                  "Edit"
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  attrs: {
                                                                    size: 18,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  item.isEdit === true
                                                    ? _c("div", [
                                                        _vm.isRegissor === true
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex flex-row align-items-center gap-1 pt-1",
                                                              },
                                                              [
                                                                _c(
                                                                  "b-button",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "b-tooltip",
                                                                          rawName:
                                                                            "v-b-tooltip.hover",
                                                                          modifiers:
                                                                            {
                                                                              hover: true,
                                                                            },
                                                                        },
                                                                      ],
                                                                    attrs: {
                                                                      variant:
                                                                        "custom-transparent",
                                                                      title:
                                                                        _vm.FormMSG(
                                                                          182,
                                                                          "Close"
                                                                        ),
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handleClickEditClose(
                                                                            item,
                                                                            index
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "text-success",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getLucideIcon(
                                                                            "Undo"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  "#225CBD",
                                                                                size: 18,
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-button",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "b-tooltip",
                                                                          rawName:
                                                                            "v-b-tooltip.hover",
                                                                          modifiers:
                                                                            {
                                                                              hover: true,
                                                                            },
                                                                        },
                                                                      ],
                                                                    attrs: {
                                                                      variant:
                                                                        "custom-transparent",
                                                                      disabled:
                                                                        _vm.isSave(
                                                                          _vm.$v
                                                                            .dataList
                                                                            .$each
                                                                            .$iter[
                                                                            index
                                                                          ]
                                                                        ),
                                                                      title:
                                                                        _vm.FormMSG(
                                                                          183,
                                                                          "Save"
                                                                        ),
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handleClickEdit(
                                                                            index
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "text-success",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getLucideIcon(
                                                                            "Save"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                size: 18,
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm.isRegissor ===
                                                            false
                                                          ? _c("div", [
                                                              _vm.$v.dataList
                                                                .$each.$iter[
                                                                index
                                                              ].$invalid ===
                                                              false
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex flex-row align-items-center gap-1 pt-1",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-button",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "b-tooltip",
                                                                                rawName:
                                                                                  "v-b-tooltip.hover",
                                                                                modifiers:
                                                                                  {
                                                                                    hover: true,
                                                                                  },
                                                                              },
                                                                            ],
                                                                          attrs:
                                                                            {
                                                                              variant:
                                                                                "custom-transparent",
                                                                              title:
                                                                                _vm.FormMSG(
                                                                                  182,
                                                                                  "Close"
                                                                                ),
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.handleClickEditClose(
                                                                                  item,
                                                                                  index
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "text-success",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                _vm.getLucideIcon(
                                                                                  "Undo"
                                                                                ),
                                                                                {
                                                                                  tag: "component",
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        "#225CBD",
                                                                                      size: 18,
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "b-button",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "b-tooltip",
                                                                                rawName:
                                                                                  "v-b-tooltip.hover",
                                                                                modifiers:
                                                                                  {
                                                                                    hover: true,
                                                                                  },
                                                                              },
                                                                            ],
                                                                          attrs:
                                                                            {
                                                                              variant:
                                                                                "custom-transparent",
                                                                              disabled:
                                                                                _vm.isSave(
                                                                                  _vm
                                                                                    .$v
                                                                                    .dataList
                                                                                    .$each
                                                                                    .$iter[
                                                                                    index
                                                                                  ]
                                                                                ),
                                                                              title:
                                                                                _vm.FormMSG(
                                                                                  183,
                                                                                  "Save"
                                                                                ),
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.handleClickEdit(
                                                                                  index
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "text-success",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                _vm.getLucideIcon(
                                                                                  "Save"
                                                                                ),
                                                                                {
                                                                                  tag: "component",
                                                                                  attrs:
                                                                                    {
                                                                                      size: 18,
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm.$v.dataList
                                                                .$each.$iter[
                                                                index
                                                              ].$invalid ===
                                                              true
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex flex-row align-items-center gap-1 pt-1",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-button",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "b-tooltip",
                                                                                rawName:
                                                                                  "v-b-tooltip.hover",
                                                                                modifiers:
                                                                                  {
                                                                                    hover: true,
                                                                                  },
                                                                              },
                                                                            ],
                                                                          attrs:
                                                                            {
                                                                              variant:
                                                                                "custom-transparent",
                                                                              title:
                                                                                _vm.FormMSG(
                                                                                  182,
                                                                                  "Close"
                                                                                ),
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.handleClickEditClose(
                                                                                  item,
                                                                                  index
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "text-success",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                _vm.getLucideIcon(
                                                                                  "Undo"
                                                                                ),
                                                                                {
                                                                                  tag: "component",
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        "#225CBD",
                                                                                      size: 18,
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "b-button",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "b-tooltip",
                                                                                rawName:
                                                                                  "v-b-tooltip.hover",
                                                                                modifiers:
                                                                                  {
                                                                                    hover: true,
                                                                                  },
                                                                              },
                                                                            ],
                                                                          attrs:
                                                                            {
                                                                              variant:
                                                                                "custom-transparent",
                                                                              disabled:
                                                                                "",
                                                                              title:
                                                                                _vm.FormMSG(
                                                                                  181,
                                                                                  "Some fields are mandatory"
                                                                                ),
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "text-success",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                _vm.getLucideIcon(
                                                                                  "Save"
                                                                                ),
                                                                                {
                                                                                  tag: "component",
                                                                                  attrs:
                                                                                    {
                                                                                      size: 18,
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ])
                                                          : _vm._e(),
                                                        _vm.rowLineInvalid(
                                                          index
                                                        )
                                                          ? _c("div", [
                                                              _vm._v(" "),
                                                            ])
                                                          : _vm._e(),
                                                      ])
                                                    : _vm._e(),
                                                ]),
                                              ]
                                            },
                                          },
                                          {
                                            key: "cell(remove)",
                                            fn: function ({ item, index }) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-row align-items-center justify-content-center flex-wrap",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex flex-row align-items-center justify-content-center",
                                                      },
                                                      [
                                                        _vm.iconRemoveType(item)
                                                          .accept === false
                                                          ? _c(
                                                              "b-button",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "b-tooltip",
                                                                    rawName:
                                                                      "v-b-tooltip.hover.left.html",
                                                                    value:
                                                                      _vm.handleContentRemoveTooltip(
                                                                        item
                                                                      ),
                                                                    expression:
                                                                      "handleContentRemoveTooltip(item)",
                                                                    modifiers: {
                                                                      hover: true,
                                                                      left: true,
                                                                      html: true,
                                                                    },
                                                                  },
                                                                ],
                                                                class: {
                                                                  "border-refuse":
                                                                    _vm.iconRemoveType(
                                                                      item
                                                                    ).isRefused,
                                                                },
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.isAskToDelteItemRow(
                                                                      item
                                                                    ),
                                                                  variant:
                                                                    "custom-transparent",
                                                                  size: "sm",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleClickRemove(
                                                                        index,
                                                                        false
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm.actionTypeRowRemove(
                                                                  item
                                                                ).isRemove
                                                                  ? _c(
                                                                      "div",
                                                                      [
                                                                        _c(
                                                                          _vm.getLucideIcon(
                                                                            _vm.iconRemoveType(
                                                                              item
                                                                            )
                                                                              .icon
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  _vm.iconRemoveType(
                                                                                    item
                                                                                  )
                                                                                    .color,
                                                                                size: 18,
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                                _vm.iconRemoveType(
                                                                  item
                                                                ).accept
                                                                  ? _c(
                                                                      "div",
                                                                      [
                                                                        _c(
                                                                          _vm.getLucideIcon(
                                                                            _vm
                                                                              .ICONS
                                                                              .LIST_TODO
                                                                              .name
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  _vm
                                                                                    .ICONS
                                                                                    .LIST_TODO
                                                                                    .color,
                                                                                size: 18,
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                                _vm.actionTypeRowRemove(
                                                                  item
                                                                ).isUndo
                                                                  ? _c(
                                                                      "div",
                                                                      [
                                                                        _c(
                                                                          _vm.getLucideIcon(
                                                                            _vm
                                                                              .ICONS
                                                                              .UNDO_2
                                                                              .name
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  _vm
                                                                                    .ICONS
                                                                                    .UNDO_2
                                                                                    .color,
                                                                                size: 18,
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            )
                                                          : _c(
                                                              "b-dropdown",
                                                              {
                                                                staticStyle: {
                                                                  "border-right":
                                                                    "0px !important",
                                                                  "padding-top":
                                                                    "0px !important",
                                                                },
                                                                attrs: {
                                                                  "no-caret":
                                                                    "",
                                                                  dropleft: "",
                                                                  offset: "25",
                                                                  variant:
                                                                    "none",
                                                                  boundary:
                                                                    "window",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "button-content",
                                                                        fn: function () {
                                                                          return [
                                                                            _c(
                                                                              "more-vertical"
                                                                            ),
                                                                          ]
                                                                        },
                                                                        proxy: true,
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              },
                                                              [
                                                                _c(
                                                                  "b-dropdown-item-button",
                                                                  {
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handleClickRemove(
                                                                            index,
                                                                            false
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-button",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "b-tooltip",
                                                                              rawName:
                                                                                "v-b-tooltip.hover.left.html",
                                                                              value:
                                                                                _vm.handleContentRemoveTooltip(
                                                                                  item,
                                                                                  true
                                                                                ),
                                                                              expression:
                                                                                "handleContentRemoveTooltip(item, true)",
                                                                              modifiers:
                                                                                {
                                                                                  hover: true,
                                                                                  left: true,
                                                                                  html: true,
                                                                                },
                                                                            },
                                                                          ],
                                                                        attrs: {
                                                                          disabled:
                                                                            _vm.isAskToDelteItemRow(
                                                                              item
                                                                            ),
                                                                          variant:
                                                                            "d-flex flex-row align-items-center custom-transparent",
                                                                          size: "sm",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm.actionTypeRowRemove(
                                                                          item
                                                                        )
                                                                          .isRemove
                                                                          ? _c(
                                                                              "div",
                                                                              [
                                                                                _c(
                                                                                  _vm.getLucideIcon(
                                                                                    _vm.iconRemoveType(
                                                                                      item
                                                                                    )
                                                                                      .icon
                                                                                  ),
                                                                                  {
                                                                                    tag: "component",
                                                                                    attrs:
                                                                                      {
                                                                                        color:
                                                                                          _vm.iconRemoveType(
                                                                                            item
                                                                                          )
                                                                                            .color,
                                                                                        size: 18,
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _vm._e(),
                                                                        _vm.actionTypeRowRemove(
                                                                          item
                                                                        ).isUndo
                                                                          ? _c(
                                                                              "div",
                                                                              [
                                                                                _c(
                                                                                  _vm.getLucideIcon(
                                                                                    _vm
                                                                                      .ICONS
                                                                                      .UNDO_2
                                                                                      .name
                                                                                  ),
                                                                                  {
                                                                                    tag: "component",
                                                                                    attrs:
                                                                                      {
                                                                                        color:
                                                                                          _vm
                                                                                            .ICONS
                                                                                            .UNDO_2
                                                                                            .color,
                                                                                        size: 18,
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _vm._e(),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t "
                                                                    ),
                                                                    _c("b", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.iconRemoveType(
                                                                            item
                                                                          ).text
                                                                        )
                                                                      ),
                                                                    ]),
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm.iconRemoveType(
                                                                  item
                                                                ).accept
                                                                  ? _c(
                                                                      "b-dropdown-item-button",
                                                                      {
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handleClickRemove(
                                                                                index,
                                                                                true
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-button",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "b-tooltip",
                                                                                  rawName:
                                                                                    "v-b-tooltip.hover.left.html",
                                                                                  value:
                                                                                    _vm.handleContentRemoveTooltip(
                                                                                      item,
                                                                                      false
                                                                                    ),
                                                                                  expression:
                                                                                    "handleContentRemoveTooltip(item, false)",
                                                                                  modifiers:
                                                                                    {
                                                                                      hover: true,
                                                                                      left: true,
                                                                                      html: true,
                                                                                    },
                                                                                },
                                                                              ],
                                                                            attrs:
                                                                              {
                                                                                disabled:
                                                                                  _vm.isAskToDelteItemRow(
                                                                                    item
                                                                                  ),
                                                                                variant:
                                                                                  "d-flex flex-row align-items-center custom-transparent",
                                                                                size: "sm",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm.iconRemoveType(
                                                                              item
                                                                            )
                                                                              .accept
                                                                              ? _c(
                                                                                  "div",
                                                                                  [
                                                                                    _c(
                                                                                      _vm.getLucideIcon(
                                                                                        _vm
                                                                                          .ICONS
                                                                                          .X_CLEAR
                                                                                          .name
                                                                                      ),
                                                                                      {
                                                                                        tag: "component",
                                                                                        attrs:
                                                                                          {
                                                                                            color:
                                                                                              _vm
                                                                                                .ICONS
                                                                                                .X_CLEAR
                                                                                                .color,
                                                                                            size: 18,
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t "
                                                                        ),
                                                                        _c(
                                                                          "b",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  507,
                                                                                  "Refused"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _vm.rowLineInvalid(index)
                                                  ? _c("div", [_vm._v(" ")])
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3448851154
                                      ),
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "3", offset: "9" } },
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      disabled: _vm.isAuthorizeNewRubric,
                                      variant: "primary",
                                      block: "",
                                      size: "sm",
                                    },
                                    on: { click: _vm.handleClickAddRubric },
                                  },
                                  [
                                    _c(_vm.getLucideIcon("PlusCircle"), {
                                      tag: "component",
                                      attrs: { size: 16 },
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.FormMSG(73, "Add a rubric")
                                        ) +
                                        "\n\t\t\t\t\t\t\t\t"
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.FormMSG(371, "Information"),
            "header-class": "header-class-modal-doc-package",
            "hide-header-close": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "dialog-class": "dialog-custom-info",
            "modal-class": "mui-animation",
            fade: false,
          },
          on: {
            cancel: _vm.closeModalShowInfo,
            hidden: _vm.closeModalShowInfo,
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-end align-items-center gap-1",
                    },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "custom-outline-gray" },
                          on: { click: _vm.closeModalShowInfo },
                        },
                        [_vm._v(" " + _vm._s(_vm.FormMSG(373, "No")) + " ")]
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "primary" },
                          on: { click: _vm.closeAllModal },
                        },
                        [_vm._v(" " + _vm._s(_vm.FormMSG(374, "Yes")) + " ")]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.showInfoEdit,
            callback: function ($$v) {
              _vm.showInfoEdit = $$v
            },
            expression: "showInfoEdit",
          },
        },
        [
          _c(
            "span",
            {
              staticClass:
                "d-flex align-items-center justify-content-center w-100",
            },
            [
              _vm._v(
                _vm._s(
                  _vm.FormMSG(
                    372,
                    "Are you sure you haven't finished your modification ?"
                  )
                )
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }