<style scoped>
.planning {
	cursor: pointer;
}
.gap-1 {
	gap: 10px;
}
</style>
<template>
	<errorContainer :error="error" :warning="warning">
		<custom-loading-planning :loading="isLoading" :label="FormMSG(2, 'Prepare data of fimalac')" />
		<transition name="slide" ref="fimalac-planning-item">
			<div v-if="isMobile">
				<b-alert variant="success" show>{{ FormMSG(151, 'You must use a computer to have a more information display of the planning') }}</b-alert>
			</div>
			<div v-else class="form" @mousedown="handleMouseDown" @mouseup="handleMouseUp">
				<b-row class="animated fadeIn">
					<b-col cols="12" xl="12">
						<b-card no-body>
							<div class="p-4">
								<div id="header-context">
									<b-row>
										<b-col>
											<b-alert :show="showTooltipsHelpPlanning" variant="info" dismissible>
												<div class="d-flex mb-2">
													<component :is="getLucideIcon('Info')" />
													<div class="ml-2 fw-700 fs-16">{{ FormMSG(145, 'Planning tool') }}:</div>
												</div>
												<div class="ml-2">
													{{
														FormMSG(
															164,
															'1- By default the tool shows all users of the project, but you can always filtered them by selecting a departement.'
														)
													}}
												</div>
												<div class="ml-2">
													{{ FormMSG(165, '2- To add a new planning(s) (i.e: To activate the button "Add to selection"):') }}
												</div>
												<div v-if="isProduction" class="ml-3">
													{{ FormMSG(166, '- You must select one category, and one activity') }}
												</div>
												<div class="ml-3">
													{{ FormMSG(167, '- You must check one or more PER DIEM &raquo; flag(s)') }}
												</div>
												<div class="ml-3">
													{{
														FormMSG(
															168,
															"- And the most important, selecting the planning of user(s) in the table (exactly as Excel fonctionnality's)"
														)
													}}
												</div>
											</b-alert>
										</b-col>
									</b-row>
									<b-row class="mb-2">
										<b-col :cols="isGestionnaryOfPaie ? 8 : 10">
											<div class="pb-2 mr-1">
												<div class="d-flex flex-row" style="gap: 10px">
													<div class="d-flex flex-row justify-content-start align-items-center gp-5">
														<div v-html="getStatus(`status-not-submitted`, initSatus)"></div>
														<div class="col-form-label">{{ FormMSG(18, 'Not submitted') }}</div>
													</div>
													<div class="d-flex flex-row justify-content-start align-items-center gp-5">
														<div v-html="getStatus(`status-info`, initSatus)"></div>
														<div class="col-form-label">{{ FormMSG(304, 'Transmitted Manager') }}</div>
													</div>
													<div class="d-flex flex-row justify-content-start align-items-center gp-5">
														<div v-html="getStatus(`status-pending`, initSatus)"></div>
														<div class="col-form-label">{{ FormMSG(305, 'Validated Charged / Dir. by Prod.') }}</div>
													</div>
													<div class="d-flex flex-row justify-content-start align-items-center gp-5">
														<div v-html="getStatus(`status-validated`, initSatus)"></div>
														<div class="col-form-label">{{ FormMSG(306, 'Validated Production Admin.') }}</div>
													</div>
													<div class="d-flex flex-row justify-content-start align-items-center gp-5">
														<div v-html="getStatus(`status-refused`, initSatus)"></div>
														<div class="col-form-label">{{ FormMSG(16, 'Refused') }}</div>
													</div>
													<div class="d-flex flex-row justify-content-start align-items-center gp-5">
														<div v-html="getStatus(`status-paie`, initSatusPaie)"></div>
														<div class="col-form-label">{{ FormMSG(303, 'Validated by the payroll manager') }}</div>
													</div>
													<div v-if="isProduction" class="d-flex flex-row justify-content-start align-items-center gp-5">
														<div class="solve-status"></div>
														<div class="col-form-label">{{ FormMSG(20, 'Line to solved') }}</div>
													</div>
													<div class="d-flex flex-row justify-content-start align-items-center gp-5">
														<div class="exist-to-valid-ask"></div>
														<div class="col-form-label">{{ FormMSG(22, 'Line indicating items to validate') }}</div>
													</div>
												</div>
											</div>
										</b-col>
										<b-col :cols="isGestionnaryOfPaie ? 4 : 2" class="d-flex flex-row align-items-center justify-content-end gp-20">
											<b-button
												v-if="isGestionnaryOfPaie"
												:disabled="canSendToPay"
												class="float-left bg-tertiaire"
												@click="handleOpenModalPayroll"
											>
												<component :is="getLucideIcon('Euro')" :size="16" :stroke-width="3" />
												<span> {{ FormMSG(258, 'Send to payroll') }} </span>
												<div
													v-if="isAlreadySendEdToPayroll"
													class="icon-container"
													v-b-tooltip.hover.top:title="FormMSG(259, 'The planning has already been sent to payroll')"
												>
													<svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
														<circle
															class="checkmark-circle"
															stroke="#5cb85c"
															stroke-width="8"
															cx="26"
															cy="26"
															r="25"
															fill="#ffffff"
														/>
														<path class="checkmark-check" fill="none" stroke="#5cb85c" stroke-width="8" d="M14 27l8 8 16-16" />
													</svg>
												</div>
											</b-button>
											<b-button v-if="isProduction" variant="success" class="float-right" @click="showManageUsersRolesDialog = true">
												<component :is="getLucideIcon('UserCog')" :size="20" />
												<span> {{ FormMSG(417, "Manage user's roles") }} </span>
											</b-button>
										</b-col>
									</b-row>
									<div>
										<header-planning-fimalac
											:id="timecards_One"
											:onSearch="onSearch"
											@handleSelectDepartementChange="handleSelectDepartementChange"
											@handleSelectFunctionChange="handleSelectFunctionChange"
										/>
									</div>
									<div>
										<HeaderFlag
											ref="headerFlag"
											:dataPlanningSelect="dataPlanningSelect"
											:DateDefaultSelect="DateDefaultSelect"
											@changeDateDefaultSelect="changeDateDefaultSelect"
											@on-add-data:selection="handleOnAddDataSelection"
											@on-preference:open="handleOpenPrefrence"
											:favoriteProjectFlagItems="flagFavoritesBookmark"
											:isNotFavoriteProjectFlagItems="flagFavoritesNotBookmark"
											@refersh-on-delete-not-submitted-all="handleRefreshData"
											@close-planning-modal-delete-all="hideModal"
											@on-active-is-loading="onActiveIsLoading"
											@on-show-is-loading="onShowLoading"
											@on-close-is-loading="onCloseLoading"
											@init-persist-planning="handleInitPlanningSelect"
											@on-refresh-cell-planning-select="handleInitCellPlanningSelect"
											@validateAllPlanning="validateAllPlanning"
											:currentValidationLevel="currentValidationLevel"
											:fromComponent="fromComponent"
											:activities="activities"
											:categories="categories"
											:dataGlobal="TimeCards"
											:trimestre="getTrimestre"
											@handle-change-affichage="onHandleChangeAffichage"
											:isGestionnaryOfPaie="isGestionnaryOfPaie"
											:statusPlanningValidateAll="statusPlanningValidateAll"
											:manager="manager"
											:userValidationLevel="userValidationLevel"
											:planningLevel="planningLevel"
											:finalizationDate="finalizationDate"
											:lockPlanning="lockPlanning"
											:planningOption="planningOption"
											:previousPlanningStatus="previousPlanningStatus"
										/>
									</div>
									<div class="divider-horizontal"></div>
								</div>
								<div class="planning">
									<planning-view-tables
										ref="planning-view-table"
										:id="timecards_One"
										:selectDate="allDataTimesheets"
										:hover="true"
										:currentpage="currentPage"
										:perpage="perPage"
										:hv="hv"
										:tsfields="mapFieldsTimeCardsPlanning"
										:sizeCell="sizeCell"
										:timesheets="TimeCards"
										@setDataSizeModalPlanning="setDataSizeModalPlanning"
										@showModalPlanningContextUpdate="showModalPlanningContextUpdate"
										:DateDefaultSelect="DateDefaultSelect"
										@changeDateDefaultSelect="changeDateDefaultSelect"
										@getDataFromPagePlanning="getDataFromPagePlanning"
										@validateTsDays="validateTsDays"
										@row-validate-change="rowValidateChange"
										@deleteItemPlanning="deleteItemPlanning"
										@replacement="replacement"
										@handleRefreshReject="handleRefreshReject"
										@filter-custom-user="handleCustomFilter"
										:customFilterValue="customFilterValue"
										@on-open-modal-new-user="handleAddNewUser"
										:isPersistDataPlanning="isPersistDataPlanning"
										:initCustomFilter="initCustomFilter"
										@reset-init-custom-filter="resetInitCustomFilter"
										:hideContextMenuPlanning="hideContextMenuPlanning"
										@hideContextMenuPlanningInit="handleHideContextMenuPlanning"
										:initPlanningSelect="initPlanningSelect"
										@restore-init-planning-select="handleRestoredinitPlanningSelect"
										@setCurrentValidationLevel="setCurrentValidationLevel"
										@planning-view-tables:edit-location="handlePlanningViewTablesEditLocation"
										:isAnalyTicalCode="isAnalyTicalCode"
										:isPrestations="isPrestations"
										:isPerDiem="isPerDiem"
										:totalOfUsers="totalOfUsers"
										@showModalDetailsCell="showModalDetailsCell"
										@showModalDetailsCellMultiple="showModalDetailsCellMultiple"
										@onOpenRejectModal="onOpenRejectModal"
										:totalGrouping="totalGrouping"
										:isGestionnaryOfPaie="isGestionnaryOfPaie"
										:statusPlanningValidateAll="statusPlanningValidateAll"
										:isActiveBusy="isActiveBusy"
										@refersh-on-planning="handleRefreshData"
										@hide-column-header="handleHideHeaderColumn"
										@handleRefresHeader="handleRefresHeader"
										@on-show-is-loading="onShowLoading"
										:locationDatesSize="locationDatesSize"
										:stopInfiniteScroll="stopInfiniteScroll"
										:lockPlanning="lockPlanning"
										:isManagerPresent="isManagerPresent"
										:planningOption="planningOption"
										:previousPlanningStatus="previousPlanningStatus"
										@quick-change-load:submitted="handleChangeCell"
									/>
								</div>
								<div class="d-flex flex-row w-100 gap-1 p-4">
									<quick-addition
										ref="quick-addition"
										:open="showQuickAdditionModal"
										:external-data="dataToAdd"
										@quick-addition:close="handleQuickAdditionClose"
										@quick-change-load:submitted="handleChangeCell"
										:is-auth="isProduction"
										:locationList="locationList"
										:activeLocation="activeLocation"
										@on-active-is-loading="onActiveIsLoading"
										:headerTabPlanning="headerTabPlanning"
										:dataPlanningSelect="dataPlanningSelect"
									/>
									<substitution
										:open="showSubstitutionModal"
										:external-data="editData"
										@substitution:close="handleSubstitutionClose"
										@substitution:submitted="handleSubstitutionSubmitted"
										:customFilterValue="customFilterValue"
									/>
									<user-advanced-edit
										:open="showUserAdvancedEditModal"
										:external-data="editData"
										:is-regissor="isProduction === false"
										@user-advanced-edit:close="handleUserAdvancedEditClose"
										:locationList="locationList"
										:trimestre="getTrimestre"
										@on-active-is-loading="onActiveIsLoading"
										:currentValidationLevel="currentValidationLevel"
										@userDeleteUpdateAdvancedEdit="userDeleteUpdateAdvancedEdit"
										:isGestionnaryOfPaie="isGestionnaryOfPaie"
										:editDataMultiple="editDataMultiple"
										:isMultipleEdit="isMultipleEdit"
									/>
									<preferences
										:open="showPreferencesModal"
										:refresh-modal="refreshModalPreferences"
										@preferences:close="handlePreferencesClose"
										@preferences:refresh-end="handlePreferencesRefreshEnd"
										@is-success-to-refresh="handleRefreshData"
										:isProduction="isProduction"
										@on-show-is-loading="onShowLoading"
									/>
									<custom-filter
										:open="openCustomFilter"
										:refresh-modal="refreshModalCustomFilter"
										@custom-filter:success="handleRefreshCustomFilter"
										@custom-filter:close="handleCustomFilterClose"
										@custom-filter:refresh-end="handleCustomFilterRefreshEnd"
									/>

									<invite-new-user
										:open="openModalNewUser"
										@invite-new-user:users-added="handleInviteNewUserUsersAdded"
										@invite-new-user:close="handleInviteNewUserClose"
										@init-total:submitted="getTotalUsers"
										@on-active-is-loading="onActiveIsLoading"
									/>

									<manage-users-roles
										:open="showManageUsersRolesDialog"
										:refresh-modal="refreshModalManageUsers"
										@manage-users-roles:close="handleManageUsersRolesClose"
										@manage-users-roles:refresh-end="handleManageUsersRolesRefreshEnd"
										@set-exist-regissor:event="handleActiveRegissor"
									/>
									<incomplete
										:open="successModalIncomplete"
										:data="incompleteData"
										@modal-incomplete:close="handleIncompleted"
										:date="DateDefaultSelect"
									/>
									<edit-location
										:open="showModalEditLocation"
										:data-for-edit="dataForEditLocation"
										@edit-location:close="handleEditLocationClose"
										@edit-location:add-success="handleEditLocationAddSuccess"
									/>
									<payroll-config
										:open="modalPayrollOpen"
										@actionModal="handleOpenModalPayroll"
										:dateDefaultSelect="DateDefaultSelect"
										@is-success-to-refresh="handleRefreshData"
										:planningOption="planningOption"
										:statusPlanningValidateAll="statusPlanningValidateAll"
									/>
									<b-modal
										header-class="header-class-modal-doc-package"
										:title="FormMSG(160, 'Informations')"
										class="modal-success"
										size="xxl"
										v-model="showDetailModalCell"
										no-close-on-backdrop
										@ok="hideModalDetailsInfo"
										ok-variant="success"
										ok-only
										modal-class="mui-animation"
										:fade="false"
									>
										<div class="container-mobile animated fadeIn">
											<div class="content-detailsMyTimesheet-mobile">
												<show-detail-planning-cell-fimalac
													:showDetailData="showDetailData"
													:isMultiple="false"
													:isProduction="isProduction"
													:isGestionnaryOfPaie="isGestionnaryOfPaie"
												/>
											</div>
										</div>
									</b-modal>
									<b-modal
										header-class="header-class-modal-doc-package"
										:title="FormMSG(160, 'Informations')"
										class="modal-success"
										size="xxl"
										v-model="showDetailModalCellMultiple"
										no-close-on-backdrop
										@ok="hideModalDetailsInfoMultiple"
										ok-variant="success"
										ok-only
										modal-class="mui-animation"
										:fade="false"
									>
										<div class="container-mobile animated fadeIn">
											<div class="content-detailsMyTimesheet-mobile">
												<show-detail-planning-cell-fimalac
													:showDetailDataMultiple="showDetailDataMultiple"
													:isMultiple="true"
													:isProduction="isProduction"
													:isGestionnaryOfPaie="isGestionnaryOfPaie"
												/>
											</div>
										</div>
									</b-modal>
									<b-modal
										header-class="header-class-modal-doc-package"
										:title="FormMSG(170, 'Refuse this day')"
										class="modal-danger"
										v-model="successModalRejectedTsDay"
										@hidden="onCloseRejectModal"
										no-close-on-backdrop
										ok-variant="danger"
										ok-only
										modal-class="mui-animation"
										:fade="false"
									>
										<b-form-group :label="FormMSG(171, 'Enter your comment:')" label-for="comment" :label-cols="4">
											<b-form-textarea
												id="comment"
												@input="handleChangeComment"
												:placeholder="this.FormMSG(172, 'Enter your remarks...')"
												rows="3"
											></b-form-textarea>
										</b-form-group>
										<template #modal-footer>
											<div class="w-100">
												<b-button @click="finaliseTsDayReject" :disabled="disableReject" variant="danger" size="sm" class="float-right">
													OK
												</b-button>
											</div>
										</template>
									</b-modal>
								</div>
							</div>
						</b-card>
					</b-col>
				</b-row>
			</div>
		</transition>
	</errorContainer>
</template>

<script>
import { store } from '@/store';
import HeaderPlanningFimalac from '@/modules/fimalac/components/HeaderFimalac/HeaderPlanningFimalac';
import { getBudgetHierarchicalCategoryJson } from '@/cruds/budget.crud';
import HeaderFlag from '@/modules/fimalac/components/HeaderFimalac/HeaderFlag';
import CustomLoadingPlanning from '@/components/Plannings/Filter/CustomLoadingPlanning';
import PlanningViewTables from '@/modules/fimalac/components/planning/PlanningViewTables.vue';
import QuickAddition from '@/modules/fimalac/components/Modal/QuickAddition.vue';
import Substitution from '@/modules/fimalac/components/Modal/Substitution.vue';
import UserAdvancedEdit from '@/modules/fimalac/components/Modal/UserAdvancedEdit.vue';
import PayrollConfig from '@/modules/fimalac/components/Modal/PayrollConfig.vue';
import Preferences from '@/modules/fimalac/components/Modal/Preferences.vue';
import { NAME_CARDS_TIMESHEET_EFC, ACTION_EFC_TIMESHEET, getStatus, formatTextToSql } from '@/utils/utils';
import { GetPlanningValidation } from '@/cruds/users.crud';
import { getDepartments } from '@/cruds/department.crud';
import { rendCurrency } from '~helpers';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import {
	getProjectUsersTsDaysPlanning,
	validateTsDays,
	deleteEntry,
	rejectTsDayPlanning,
	getProjectTotalTsdayDetails,
	getPlanningStatus,
	getTotalDetailsTsdayUser,
	getLastChange,
	getProjectUserCellTsDays
} from '@/cruds/timesheets-efc.crud';
import { getProjectLocationList, getProjectFlagItems, getTotalUsersCount } from '@/cruds/fimalac.crud';
import moment from 'moment';
import _ from 'lodash';
import { getUsersAndFlagsValue } from '@/cruds/flag.crud';
import { submitSelectedTimesheet } from '@/cruds/timesheet.crud';
import { mapGetters, mapActions } from 'vuex';
import CustomFilter from '@/modules/fimalac/components/Modal/CustomFilter.vue';
import InviteNewUser from '@/modules/fimalac/components/Modal/InviteNewUser.vue';
import ManageUsersRoles from '@/modules/fimalac/components/Modal/ManageUsersRoles.vue';
import Incomplete from '@/modules/fimalac/components/Modal/Incomplete.vue';
import EditLocation from '@/modules/fimalac/components/Modal/EditLocation.vue';
import ShowDetailPlanningCellFimalac from '@/modules/fimalac/components/planning/ShowDetailPlanningCell';
import { getUsersForManageRole } from '@/cruds/users.crud';
import {
	TYPE_DELETED,
	CELL,
	STATUS_VALIDATE_PLANNING,
	PLANNING_LEVEL,
	BREAKPOINTS,
	INTERNAL_STATUS,
	ACTION_PLANNING_FLUX,
	formatDatesInString
} from '@/shared/constants';
import { generateSecureId } from '@/shared/utils';
import { generateID } from '@/utils/utils';
import { StatePersistence } from '../components/planning/CellInfo.vue';

function mapGenerateProps(newList, flag_items, category, activity, flag_in_db, result = []) {
	if (newList.length === 0) {
		return result;
	}

	let checkIfExistInResult = false;

	if (result.length > 0) {
		result.map((option) => {
			if (option.userId === newList[0].userId) {
				checkIfExistInResult = true;
				option.dates.push(newList[0].date);
			}
			return {
				...option
			};
		});
	}

	if (checkIfExistInResult === false) {
		for (let i = 0; i < flag_items.length; i++) {
			const userFinded = _.find(flag_in_db, (option) => +option.id === +newList[0].userId);
			let amountFinded = null;
			if (userFinded && userFinded.projectFlagItem) {
				amountFinded = _.find(userFinded.projectFlagItem, (option) => +option.id === +flag_items[i].id);
			}
			result.push({
				userId: newList[0].userId,
				fullName: newList[0].fullName,
				departmentName: newList[0].dataAdd.departmentName,
				functionName: newList[0].dataAdd.functionName,
				managerSalaryAcess: newList[0].dataAdd.managerSalaryAcess,
				amount: amountFinded.amount || 0,
				category,
				activity,
				flagItem: {
					...flag_items[i],
					amount: amountFinded.amount || 0
				},
				dates: [newList[0].date]
			});
		}
	}
	newList.splice(0, 1);
	return mapGenerateProps(newList, flag_items, category, activity, flag_in_db, result);
}

export default {
	name: 'Fimalac',

	components: {
		UserAdvancedEdit,
		QuickAddition,
		Substitution,
		Preferences,
		PlanningViewTables,
		CustomLoadingPlanning,
		HeaderFlag,
		HeaderPlanningFimalac,
		CustomFilter,
		InviteNewUser,
		ManageUsersRoles,
		Incomplete,
		EditLocation,
		ShowDetailPlanningCellFimalac,
		PayrollConfig
	},
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	data() {
		const { timecards_One } = NAME_CARDS_TIMESHEET_EFC;
		const { addData, updateData, deleteData } = ACTION_EFC_TIMESHEET;
		const { DEFAULT_INTERNAL, SENT_TO_PAY } = INTERNAL_STATUS;
		return {
			isActiveBusy: false,
			error: {},
			warning: '',
			showQuickAdditionModal: false,
			showSubstitutionModal: false,
			showUserAdvancedEditModal: false,
			addData,
			updateData,
			deleteData,
			timecards_One,
			allDataTimesheets: [],
			currentPage: 1,
			perPage: 0,
			hv: 'dark',
			DateDefaultSelect: {
				value: moment(Date.now()).format('MM/YYYY'),
				text: moment(Date.now()).format('MMMM YYYY')
			},
			headerTabPlanning: [],
			rendCurrency,
			recordOffSet: 0,
			recordLimit: 20, // default limit 20
			// recordLimitPerDate: 10,
			// recordOffSetPerDate: 0,
			allDepartment: [],
			TimeCards: [],
			allDataTimeCards: [],
			isPersistDataPlanning: false,
			valueToSearch: null,
			isLoading: false,
			departmentPersist: {
				id: null,
				value: null,
				message: null
			},
			functionPersist: {
				id: null,
				value: null,
				message: null
			},
			useFormInModal: true,
			dataPlanningSelect: [],
			planningToSend: [],
			editData: null,
			editDataMultiple: [],
			isMultipleEdit: false,
			isActionCrud: '',
			isRefresh: false,
			Open: false,
			isNavigateInitDate: false,
			filter: '',
			showPreferencesModal: false,
			dataToAdd: [],
			favoriteProjectFlagItems: [],
			isNotFavoriteProjectFlagItems: [],
			activities: [],
			categories: [],
			loadingAddTsDay: false,
			openDeleteModalPlanningSelect: false,
			showTooltipsHelpPlanning: false,
			orderByName: false,
			orderByFunction: false,
			openCustomFilter: false,
			openModalNewUser: false,
			initCustomFilter: false,
			hideContextMenuPlanning: false,
			refreshModalPreferences: false,
			initPlanningSelect: false,
			fromComponent: '',
			currentValidationLevel: 0,
			showManageUsersRolesDialog: false,
			customFilterValue: 0,
			refreshModalManageUsers: false,
			refreshModalCustomFilter: false,
			successModalIncomplete: false,
			incompleteData: [],
			isPerDiem: false,
			isAnalyTicalCode: true,
			isPrestations: false,
			dataForEditLocation: {},
			showModalEditLocation: false,
			locationList: [],
			activeLocation: null,
			totalOfUsers: 0,
			onLoadFirstLy: true,
			showDetailModalCell: false,
			showDetailData: {},
			successModalRejectedTsDay: false,
			rejectComment: '',
			disableReject: true,
			rejectData: [],
			getStatus,
			totalGrouping: [],
			modalPayrollOpen: false,
			infoNotCompleted: [],
			gestionnaryOfPaieCode: 4004,
			isExistRegissor: false,
			initSatus: {
				internalStatus: DEFAULT_INTERNAL
			},
			initSatusPaie: {
				internalStatus: SENT_TO_PAY
			},
			statusPlanningValidateAll: 0,
			manager: '',
			finalizationDate: '',
			planningLevel: null,
			validationPlanningType: false,
			formatTextToSql,
			codePaie: 421,
			isManagerPresent: false,
			// v2
			planningOption: null,
			showDetailModalCellMultiple: false,
			showDetailDataMultiple: [],
			sizeCell: CELL.WIDTH,
			defaultWidthCell: CELL.WIDTH,
			locationDatesSize: 0,
			lockPlanning: false,
			scrollDocument: 0,
			locationHiddens: [],
			intervalID: null,
			isMouseMove: false,
			stopInfiniteScroll: false,
			isMobile: false,
			isAlreadySendEdToPayroll: false,
			previousPlanningStatus: null,
			canSendToPay: true,
			userValidationLevel: null,
			userFullName: '',
			lapseTime: 10000,
			lastActionDate: null,
			rowPlanningSelect: []
		};
	},
	computed: {
		...mapGetters({
			flagFavoritesBookmark: 'fimalac/flagFavoritesBookmark',
			flagFavoritesNotBookmark: 'fimalac/flagFavoritesNotBookmark',
			functionOrdersFiltered: 'fimalac/functionOrdersFiltered',
			projectLocationsList: 'locations/projectLocationsList'
		}),
		getTrimestre() {
			const _date = this.DateDefaultSelect.value.split`/`;
			const month = +_date[0];
			const year = _date[1].toString().substring(2);
			if (month >= 0 && month <= 3) {
				return '1T' + year;
			} else if (month >= 4 && month <= 6) {
				return '2T' + year;
			} else if (month >= 7 && month <= 9) {
				return '3T' + year;
			} else {
				return '4T' + year;
			}
		},
		canManageCrewList() {
			return store.canManageCrewList();
		},
		isGestionnaryOfPaie() {
			if (!_.isNil(store.state.myProfile.function)) {
				return store.state.myProfile.function === this.gestionnaryOfPaieCode;
			}
			return false;
		},
		isProduction() {
			return this.validationPlanningType;
		},
		mapFieldsTimeCardsPlanning() {
			if (this.headerTabPlanning.length !== 0) {
				const _sizeCell = Math.ceil((window.innerWidth * 2.42) / this.headerTabPlanning.length);
				this.sizeCell = _sizeCell <= this.defaultWidthCell ? this.defaultWidthCell + 1 : _sizeCell;
			}
			return this.headerTabPlanning;
		},
		rendConfirmModalTxt() {
			return {
				title: this.FormMSG(255, 'Please Confirm'),
				okTitle: this.FormMSG(256, 'YES'),
				cancelTitle: this.FormMSG(257, 'NO')
			};
		}
	},
	watch: {
		projectLocationsList: {
			handler(newVal) {
				let option = [...newVal].map((item) => ({ ...item, label: item.setName }));
				this.locationList = [{ id: '0', setName: 'Off' }, ..._.cloneDeep(option)];
			},
			deep: true,
			immediate: true
		}
	},
	mounted() {
		window.addEventListener('resize', this.handleResize);
	},
	async created() {
		this.screenSizeFunc();
		await this.initialize();
	},
	beforeDestroy() {
		this.removeEventListenersAppBody();
		clearInterval(this.intervalID);
		window.removeEventListener('resize', this.handleResize);
	},
	methods: {
		...mapActions({
			getFlagFavorites: 'fimalac/getFlagFavorites',
			getFunctionOrders: 'fimalac/getFunctionOrders',
			getProjectLocationList: 'locations/getProjectLocationList',
			getNotifications: 'header/getNotifications'
		}),
		screenSizeFunc() {
			const { MOBILE } = BREAKPOINTS;
			const elementSideBar = document.getElementsByClassName('sidebar')[0];
			let sideBarWidth = 0;
			if (elementSideBar) {
				const { width: defaultWidthScreen } = elementSideBar.getBoundingClientRect();
				sideBarWidth = defaultWidthScreen;
			}
			const screenWidth = window.innerWidth - sideBarWidth;
			this.isMobile = screenWidth <= MOBILE;
			return this.isMobile;
		},
		handleResize(e) {
			e.preventDefault();
			return this.screenSizeFunc();
		},
		handleMouseDown() {
			this.isMouseMove = true;
		},
		handleMouseUp() {
			this.isMouseMove = false;
		},
		formatLocation(item) {
			const langCurrent = this.currentLang;
			const key = formatDatesInString(item.date, 'DD/MM/YYYY');
			const formattedDate = moment(key, 'DD/MM/YYYY').locale(langCurrent).format('DD ddd');
			return {
				securedId: generateSecureId(`${key}-${item.setName}-${generateID()}`),
				id: item.id,
				key,
				label: formattedDate,
				name: item.setName,
				locations: item,
				isHidden: item.isHidden,
				canNotBeHidden: item.canNotBeHidden,
				sortable: true
			};
		},
		insertDateInOrder(item) {
			const { key: keyHidden } = item;
			const dateObjet = new Date(keyHidden.split('/').reverse().join('-'));
			let position = this.headerTabPlanning.length;
			for (let i = 0; i < this.headerTabPlanning.length; i++) {
				const key = this.headerTabPlanning[i].key;
				if (key !== 'crew_member') {
					const dateExistant = formatDatesInString(key, 'DD/MM/YYYY');
					const dateExistantObjet = new Date(dateExistant.split('/').reverse().join('-'));
					if (dateObjet <= dateExistantObjet) {
						position = i;
						break;
					}
				}
			}
			this.headerTabPlanning.splice(position, 0, {
				...item,
				isHidden: false
			});
			this.locationHiddens = this.locationHiddens.filter((item) => item.key !== keyHidden);
			return this.headerTabPlanning;
		},
		async handleHideHeaderColumn(item) {
			const { keyColumn, data, isHiddens } = item;
			if (isHiddens) {
				const itemHidde = this.headerTabPlanning.splice(keyColumn, 1);
				this.locationHiddens = [...this.locationHiddens, itemHidde[0]];
				this.handleRefresHeader();
			} else {
				const locationHiddens = this.locationHiddens;
				for (let i = 0; i < data.length; i++) {
					const isExistItem = locationHiddens.find((item) => item.key === data[i].date);
					if (isExistItem) {
						this.insertDateInOrder(isExistItem);
					}
				}
				this.handleRefresHeader();
			}
		},
		async initialize() {
			const init = await store.onLoad().then(async (res) => {
				if (res) {
					this.getProjectTotalTsdayDetailsInitial();
					this.initCelluleLoadingPlanning();
					this.activeLastActionFilter();
					this.getProjectTotalTsdayDetailsInitial();
					await this.getAccessValidationPlanning();
					await this.getProjectLocationList();
					this.getActivities();
					this.getCustomCategories();
					this.toggleShowTooltipsForPlanningHelp();
					this.getFlagFavorites();
					this.getUsersRole();
					await this.getFunctionOrders();
					this.reloadData();
					document.getElementById('app-body').addEventListener('scroll', this.onScrollGlobal);
					this.lastActionDate = null;
					this.runSocketDefault();

					return res;
				}
			});
			return init;
		},
		runSocketDefault() {
			this.$nextTick(async () => {
				const token = store.state.token;
				if (token) {
					this.intervalID = setInterval(async () => {
						try {
							if (!this.isMouseMove && !this.isLoading && token) {
								const currentMounthSelected = formatDatesInString(`01/${this.DateDefaultSelect.value}`, 'YYYY-MM-DD') + 'T00:00:00Z';
								const lastActionDate = await getLastChange(currentMounthSelected);
								if (!_.isNil(this.lastActionDate)) {
									if (new Date(lastActionDate).getTime() > new Date(this.lastActionDate).getTime()) {
										await this.mapPlanningRestore().then(async ({ generateReponse }) => {
											if (JSON.stringify(this.TimeCards) !== JSON.stringify(generateReponse)) {
												this.getPlanningStatusInitial();
												this.allDataTimeCards = this.TimeCards = generateReponse;
											}
										});
									}
								}
								this.lastActionDate = lastActionDate;
							}
						} catch {
							clearInterval(this.intervalID);
						}
					}, this.lapseTime);
				} else {
					clearInterval(this.intervalID);
				}
			});
		},
		onScrollGlobal(e) {
			if (e) {
				if (this.showModalEditLocation === false) {
					const scrollTop = e.target.scrollTop;
					this.scrollDocument = scrollTop;
				}
			}
		},
		activeLastActionFilter() {
			this.customFilterValue = +window.localStorage.getItem(`last-tri-${store.userID()}-${+store.state.myProject.id}`) || 0;
			if (this.customFilterValue === 1) {
				this.orderByName = true;
				this.orderByFunction = false;
				return true;
			}
			if (this.customFilterValue === 2) {
				this.orderByName = false;
				this.orderByFunction = true;
				return true;
			}
			if (this.customFilterValue === 3) {
				this.orderByName = false;
				this.orderByFunction = false;
				return true;
			} else {
				this.orderByName = false;
				this.orderByFunction = false;
				return true;
			}
		},
		setTextInfoBullWorkFlow(text) {
			if (!_.isNil(text)) {
				switch (text) {
					case 'Not found':
						return this.FormMSG(375, 'Not found');
					case 'Submitted':
						return this.FormMSG(376, 'Transmitted Manager');
					case 'Validated HOD':
						return this.FormMSG(377, 'Validated Charged / Dir. by Prod.');
					case 'Validated':
						return this.FormMSG(388, 'Validated Production Admin.');
					case 'Refused':
						return this.FormMSG(399, 'Refused');
					default:
						return text;
				}
			}
		},
		getLabelStatus(value) {
			if (value === this.codePaie) {
				return this.FormMSG(374, 'Validated payroll manager');
			} else {
				const text = this.GetTextFromMenuNumberAndMenuValue(1008, value);
				const response = this.setTextInfoBullWorkFlow(text);
				return response;
			}
		},
		async handleActiveRegissor() {
			const result = await this.getUsersRole();
			this.getPlanningStatusInitial();
		},
		async getUsersRole() {
			const response = [...(await getUsersForManageRole(null, null, null, true, true))];
			const result = await new Promise((resolve, _) => {
				resolve(response);
			}).then((res) => {
				this.isExistRegissor = res.some((user) => user.role === 0);
				return res;
			});
			return result;
		},
		async getAccessValidationPlanning() {
			const userId = store.userID();
			const result = await GetPlanningValidation(userId);
			if (result !== undefined) {
				let type_status = result.validationLevelTs;
				this.validationPlanningType = !!type_status;
				return this.validationPlanningType;
			}
			return this.validationPlanningType;
		},
		handleOpenModalPayroll() {
			this.modalPayrollOpen = !this.modalPayrollOpen;
		},
		async getProjectTotalTsdayDetailsInitial() {
			// this.isLoading = true;
			let date = '01/' + this.DateDefaultSelect.value;
			let dateValid = formatDatesInString(date, 'YYYY-MM-DD') + 'T00:00:00Z';
			let totals = await getProjectTotalTsdayDetails(dateValid);
			return await new Promise((resolve, _) => {
				resolve(totals);
			})
				.then((res) => {
					if (res) {
						this.totalGrouping = res;
						// this.isLoading = false;
						return res;
					}
				})
				.catch(() => {
					// this.isLoading = false;
					return false;
				});
		},
		onCloseRejectModal() {
			this.successModalRejectedTsDay = false;
		},
		onOpenRejectModal(payload) {
			this.successModalRejectedTsDay = payload.successModalRejectedTsDay;
			this.rejectData = payload.rejectData;
		},
		handleChangeComment(value) {
			this.rejectComment = value;
			if (this.rejectComment.length !== 0) {
				this.disableReject = false;
			} else {
				this.disableReject = true;
			}
		},
		async finaliseTsDayReject() {
			let TsDayIds = [...this.rejectData].map((item) => +item.id);
			let Comment = this.rejectComment;
			this.successModalRejectedTsDay = true;
			if (Comment.length !== 0) {
				let result = await rejectTsDayPlanning(TsDayIds, Comment, true)
					.then(async (result) => {
						if (result) {
							this.successModalRejectedTsDay = false;
							this.handleRefreshReject();
							this.rejectComment = '';
							this.rejectData = [];
							this.$bvToast.toast('Reject with success', {
								title: this.FormMSG(301, 'success'),
								variant: 'success',
								solid: true,
								toaster: 'b-toaster-top-center',
								autoHideDelay: 3000
							});
						} else {
							this.successModalRejectedTsDay = false;
							this.handleRefreshReject();
							this.rejectComment = '';
							this.rejectData = [];
							this.$bvToast.toast('Reject error', {
								title: this.FormMSG(302, 'Error'),
								variant: 'error',
								solid: true,
								toaster: 'b-toaster-top-center',
								autoHideDelay: 3000
							});
						}
					})
					.catch((error) => {
						this.successModalRejectedTsDay = false;
						this.handleRefreshReject();
						this.rejectComment = '';
						this.rejectData = [];
						this.$bvToast.toast('Reject error', {
							title: this.FormMSG(302, 'Error'),
							variant: 'error',
							solid: true,
							toaster: 'b-toaster-top-center',
							autoHideDelay: 3000
						});
					});
				return result;
			} else {
				this.successModalRejectedTsDay = true;
			}
		},
		onHandleChangeAffichage(value) {
			if (value === 1) {
				this.isAnalyTicalCode = true;
				this.isPrestations = false;
				this.isPerDiem = false;
			}
			if (value === 2) {
				this.isAnalyTicalCode = false;
				this.isPrestations = true;
				this.isPerDiem = false;
			}
			if (value === 0) {
				this.isAnalyTicalCode = false;
				this.isPrestations = false;
				this.isPerDiem = true;
			}
		},
		handleIncompleted() {
			this.successModalIncomplete = false;
			this.incompleteData = [];
			this.handleRefreshData();
		},
		async handleRefresHeader() {
			this.isLoading = true;
			this.locationHiddens = [];
			const startDate = '01/' + this.DateDefaultSelect.value;
			const locations = [...(await this.getLocationList(startDate))];
			this.locationDatesSize = locations ? locations.length : 0;
			this.locationHiddens = locations.filter((item) => item.isHidden).map(this.formatLocation);
			const dateHeader = locations.filter((item) => !item.isHidden).map(this.formatLocation);
			this.headerTabPlanning = [
				{
					key: 'crew_member',
					label: this.FormMSG(300, 'Member of team'),
					sortable: true
				},
				...dateHeader
			];
			this.isLoading = false;
		},
		async handleEditLocationAddSuccess(options) {
			const { isLoad } = options;
			let response = await this.getProjectLocationList().then((_) => {
				if (isLoad) {
					this.handleRefresHeader();
				}
				return true;
			});
			return response;
		},
		removeEventListenersAppBody() {
			const appBody = document.getElementById('app-body');
			if (appBody) {
				appBody.removeEventListener('scroll', this.onScrollGlobal);
			}
		},
		createEventListenersAppBody() {
			const appBody = document.getElementById('app-body');
			if (appBody) {
				appBody.addEventListener('scroll', this.onScrollGlobal);
			}
		},
		async handleEditLocationClose() {
			this.dataForEditLocation = {};
			this.showModalEditLocation = false;
			this.removeEventListenersAppBody();
			setTimeout(() => {
				document.getElementById('app-body').scrollTo({ top: this.scrollDocument, behavior: 'smooth' });
				this.createEventListenersAppBody();
			}, 200);
		},

		handlePlanningViewTablesEditLocation(payload) {
			this.dataForEditLocation = payload;
			this.showModalEditLocation = true;
		},

		handleCustomFilterRefreshEnd() {
			this.refreshModalCustomFilter = false;
		},

		handleManageUsersRolesRefreshEnd() {
			this.refreshModalManageUsers = false;
		},
		setCurrentValidationLevel(value) {
			this.currentValidationLevel = value;
		},

		handleManageUsersRolesClose() {
			this.showManageUsersRolesDialog = false;
		},

		async handlePreferencesRefreshEnd() {
			this.refreshModalPreferences = false;
		},

		async handleInviteNewUserUsersAdded() {
			await this.handleRefreshReject();

			this.refreshModalPreferences = true;
			this.refreshModalManageUsers = true;
			this.refreshModalCustomFilter = true;
		},

		handleInviteNewUserClose() {
			this.openModalNewUser = false;
		},

		handleCustomFilterClose() {
			this.openCustomFilter = false;
		},

		toggleShowTooltipsForPlanningHelp() {
			if (this.$cookies.isKey('ck_suggest_planning_fimalac')) {
				this.showTooltipsHelpPlanning = false;
			} else {
				this.$cookies.set('ck_suggest_planning_fimalac', true, '5d');
				this.showTooltipsHelpPlanning = true;
			}
		},
		onShowLoading(e) {
			this.isLoading = e;
		},
		onCloseLoading(e) {
			this.isLoading = e;
		},
		onActiveIsLoading(e) {
			this.isLoading = e;
			this.hideContextMenuPlanning = true;
		},
		handleHideContextMenuPlanning(e) {
			if (e) {
				this.$refs['planning-view-table'].dataForPlanning = [];
			}
			this.hideContextMenuPlanning = e;
		},
		handleRefreshReject() {
			this.hideContextMenuPlanning = true;
			this.onRefreshCellItems(null);
		},
		handleOpenPrefrence() {
			this.showPreferencesModal = true;
		},
		async handleSubstitutionSubmitted(result) {
			this.isLoading = true;
			const { responses, options } = result;
			const updateTotal = (totalBefore, deletedItems) => {
				const { tsDayDetails } = deletedItems[0];
				return tsDayDetails.reduce((newTotal, { activity: { short }, costCenter, amount, projectFlagItem: { groupingCategory } }) => {
					const uniqueCostCenter = costCenter.toString().replace('$', short);
					return newTotal.map((item) => {
						if (item.type === groupingCategory) {
							return {
								...item,
								amount: item.amount - amount,
								totalByCostCenter: item.totalByCostCenter.map((costItem) =>
									costItem.costCenterActivity === uniqueCostCenter ? { ...costItem, amount: costItem.amount - amount } : costItem
								)
							};
						}
						return item;
					});
				}, totalBefore);
			};
			if (responses) {
				const { owner, hold } = options;
				const ids = responses.filter(Boolean).map(({ id }) => +id);
				const { convertObject } = await this.generateNewResponseCells(ids);
				const updateRow = (id, data) => {
					const rowIndex = this.TimeCards.findIndex((item) => +item.id === +id);
					if (rowIndex === -1) return;
					Object.assign(this.TimeCards[rowIndex], data);
				};
				if (owner) {
					const { rowIndex, tsDay } = this.rowPlanningSelect[0];
					const selectedId = +tsDay.id;
					if (convertObject[owner]) {
						const { tsDays, tsDaysTotal } = convertObject[owner];
						updateRow(owner, {
							tsDays: this.TimeCards[rowIndex].tsDays.map((item) => (+item.id === +tsDays[0].id ? tsDays[0] : item)),
							tsDaysTotal
						});
					} else {
						const elementIndex = this.TimeCards[rowIndex].tsDays.findIndex(({ id }) => +id === selectedId);
						if (elementIndex !== -1) {
							const deleted = this.TimeCards[rowIndex].tsDays.splice(elementIndex, 1);
							const { tsDays, tsDaysTotal } = this.TimeCards[rowIndex];
							this.TimeCards[rowIndex].tsDaysTotal = !!tsDays.length ? updateTotal(tsDaysTotal, deleted) : [];
						}
					}
				}
				if (convertObject[hold]) {
					const { tsDays, tsDaysTotal } = convertObject[hold];
					updateRow(hold, {
						tsDays: [...this.TimeCards[this.TimeCards.findIndex((item) => +item.id === +hold)].tsDays, tsDays[0]],
						tsDaysTotal
					});
				}
				this.hideContextMenuPlanning = true;
				this.showSubstitutionModal = false;
				return new Promise((resolve, _) => resolve(this.showSubstitutionModal)).then(async () => {
					const { dataForPlanning, inactiveCellsPlanningColor } = this.$refs['planning-view-table'];
					inactiveCellsPlanningColor(dataForPlanning);
					this.$refs['planning-view-table'].dataForPlanning = [];
					clearInterval(this.intervalID);
					this.runSocketDefault();
					this.isLoading = false;
				});
			}
		},
		replacement(dataPlanning) {
			const item = { ...dataPlanning[0] };
			this.editData = item;
			this.showSubstitutionModal = true;
		},
		handleInitPlanningSelect() {
			this.initPlanningSelect = true;
			this.fromComponent = '';
			this.rowPlanningSelect = [];
		},
		async handleInitCellPlanningSelect() {
			this.initPlanningSelect = true;
			const isFinished = await this.onRefreshCellItems(null);
			this.fromComponent = '';
			return isFinished;
		},
		handleRestoredinitPlanningSelect() {
			this.initPlanningSelect = false;
		},
		async handleChangeCell(elements) {
			if (elements) {
				const { responses } = elements;
				const isFinished = await this.onRefreshCellItems(responses);
				return isFinished;
			}
		},
		handleQuickAdditionClose() {
			this.showQuickAdditionModal = false;
		},
		async handleOnAddDataSelection({ dataPlanningSelect, flags_selected, category, activity }) {
			this.isLoading = true;
			clearInterval(this.intervalID);
			try {
				const datasPlanning = [..._.cloneDeep(dataPlanningSelect)];
				const result = await this.mapGetUsersAndFlagsValue(datasPlanning, flags_selected).then((res) => {
					if (res) {
						this.dataToAdd = mapGenerateProps(datasPlanning, flags_selected, category, activity, res);
						this.showQuickAdditionModal = true;
						this.$refs['quick-addition'].rowPlanningSelect = dataPlanningSelect;
						this.isLoading = false;
						if (dataPlanningSelect.length === 1) {
							this.activeLocation = { ...dataPlanningSelect[0].locations };
						} else {
							this.activeLocation = null;
						}
						return res;
					} else {
						this.isLoading = false;
						return [];
					}
				});
				return result;
			} finally {
				this.isLoading = false;
				this.runSocketDefault();
			}
		},
		prepareDataToAddByManager() {
			let dataToSend = [];
			for (let i = 0; i < this.dataToAdd.length; i++) {
				const option = this.dataToAdd[i];
				for (let j = 0; j < option.dates.length; j++) {
					const element = option.dates[j];
					const findIndex = dataToSend.findIndex((dT) => {
						return +dT.userId === +option.userId && dT.date === element.split('/').reverse().join('-');
					});
					if (findIndex === -1) {
						dataToSend = _.concat(dataToSend, {
							userId: +option.userId,
							date: element.split('/').reverse().join('-'),
							allowances: [
								{
									flagId: +option.flagItem.flagId,
									value: +option.flagItem.value,
									paycode: +option.flagItem.paycode
								}
							]
						});
					} else {
						dataToSend[findIndex].allowances.push({
							flagId: +option.flagItem.flagId,
							value: +option.flagItem.value,
							paycode: +option.flagItem.paycode
						});
					}
				}
			}

			return dataToSend;
		},
		async mapGetUsersAndFlagsValue(dataPlanningSelect, flags_selected) {
			const mapListUserID = [...new Set(dataPlanningSelect.map(({ userId }) => +userId))];
			const mapListFlagID = flags_selected.map(({ id }) => +id);
			return await getUsersAndFlagsValue(mapListUserID, mapListFlagID);
		},
		async getCustomCategories() {
			this.categories = [];
			let result = await getBudgetHierarchicalCategoryJson(-2, true, null, -1, false);
			this.categories = result;
		},
		async getActivities() {
			let type = [1];
			let sortByBookMark = false;
			let flagItems = await getProjectFlagItems(type, sortByBookMark);
			this.activities = flagItems.projectFlagItems;
		},
		handleAddNewUser(e) {
			this.openModalNewUser = e;
		},
		handlePreferencesClose() {
			this.showPreferencesModal = false;
		},
		async generateNewResponseCells(newTsDayID) {
			const arraysRes = await getProjectUserCellTsDays(newTsDayID).then((res) => {
				if (res) {
					this.getProjectTotalTsdayDetailsInitial();
					this.getPlanningStatusInitial();
					return res;
				}
			});
			const convertObject = arraysRes.reduce((accumulator, current) => {
				const { id: userId, tsDays, tsDaysTotal } = current;
				if (accumulator[userId]) {
					accumulator[userId] = { tsDays, tsDaysTotal };
				} else {
					accumulator = { ...accumulator, [userId]: { tsDays, tsDaysTotal } };
				}
				return accumulator;
			}, {});

			return { convertObject, arraysRes };
		},
		async mapInitializeServiceCellGlobal(newItem) {
			if (newItem) {
				const newTsDayID = newItem.map(({ tsDay }) => (tsDay && tsDay.id ? +tsDay.id : null)).filter(Boolean);
				const { convertObject } = await this.generateNewResponseCells(newTsDayID);
				return { responses: convertObject };
			}
			return { responses: null };
		},
		getTsDayByUserID(preced, responses) {
			if (preced.length !== 0) {
				const { userId, date: precedDate } = preced;
				const newItem = responses.find(({ user, date: newDate }) => +user.id === +userId && precedDate === formatDatesInString(newDate, 'DD/MM/YYYY'));
				if (newItem) {
					return newItem.id;
				}
				return null;
			}
			return null;
		},
		generateRowPlanningDefault(responses, rowPlanningSelect) {
			return responses ? rowPlanningSelect.map((item) => ({ ...item, tsDay: { id: this.getTsDayByUserID(item, responses) } })) : rowPlanningSelect;
		},
		async onRefreshCellItems(responses) {
			const rowPlanningSelect = this.generateRowPlanningDefault(responses, this.rowPlanningSelect);
			if (rowPlanningSelect && rowPlanningSelect.length !== 0) {
				const { responses } = await this.mapInitializeServiceCellGlobal(rowPlanningSelect);
				if (responses) {
					const exc = rowPlanningSelect
						.map((item) => {
							const { rowIndex, elementElementIndex, userId, tsDay } = item;
							if (tsDay) {
								const { id: tsDayID } = tsDay;
								const cellItems = responses[userId];
								if (cellItems) {
									const { tsDays, tsDaysTotal } = cellItems;
									const newItem = tsDays.find(({ id }) => +id === +tsDayID);
									if (newItem) {
										if (this.TimeCards[rowIndex] && this.TimeCards[rowIndex].tsDays) {
											if (elementElementIndex !== -1) {
												this.TimeCards[rowIndex].tsDays.splice(elementElementIndex, 0, newItem);
											} else {
												this.TimeCards[rowIndex].tsDays.push(newItem);
											}
										}
										this.TimeCards[rowIndex] = { ...this.TimeCards[rowIndex], tsDaysTotal };
										return true;
									}
									return true;
								} else {
									const { id: tsDayID } = tsDay;
									const elementIndex = this.TimeCards[rowIndex].tsDays.findIndex(({ id }) => +id === +tsDayID);
									const changedTotalElement = (TsDayTotalBefore, tsDayDeleted) => {
										const { tsDayDetails } = tsDayDeleted[0];
										let newTotal = TsDayTotalBefore;
										for (let i = 0; i < tsDayDetails.length; i++) {
											const {
												activity: { short },
												costCenter,
												amount: totalAmountElementsBeforeDelete,
												projectFlagItem: { groupingCategory }
											} = tsDayDetails[i];
											const newCostCenterUnique = `${costCenter.toString().replace('$', short)}`;
											newTotal = newTotal.map((item) => {
												if (item.type === groupingCategory) {
													return {
														...item,
														amount: item.amount - totalAmountElementsBeforeDelete,
														totalByCostCenter: item.totalByCostCenter.map((costItem) =>
															costItem.costCenterActivity === newCostCenterUnique
																? { ...costItem, amount: costItem.amount - totalAmountElementsBeforeDelete }
																: costItem
														)
													};
												}
												return item;
											});
										}
										return newTotal;
									};
									if (elementIndex !== -1) {
										const tsDayDeleted = this.TimeCards[rowIndex].tsDays.splice(elementIndex, 1);
										const { tsDays: tsDaysHaveChange, tsDaysTotal: TsDayTotalBefore } = this.TimeCards[rowIndex];
										const newTsDayTotal = changedTotalElement(TsDayTotalBefore, tsDayDeleted);
										this.TimeCards[rowIndex].tsDaysTotal = tsDaysHaveChange.length === 0 ? [] : newTsDayTotal;
										return true;
									}
									return true;
								}
							}
							return true;
						})
						.every((isFinish) => isFinish);
					const { dataForPlanning, inactiveCellsPlanningColor } = this.$refs['planning-view-table'];
					inactiveCellsPlanningColor(dataForPlanning);
					this.$refs['planning-view-table'].dataForPlanning = [];
					clearInterval(this.intervalID);
					this.runSocketDefault();
					return exc;
				}
				return false;
			}
		},
		async handleUserAdvancedEditClose(action) {
			this.isLoading = true;
			const { activeChangeAction } = action;
			this.showUserAdvancedEditModal = false;
			return await new Promise((resolve) => resolve(this.showUserAdvancedEditModal)).then(() => {
				this.hideContextMenuPlanning = true;
				this.editDataMultiple = [];
				this.editData = null;
				if (activeChangeAction) {
					this.onRefreshCellItems(null);
				}
				this.isLoading = false;
			});
		},
		handleClickShowSubstitutionModal() {
			this.showSubstitutionModal = true;
		},
		handleSubstitutionClose() {
			this.showSubstitutionModal = false;
		},
		async reloadData() {
			this.onLoadFirstLy = true;
			this.allDepartment = await getDepartments(false, false);
			const totalOfUsers = this.getTotalUsers();
			const initializeGlobal = await this.initializeGlobal();
			return await new Promise((resolve, _) => {
				resolve(initializeGlobal, [totalOfUsers]);
			}).then(async (res) => {
				this.getPlanningStatusInitial();
				this.onLoadFirstLy = false;
				return res;
			});
		},
		async getTotalUsers() {
			const result = await getTotalUsersCount(+store.projectID(), true, true).then((res) => {
				if (res) {
					this.totalOfUsers = res;
					return res;
				}
			});
			return result;
		},
		resetDataAfterAddUpdate() {
			this.dataPlanningSelect = [];
			this.planningToSend = [];
		},
		setFilterName(name, value) {
			let obj = {};
			obj[name] = value;
			return { ...obj, projectId: +store.projectID(), activateCrewAccessRestriction: true };
		},
		async getLocationList(startDate) {
			let locationStartDate = formatDatesInString(startDate, 'YYYY-MM-DD') + 'T00:00:00Z';
			let locationList = await getProjectLocationList(locationStartDate);
			return locationList;
		},
		resetInitCustomFilter(bool, value) {
			this.initCustomFilter = bool;
			this.customFilterValue = value;
		},
		handleRefreshCustomFilter() {
			this.customFilterValue = 3;
			this.formChangedPlanningView(this.DateDefaultSelect, this.timecards_One);
		},
		handleCustomFilter(value) {
			if (value === 1) {
				this.orderByName = true;
				this.orderByFunction = false;
				this.customFilterValue = 1;
				return this.formChangedPlanningView(this.DateDefaultSelect, this.timecards_One);
			}
			if (value === 2) {
				this.orderByName = false;
				this.orderByFunction = true;
				this.customFilterValue = 2;
				return this.formChangedPlanningView(this.DateDefaultSelect, this.timecards_One);
			}
			if (value === 3) {
				this.openCustomFilter = true;
			} else {
				this.orderByName = false;
				this.orderByFunction = false;
				this.customFilterValue = 0;
				return this.formChangedPlanningView(this.DateDefaultSelect, this.timecards_One);
			}
		},
		async initializeGlobal() {
			this.isLoading = true;
			let data = [...(await this.mapInitializeServiceGlobal())];
			return await new Promise((resolve, _) => {
				resolve(data);
			}).then(async (responses) => {
				const startDate = '01/' + this.DateDefaultSelect.value;
				const locations = [...(await this.getLocationList(startDate))];
				this.locationDatesSize = locations ? locations.length : 0;
				this.locationHiddens = locations.filter((item) => item.isHidden).map(this.formatLocation);
				const dateHeader = locations.filter((item) => !item.isHidden).map(this.formatLocation);
				this.headerTabPlanning = [
					{
						key: 'crew_member',
						label: this.FormMSG(300, 'Member of team'),
						sortable: true
					},
					...dateHeader
				];
				this.TimeCards = this.allDataTimeCards = responses;
				this.isLoading = false;
				return responses;
			});
		},
		async formChanged(_date, idTable) {
			if (this.isRefresh === false) {
				if (idTable === this.timecards_One) {
					if (_date.value !== 0) {
						this.miniSpinnerLoadingTimeCards = true;
						this.TimeCards = await this.getProjectUserTsDaysDataInitial();
						this.miniSpinnerLoadingTimeCards = false;
					}
				}
			} else {
				let resultTimeCardsDATA = await this.getProjectUserTsDaysDataInitial();
				this.allDataTimeCards = resultTimeCardsDATA;
				let DATA = this.allDataTimeCards;
				this.TimeCards = DATA.filter((item) => {
					if (moment(item.date).format('MM/YYYY').includes(_date.value)) {
						return true;
					}
					return false;
				});
				this.isRefresh = false;
			}
		},
		handleSelectedFormFilter(data) {
			this.DateDefaultSelect = data.e;
			this.formChanged(data.e, data.id);
		},
		setDataSizeModalPlanning(dataPlanning) {
			this.dataPlanningSelect = dataPlanning;
			this.planningToSend = dataPlanning;
			this.generateGlobalIndex(dataPlanning);
			if (this.editData !== null) {
				this.isActionCrud = this.updateData;
			} else {
				this.isActionCrud = this.addData;
			}
		},
		async changeDateDefaultSelect(item) {
			this.lastActionDate = null;
			const { cleanGlobalAction } = this.$refs['planning-view-table'];
			const isClean = cleanGlobalAction();
			return new Promise((resolve, _) => resolve(isClean)).then((res) => {
				if (res) {
					this.dataPlanningSelect = [];
					this.planningToSend = [];
					this.DateDefaultSelect = item;
					return this.formChangedPlanningView(this.DateDefaultSelect, this.timecards_One);
				}
			});
		},
		showModalPlanningContextUpdate(dataPlanning) {
			if (dataPlanning.length === 1) {
				this.isActionCrud = this.updateData;
				this.dataPlanningSelect = dataPlanning;
				this.planningToSend = dataPlanning;
				const item = dataPlanning[0];
				this.editData = item;
				this.isMultipleEdit = false;
				this.showUserAdvancedEditModal = true;
				return;
			} else if (dataPlanning.length >= 2) {
				this.showUserAdvancedEditModal = true;
				this.editDataMultiple = dataPlanning.filter((item) => item.tsDay !== undefined);
				this.isMultipleEdit = true;
				return;
			} else {
				this.dataPlanningSelect = dataPlanning;
				this.planningToSend = dataPlanning;
				this.editData = null;
				this.Open = false;
				return;
			}
		},
		checkActionPlanningBefore(data) {
			let GROUPING = {
				INITIAL: true,
				INPROGRESS: true,
				FINAL: true
			};
			if (data) {
				const {
					finalizationDate,
					status,
					planningLevel,
					isManagerPresent,
					isPlanningSentBefore,
					previousPlanningStatus,
					isValid,
					isFinalValid,
					canSendToPay,
					userValidationLevel,
					userFullName
				} = data;
				this.statusPlanningValidateAll = +status;
				this.manager = userFullName;
				this.userValidationLevel = userValidationLevel;
				this.isAlreadySendEdToPayroll = isPlanningSentBefore;
				this.finalizationDate = finalizationDate;
				this.planningLevel = planningLevel;
				this.isManagerPresent = isManagerPresent;
				this.previousPlanningStatus = previousPlanningStatus;
				this.canSendToPay = !canSendToPay;
				const { DEFAULT_STATUS, INITIAL, INPROGRESS, FINAL, LOCKED } = STATUS_VALIDATE_PLANNING;
				const { ADM_PROD } = PLANNING_LEVEL;
				this.lockPlanning = status === LOCKED;
				const disabled = !isValid;
				const isFinalDisabled = !isFinalValid;
				if (this.currentValidationLevel !== ADM_PROD) {
					if (status === DEFAULT_STATUS) {
						return {
							...GROUPING,
							INITIAL: disabled
						};
					}
					if ([INITIAL, INPROGRESS, FINAL].includes(status)) {
						return {
							...GROUPING,
							INPROGRESS: disabled,
							FINAL: isFinalDisabled
						};
					}
					return GROUPING;
				} else {
					return { ...GROUPING, FINAL: isFinalDisabled };
				}
			}
			return GROUPING;
		},
		async getPlanningStatusInitial() {
			this.statusPlanningValidateAll = 0;
			let dateValue = '01/' + this.DateDefaultSelect.value;
			let date = formatDatesInString(dateValue, 'YYYY-MM-DD') + 'T00:00:00Z';
			let result = await getPlanningStatus(date);
			return await new Promise((resolve, _) => {
				resolve(result);
			})
				.then((res) => {
					const option = this.checkActionPlanningBefore(res);
					this.planningOption = option;
					return res;
				})
				.catch(() => {
					return false;
				});
		},
		validateAllPlanning(type) {
			const { FINAL } = ACTION_PLANNING_FLUX;
			const isFinal = type === FINAL;
			const isLockPlanning = false;
			const defaultDateSelectedPlanning = '01/' + this.DateDefaultSelect.value;
			const currentTime = 'T' + moment().format('HH:mm:ss') + 'Z';
			const dateRequest = moment(defaultDateSelectedPlanning, 'DD/MM/YYYY').format('YYYY-MM-DD') + 'T00:00:00Z';
			const finalizationDate = moment().format('YYYY-MM-DD') + currentTime;
			const isProd = this.FormMSG(243, 'valid');
			const regiss = this.FormMSG(244, 'send');
			const confirmMessage = this.isProduction
				? `${this.FormMSG(241, 'Please confirm that you want to')} ${isProd} ${this.FormMSG(242, 'all the timesheet in planning')}`
				: `${this.FormMSG(241, 'Please confirm that you want to')} ${regiss} ${this.FormMSG(242, 'all the timesheet in planning')}`;
			this.$bvModal
				.msgBoxConfirm(confirmMessage, {
					title: this.rendConfirmModalTxt.title,
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'primary',
					okTitle: this.rendConfirmModalTxt.okTitle,
					cancelTitle: this.rendConfirmModalTxt.cancelTitle,
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false,
					modalClass: 'mui-animation'
				})
				.then(async (value) => {
					if (value) {
						this.isLoading = true;
						const result = await validateTsDays(null, dateRequest, finalizationDate, isFinal, isLockPlanning);
						this.incompleteData = result;
						if (this.incompleteData.length !== 0) {
							this.successModal = false;
							this.isLoading = false;
							this.successModalIncomplete = true;
							return true;
						} else {
							const strTitle = this.FormMSG(9, 'Success');
							const message = this.isProduction
								? this.FormMSG(245, 'All timesheets have been validated')
								: this.FormMSG(246, 'All timesheets have been submitted for validation send');
							this.$bvToast.toast(message, {
								title: strTitle,
								variant: 'success',
								toaster: 'b-toaster-top-center',
								solid: true
							});
							const { dataForPlanning, inactiveCellsPlanningColor } = this.$refs['planning-view-table'];
							inactiveCellsPlanningColor(dataForPlanning);
							this.$refs['planning-view-table'].dataForPlanning = [];
							this.successModalIncomplete = false;
							this.handleRefreshData();
							this.hideContextMenuPlanning = true;
							this.$refs['planning-view-table'].dataForPlanning = [];
							this.isLoading = false;
							return true;
						}
					}
				})
				.catch((err) => {
					const message = this.FormMSG(500, 'You can not send an email to this address when you are in DEV or TEST environment');
					this.$bvToast.toast(message, {
						title: this.FormMSG(23, 'warning'),
						variant: 'warning',
						solid: true,
						toaster: 'b-toaster-top-center',
						autoHideDelay: 3000
					});
					this.successModalIncomplete = false;
					this.handleRefreshData();
					this.hideContextMenuPlanning = true;
					this.$refs['planning-view-table'].dataForPlanning = [];
					return false;
				});
		},
		async validateTsDays(item) {
			if (item) {
				this.$bvModal
					.msgBoxConfirm(this.FormMSG(238, 'Please confirm that you want to valid this timesheets'), {
						title: this.rendConfirmModalTxt.title,
						size: 'sm',
						buttonSize: 'sm',
						okVariant: 'primary',
						okTitle: this.rendConfirmModalTxt.okTitle,
						cancelTitle: this.rendConfirmModalTxt.cancelTitle,
						footerClass: 'p-2',
						hideHeaderClose: false,
						centered: false,
						modalClass: 'mui-animation'
					})
					.then(async (value) => {
						if (value) {
							this.isLoading = true;
							const id = +item.id;
							const result = await validateTsDays(id, null, null, false, false);
							if (result !== null) {
								this.successModal = true;
								this.hideContextMenuPlanning = true;
								const isFinished = this.onRefreshCellItems(null);
								if (isFinished) {
									this.isLoading = false;
								}
							}
						}
					})
					.catch((err) => {
						const message = this.FormMSG(2200, 'There is a server error');
						this.$bvToast.toast(message, {
							title: this.FormMSG(23, 'warning'),
							variant: 'warning',
							solid: true,
							toaster: 'b-toaster-top-center',
							autoHideDelay: 3000
						});
						this.successModal = true;
						this.hideContextMenuPlanning = true;
						const isFinished = this.onRefreshCellItems(null);
						if (isFinished) {
							this.isLoading = false;
						}
					});
			}
		},
		async onRefreshGlobalNotSelected(generateNewID, rowIndex) {
			const userId = this.TimeCards[rowIndex].id;
			const { convertObject } = await this.generateNewResponseCells(generateNewID);
			const { tsDays, tsDaysTotal } = convertObject[userId];
			const tsDayReference = tsDays.reduce((accumulator, current) => {
				const { id } = current;
				if (accumulator[id]) {
					accumulator[id] = current;
				} else {
					accumulator = { ...accumulator, [id]: current };
				}
				return accumulator;
			}, {});
			const newTsDays = this.TimeCards[rowIndex].tsDays.map((item) => {
				if (tsDayReference[item.id]) return tsDayReference[item.id];
				return item;
			});
			this.TimeCards[rowIndex].tsDays = newTsDays;
			this.TimeCards[rowIndex].tsDaysTotal = tsDaysTotal;
			this.hideContextMenuPlanning = true;
			this.$refs['planning-view-table'].dataForPlanning = [];
			return true;
		},
		async rowValidateChange(item) {
			this.$bvModal
				.msgBoxConfirm(this.FormMSG(238, 'Please confirm that you want to send all unsent timesheets to the validation'), {
					title: this.rendConfirmModalTxt.title,
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'primary',
					okTitle: this.rendConfirmModalTxt.okTitle,
					cancelTitle: this.rendConfirmModalTxt.cancelTitle,
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false,
					modalClass: 'mui-animation'
				})
				.then(async (value) => {
					if (value) {
						this.isLoading = true;
						const { generateNewID, rowIndex } = item;
						const response = await submitSelectedTimesheet(generateNewID, true);
						const variant = response ? 'success' : 'warning';
						const content = {
							success: { title: [9, 'Success'], message: [12, 'All timesheets have been submitted for validation send'] },
							warning: {
								tittle: [239, 'Warning'],
								message: [240, 'Check the timesheet(s) because there is an timesheets that has not been to sent for validation']
							}
						};
						this.$bvToast.toast(this.FormMSG(...content[variant].message), {
							title: this.FormMSG(...content[variant].title),
							variant,
							solid: true,
							toaster: 'b-toaster-top-center',
							autoHideDelay: 3000
						});
						if (rowIndex) {
							const isFinished = this.onRefreshGlobalNotSelected(generateNewID, rowIndex);
							if (isFinished) {
								this.isLoading = false;
							}
						} else {
							const isFinished = this.onRefreshCellItems(null);
							if (isFinished) {
								this.isLoading = false;
							}
						}
					}
				})
				.catch((err) => console.log(err));
		},
		initCelluleLoadingPlanning() {
			StatePersistence.setState(-1, null);
		},
		async formChangedPlanningView(_date, _) {
			this.isLoading = true;
			try {
				this.getProjectTotalTsdayDetailsInitial();
				this.getPlanningStatusInitial();
				this.initCelluleLoadingPlanning();
				const startDate = '01/' + this.DateDefaultSelect.value;
				const locations = [...(await this.getLocationList(startDate))];
				this.locationDatesSize = locations ? locations.length : 0;
				this.locationHiddens = locations.filter((item) => item.isHidden).map(this.formatLocation);
				const dateHeader = locations.filter((item) => !item.isHidden).map(this.formatLocation);
				this.headerTabPlanning = [
					{
						key: 'crew_member',
						label: this.FormMSG(300, 'Member of team'),
						sortable: true
					},
					...dateHeader
				];
				this.DateDefaultSelect = _date;
				window.localStorage.setItem(`last-tri-${store.userID()}-${+store.state.myProject.id}`, JSON.stringify(this.customFilterValue));
				if (this.departmentPersist.value === null) {
					const responses = await this.mapPlanningRestoreGlobal().then(async ({ newGenerateGlobalReponse }) => {
						this.allDataTimeCards = this.TimeCards = newGenerateGlobalReponse;
						return newGenerateGlobalReponse;
					});
					return responses;
				} else {
					const responses = await this.mapPlanningRestore().then(async ({ generateReponse }) => {
						this.TimeCards = generateReponse;
						return generateReponse;
					});
					return responses;
				}
			} finally {
				this.isLoading = false;
			}
		},
		setIsNavigateInitDate(boolean) {
			this.isNavigateInitDate = boolean;
		},
		showModal() {
			this.isActionCrud = this.addData;
			this.Open = true;
		},
		showModalDetailsCell(payload) {
			this.showDetailModalCell = payload.showDetailModalCell;
			this.showDetailData = { ...payload.showDetailData };
		},
		showModalDetailsCellMultiple(payload) {
			this.showDetailModalCellMultiple = payload.showDetailModalCellMultiple;
			this.showDetailDataMultiple = payload.showDetailDataMultiple;
		},
		hideModalDetailsInfo() {
			this.showDetailModalCell = false;
			this.showDetailData = {};
		},
		hideModalDetailsInfoMultiple() {
			this.showDetailModalCellMultiple = false;
			this.showDetailDataMultiple = [];
		},
		hideModal() {
			this.Open = false;
			this.dataPlanningSelect = [];
			this.planningToSend = [];
			this.editData = null;
			this.isActionCrud = '';
			this.isPersistDataPlanning = false;
			this.openDeleteModalPlanningSelect = false;
		},
		async mapPlanningRestore() {
			const filters = [...Array(this.recordOffSet + 1)].map((_, i) => ({
				...this.setFilterName('startDate', `${formatDatesInString(`01/${this.DateDefaultSelect.value}`, 'YYYY-MM-DD')}T00:00:00Z`),
				recordLimit: this.recordLimit,
				recordOffSet: i,
				department: this.departmentPersist.value,
				userFunction: this.functionPersist.value,
				keyWord: this.valueToSearch,
				orderByName: this.orderByName,
				orderByFunction: this.orderByFunction
			}));
			const responses = Promise.all(
				filters.map((filter) => {
					const FunctionValueDep = [0, 3].includes(this.customFilterValue) ? this.functionOrdersFiltered : [];
					return getProjectUsersTsDaysPlanning(filter, FunctionValueDep);
				})
			).then(async (res) => {
				const formatData = res.flat();
				return { generateReponse: formatData };
			});
			return responses;
		},
		async mapPlanningRestoreGlobal() {
			const globalFilters = [...Array(this.recordOffSet + 1)].map((_, i) => ({
				...this.setFilterName('startDate', `${formatDatesInString(`01/${this.DateDefaultSelect.value}`, 'YYYY-MM-DD')}T00:00:00Z`),
				recordLimit: this.recordLimit,
				recordOffSet: i,
				department: null,
				userFunction: null,
				keyWord: null,
				orderByName: this.orderByName,
				orderByFunction: this.orderByFunction
			}));
			const responses = await Promise.all(
				globalFilters.map((newfilter) => {
					const FunctionValueDep = [0, 3].includes(this.customFilterValue) ? this.functionOrdersFiltered : [];
					return getProjectUsersTsDaysPlanning(newfilter, FunctionValueDep);
				})
			).then((res) => {
				const newFormatData = res.flat();
				return { newGenerateGlobalReponse: newFormatData };
			});
			return responses;
		},
		async mapInitializeServiceGlobal() {
			let Filter = {
				...this.setFilterName('startDate', formatDatesInString(`01/${this.DateDefaultSelect.value}`, 'YYYY-MM-DD') + 'T00:00:00Z'),
				recordLimit: this.recordLimit,
				recordOffSet: this.recordOffSet,
				department: this.departmentPersist.value,
				userFunction: this.functionPersist.value,
				keyWord: this.valueToSearch,
				orderByName: this.orderByName,
				orderByFunction: this.orderByFunction
			};
			delete Filter.activateCrewAccessRestriction;
			const FunctionValueDep = [0, 3].includes(this.customFilterValue) ? this.functionOrdersFiltered : [];
			let data = [...(await getProjectUsersTsDaysPlanning(Filter, FunctionValueDep))];
			return data;
		},
		async handleRefreshData() {
			this.isLoading = true;
			try {
				if (!this.onLoadFirstLy) {
					this.getTotalUsers();
					this.getProjectTotalTsdayDetailsInitial();
					const responses = await this.mapPlanningRestore().then(async ({ generateReponse }) => {
						await this.getNotifications();
						this.getPlanningStatusInitial();
						this.allDataTimeCards = this.TimeCards = generateReponse;
						clearInterval(this.intervalID);
						this.runSocketDefault();
						this.hideModal();
						return generateReponse;
					});
					return responses;
				}
			} finally {
				const globalResolve = [this.allDataTimeCards, this.TimeCards];
				return new Promise((resolve, _) => resolve(globalResolve)).then(async (res) => {
					if (res) {
						this.isLoading = false;
						if (!this.isLoading) {
							const { cleanGlobalAction } = this.$refs['planning-view-table'];
							cleanGlobalAction();
						}
						return res;
					}
				});
			}
		},
		async getDataFromPagePlanning(recordOffSet) {
			const onLoading = async () => {
				this.recordOffSet += recordOffSet;
				let data = [...(await this.mapInitializeServiceGlobal())];
				const sizeDefaultItems = data.length;
				if (sizeDefaultItems !== 0) {
					this.stopInfiniteScroll = false;
					this.isActiveBusy = true;
					let nextDataTimeCards = [...this.TimeCards, ...data];
					let nextDataAllTimeCards = [...this.allDataTimeCards, ...data];
					this.TimeCards = nextDataTimeCards;
					this.allDataTimeCards = nextDataAllTimeCards;
					this.isActiveBusy = false;
				} else {
					this.stopInfiniteScroll = true;
					this.recordOffSet -= 1;
				}
			};
			return await this.showLoader(onLoading, this.stopInfiniteScroll ? null : 'fimalac-planning-item');
		},
		async userDeleteUpdateAdvancedEdit(item) {
			const { id, askToDelete, content_title } = item;
			let result = await deleteEntry([id], false)
				.then(async (res) => {
					await this.getNotifications();
					this.$bvToast.toast(content_title, {
						title: this.FormMSG(249, 'success'),
						variant: 'success',
						solid: true,
						toaster: 'b-toaster-top-center',
						autoHideDelay: 3000
					});
				})
				.catch(async (e) => {
					await this.getNotifications();
					let _title = this.FormMSG(2510, 'Email is not send');
					this.$bvToast.toast(_title, {
						title: this.FormMSG(2512, 'warning'),
						variant: 'warning',
						solid: true,
						toaster: 'b-toaster-top-center',
						autoHideDelay: 3000
					});
				});
			return result;
		},
		changeToBooleanStatusAskToDelete(status) {
			if (status && status !== -1) {
				return true;
			}
			return false;
		},
		generateGlobalIndex(item) {
			if (item) {
				const newItem = [item].flat();
				this.rowPlanningSelect = newItem.sort((a, b) => a.rowIndex - b.rowIndex);
				return this.rowPlanningSelect;
			}
			return null;
		},
		deleteItemPlanning(item, unique) {
			const { key, undo } = unique;
			const { ASK } = TYPE_DELETED;
			if (this.dataPlanningSelect.length > 1) {
				// this.generateGlobalIndex(this.dataPlanningSelect);
				// this.$refs['headerFlag'].rowPlanningSelect = this.rowPlanningSelect;
				this.$refs['headerFlag'].onOpenModalToAllDelete();
				this.fromComponent = 'FROM-PLANNING';
				return true;
			} else {
				let _id = +item[0].tsDay.id;
				let status = item[0].tsDay.askToDeletes;
				// this.generateGlobalIndex(item[0]);
				let isAsk = this.changeToBooleanStatusAskToDelete(status);
				let content_title = '';
				if (isAsk) {
					content_title = this.FormMSG(251, 'You have canceled the sending of timesheets for delete');
				} else {
					content_title = this.FormMSG(250, 'Timesheets item delete successfully');
				}
				let title = this.FormMSG(248, 'Please confirm');
				let txt = isAsk
					? this.isGestionnaryOfPaie
						? this.FormMSG(247, 'Please confirm that you want delete this timesheet')
						: this.FormMSG(2470, 'Would you like to undo this timesheet')
					: key === ASK
					? this.FormMSG(24777, 'Would you like ask for the deletion of this timesheets')
					: this.FormMSG(247, 'Please confirm that you want delete this timesheet');
				let result = this.$bvModal
					.msgBoxConfirm(txt, {
						title: title,
						size: 'sm',
						buttonSize: 'sm',
						okVariant: 'danger',
						okTitle: this.FormMSG(125445, 'ok'),
						cancelTitle: this.FormMSG(85296, 'Cancel'),
						footerClass: 'p-2',
						hideHeaderClose: false,
						centered: false,
						modalClass: 'mui-animation'
					})
					.then(async (v) => {
						if (!v) return;
						else {
							this.onActiveIsLoading(true);
							let result = await deleteEntry([_id], undo)
								.then(async (res) => {
									await this.getNotifications();
									this.$bvToast.toast(content_title, {
										title: this.FormMSG(249, 'success'),
										variant: 'success',
										solid: true,
										toaster: 'b-toaster-top-center',
										autoHideDelay: 3000
									});
									this.hideContextMenuPlanning = true;
									const isFinished = await this.onRefreshCellItems(null);
									if (isFinished) {
										this.onActiveIsLoading(false);
									}
								})
								.catch(async (e) => {
									await this.getNotifications();
									let _title = this.FormMSG(2510, 'Email is not send');
									this.$bvToast.toast(_title, {
										title: this.FormMSG(2512, 'warning'),
										variant: 'warning',
										solid: true,
										toaster: 'b-toaster-top-center',
										autoHideDelay: 3000
									});
									const isFinished = await this.onRefreshCellItems(null);
									if (isFinished) {
										this.onActiveIsLoading(false);
									}
								});
							return result;
						}
					})
					.catch(() => {
						return false;
					});
				return result;
			}
		},
		async handleSelectDepartementChange(department) {
			this.isLoading = true;
			try {
				if (this.onLoadFirstLy === false) {
					this.dataPlanningSelect = [];
					this.planningToSend = [];
					this.departmentPersist = department;
					this.functionPersist = {
						...this.functionPersist,
						id: null,
						value: null,
						message: null
					};
					this.stopInfiniteScroll = this.departmentPersist.value !== null ? this.stopInfiniteScroll : false;
					const responses = await this.mapPlanningRestore().then(async ({ generateReponse }) => {
						this.allDataTimeCards = this.TimeCards = generateReponse;
						return generateReponse;
					});
					return responses;
				}
			} finally {
				this.isLoading = false;
			}
		},
		async handleSelectFunctionChange(functionFilter) {
			this.isLoading = true;
			try {
				if (!this.onLoadFirstLy) {
					this.dataPlanningSelect = [];
					this.planningToSend = [];
					this.functionPersist = functionFilter;
					const responses = await this.mapPlanningRestore().then(async ({ generateReponse }) => {
						this.allDataTimeCards = this.TimeCards = generateReponse;
						return generateReponse;
					});
					return responses;
				}
			} finally {
				this.isLoading = false;
			}
		},
		async onSearch(searchValue, action, idTable) {
			if (this.onLoadFirstLy === false) {
				this.filter = searchValue;
				this.valueToSearch = formatTextToSql(searchValue);
				if (idTable === this.timecards_One) {
					let DATA = this.allDataTimeCards;
					if (searchValue.length === 0) {
						this.TimeCards = DATA;
					} else {
						if (action) {
							this.TimeCards = DATA;
						} else {
							const responses = await this.mapPlanningRestore().then(async ({ generateReponse }) => {
								this.TimeCards = generateReponse;
								return generateReponse;
							});
							return responses;
						}
					}
				}
			}
		}
	}
};
</script>
<style scoped>
@keyframes sonar {
	0% {
		transform: scale(0.6);
		opacity: 1;
	}
	100% {
		transform: scale(1.2);
		opacity: 0;
	}
}
@keyframes pulse {
	0% {
		transform: scale(1);
	}
	20% {
		transform: scale(0.9);
	}
	50% {
		transform: scale(1);
	}
	80% {
		transform: scale(0.6);
	}
	100% {
		transform: scale(1);
	}
}
.divider-horizontal {
	border-bottom: 2px solid #ffffff;
}
.status-not-submitted {
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background-color: #fcede9;
	border: 1px solid #ea4e2c;
	animation: pulse 1.5s infinite;
}
.status-not-submitted:after {
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background-color: #ea4e2c;
	animation: sonar 1.5s infinite;
}
.status-info {
	width: 12px;
	height: 12px;
	border-radius: 2px;
	background-color: #bdd5f3;
	border: 1px solid #225cbd;
}
.status-pending {
	width: 12px;
	height: 12px;
	border-radius: 2px;
	background-color: #f7eac6;
	border: 1px solid #cd9a16;
}
.status-validated {
	width: 12px;
	height: 12px;
	border-radius: 2px;
	background-color: #caf5ba;
	border: 1px solid #3c7442;
}
.status-refused {
	width: 12px;
	height: 12px;
	border-radius: 2px;
	background-color: #ffb0b0;
	border: 1px solid #980000;
}
.status-sent {
	width: 12px;
	height: 12px;
	border-radius: 2px;
	background-color: #d5d6d7;
	border: 1px solid #687887;
}
.solve-status {
	width: 20px;
	height: 20px;
	border-radius: 4px;
	border: 2px solid #ea4e2c;
}

.exist-to-valid-ask {
	width: 50px;
	height: 20px;
	border: 0.3rem dashed #025755;
	animation: dash 4s linear infinite;
}

@keyframes dash {
	0% {
		border-color: #025755;
		border-right-color: transparent;
	}
	25% {
		border-bottom-color: transparent;
	}
	50% {
		border-left-color: transparent;
	}
	75% {
		border-top-color: transparent;
	}
	100% {
		border-color: #025755;
	}
}

.gp-5 {
	gap: 5px;
}
.gp-20 {
	gap: 20px;
}
.bg-tertiaire {
	background-color: #126782ad;
}
.icon-container {
	width: 20px;
	height: 20px;
}

.checkmark {
	width: 100%;
	height: 100%;
	stroke: #5cb85c;
}

.checkmark-circle {
	stroke: #5cb85c;
	stroke-dasharray: 166;
	stroke-dashoffset: 166;
	stroke-width: 2;
	animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark-check {
	stroke-dasharray: 48;
	stroke-dashoffset: 48;
	animation: stroke 0.4s cubic-bezier(0.65, 0, 0.45, 1) 0.6s forwards;
}

@keyframes stroke {
	to {
		stroke-dashoffset: 0;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translateY(20px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes fadeInText {
	to {
		opacity: 1;
	}
}
</style>
