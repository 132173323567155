<template>
	<div>
		<div class="w-100">
			<template v-if="isActiveHeaderGlobalOption">
				<div v-if="isMountedHeaderLocation" class="scroll-container" id="headerBarOption" @scroll="handleHeaderScroll">
					<div
						id="row-header-hide-row-position-fixed-scroll"
						class="scroll-item-sticky"
						:style="{ left: '0 !important', width: `${accessChildrenRowWidthSet()}px` }"
					></div>
					<div
						id="row-header-hide-row-position-scroll-start-scroll"
						class="scroll-item-start"
						:style="{ width: `${accessChildrenRowWidthSetByLocation(0)}px` }"
					>
						<button
							:id="`popover-button-event-when-start-active`"
							v-if="checkNexDateWhenStartActive()"
							@click.prevent.stop="handleHeaderOptionWhenStartActive"
						>
							+
						</button>
						<b-tooltip
							v-if="checkNexDateWhenStartActive()"
							:target="`popover-button-event-when-start-active`"
							:ref="`tootlip-button-event-when-start-active`"
							placement="right"
						>
							{{ FormMSG(1, 'There are mask columns between these two dates') }}
						</b-tooltip>
						<b-popover
							v-if="checkNexDateWhenStartActive()"
							custom-class="popover-header-option"
							:ref="`popover-button-event-when-start-active`"
							:target="`popover-button-event-when-start-active`"
							:id="`popover-item-when-start-active`"
							placement="top"
						>
							<list-hidden-column
								:ref="`list-hidden-column-when-start-active`"
								@popover:close-all="handleCloseOption"
								@type:close="handleCloseOption"
								@type:submitted="handleSubmitted"
							/>
						</b-popover>
					</div>
					<div
						v-for="(item, _index) of mapFields"
						:key="generateSecureId(`option-${item.key}`)"
						:id="`row-header-hide-row-position-scroll-${_index}`"
						:class="{
							'scroll-item': true,
							'margin-before-last-header-active': _index === mapFields.length - 2,
							'margin-last-header-active': _index === mapFields.length - 1
						}"
						:style="{ width: `${accessChildrenRowWidthSetByLocation(_index + 1)}px` }"
					>
						<button :id="`popover-button-event-${item.securedId}`" v-if="checkNexDate(item, _index)" @click.prevent.stop="handleHeaderOption(item)">
							+
						</button>
						<b-tooltip
							v-if="checkNexDate(item, _index)"
							:target="`popover-button-event-${item.securedId}`"
							:ref="`tootlip-button-event-${item.securedId}`"
							placement="right"
						>
							{{ FormMSG(1, 'There are mask columns between these two dates') }}
						</b-tooltip>
						<b-popover
							v-if="checkNexDate(item, _index)"
							custom-class="popover-header-option"
							:ref="`popover-button-event`"
							:target="`popover-button-event-${item.securedId}`"
							:id="`popover-item-${item.key}`"
							placement="top"
						>
							<list-hidden-column
								:ref="`list-hidden-column`"
								:keyOption="_index"
								@popover:close-all="handleCloseOption"
								@type:close="handleCloseOption"
								@type:submitted="handleSubmitted"
							/>
						</b-popover>
					</div>
					<div class="scroll-item-sticky" :style="{ right: '0 !important' }" id="option-fixed-end">
						<button v-if="isActiveHeaderGlobalOption" :id="`popover-button-event-global-option`" @click.prevent.stop="handleHeaderGlobalOption">
							<component :is="getLucideIcon(ICONS.LIST_TODO.name)" :color="ICONS.LIST_TODO.color" :size="20" />
						</button>
						<b-tooltip
							v-if="isActiveHeaderGlobalOption"
							:target="`popover-button-event-global-option`"
							:ref="`tootlip-button-event-global-option`"
							placement="right"
						>
							{{ FormMSG(400, 'Configuration of all hidden columns') }}
						</b-tooltip>
						<b-popover
							v-if="isActiveHeaderGlobalOption"
							custom-class="popover-header-option"
							:ref="`popover-button-event-global-option`"
							:target="`popover-button-event-global-option`"
							:id="`popover-item-global-option`"
							placement="top"
						>
							<list-hidden-column
								:ref="`list-hidden-column-global-option`"
								@popover:close-all="handleCloseOption"
								@type:close="handleCloseOption"
								@type:submitted="handleSubmitted"
							/>
						</b-popover>
					</div>
				</div>
			</template>
			<template>
				<b-table-simple
					v-if="tsfields.length !== 0"
					small
					caption-top
					responsive
					:sticky-header="`${getHeaderHeight}px`"
					:style="{ 'min-height': minHeight + 'px' }"
					sticky-column="first"
					ref="my-table"
					v-click-outside="onOutsideClick"
				>
					<b-thead>
						<b-tr id="headBar">
							<b-th
								class="text-center middle row-header-fixed resizable"
								:style="{ left: 0 }"
								@mouseover="handleStopDragging"
								id="headRefLeft"
								v-column-resize-right
							>
								<header-row-tables
									:isFixedStart="true"
									:isShowSomme="false"
									:initCustomFilter="initCustomFilter"
									:customFilterValue="customFilterValue"
									:handleAddNewUser="handleAddNewUser"
									@filter-handle-user="handleFilterUser"
									@handle-reset-init-custom-filter="handleResetInitCustomFilter"
									:widthCell="widthCell"
									:sizeCell="sizeCell"
									:isFixedColumn="true"
									:isProduction="isProduction"
								/>
							</b-th>
							<b-th
								v-for="(item, _index) of mapFields"
								:key="generateSecureId(`${item.key}`)"
								:id="`headRefLocation-${_index}`"
								:class="{
									'text-center middle row-header-locations resizable': true,
									'bg-header-today': isToday(item.key),
									'default-location': true
								}"
								@mouseover="handleStopDragging"
								v-b-popover.hover.bottom.html="isToday(item.key) && returnHeaderRow()"
								v-column-hover
								v-column-resize
							>
								<header-row-tables
									:ref="`row-header-${_index}`"
									:item="item"
									:index="_index"
									:isFixedStart="false"
									:isShowSomme="false"
									:onActiveHideColumn="(item) => hideColumn(item, _index)"
									:onOpenFlagHeader="(item) => handleOpenFlagHeader(item)"
									:widthCell="widthCell"
									:sizeCell="sizeCell"
									:isFixedColumn="false"
									:isProduction="isProduction"
								/>
							</b-th>
							<b-th class="text-center middle row-header-fixed resizable" :style="{ right: 0 }" id="headRefRight" v-column-resize-left>
								<header-row-tables
									:isShowSomme="true"
									:onHandleShowContentVerified="handleShowContentVerified"
									:showVerified="showVerified"
									:widthCell="widthCell"
									:sizeCell="sizeCell"
									:isFixedColumn="true"
									:isProduction="isProduction"
								/>
							</b-th>
						</b-tr>
					</b-thead>
					<b-tbody ref="tableRef" @mousedown="startScrollingLeft" @mouseup="stopScrollingLeft">
						<b-tr v-if="mapPrestations.length === 0">
							<b-td :colspan="tsfields.length === 0 ? 2 : tsfields.length + 1" class="text-center border-left init-background default-data-empty">
								<div>
									<div class="text-muted fs-14 w-100">{{ this.FormMSG(2, 'No data available') }}</div>
								</div>
							</b-td>
						</b-tr>
						<b-tr style="background: #f3f4f5" v-for="(item, rowIndex) of mapPrestations" :key="rowIndex">
							<b-td class="sticky-column-body-left default-data-no-empty" v-row-hover>
								<cell-fixed-tables-member
									:item="item"
									:isCheckValidatedAll="checkValidatedAll(item)"
									:isHaveSolved="haveSolved(item)"
									:isProduction="isProduction"
									:isCheckNotSubmitted="checkNotSubmitted(item)"
									:onValidateAllItem="(data) => validateAllTsDays(data, rowIndex)"
									:onSendAllForValidation="(data) => sendAllForValidation(data, rowIndex)"
								/>
							</b-td>
							<b-td
								v-for="(data, columnIndex) of mapFields"
								:key="secureIdsForItem(data.key, item)"
								:class="getCellClass(data.key, item.tsDays)"
								@contextmenu.stop.prevent="onActiveContextMenu($event, data, item, rowIndex, columnIndex)"
								@dblclick.stop.prevent="(e) => handleDoubleClick(e, item, data.key, data, rowIndex, columnIndex)"
								@click.stop.prevent="(e) => handleAllActionPlanning(e, item, data.key, data, rowIndex, columnIndex)"
								@mousedown="(e) => startDrag(e)"
								@mouseover="(e) => selectCell(e, item, data.key, data, rowIndex, columnIndex)"
								@mouseup="(e) => endDrag(e)"
								:id="secureIdsForItem(data.key, item)"
							>
								<cell-info
									:ref="`cell-info-${columnIndex}`"
									:id="`item-cell-info-${columnIndex}`"
									:isRefCellInfo="`cell-info`"
									:generateElementHeaderRow="generateElementHeaderRow(columnIndex)"
									:isCheckCell="checkCell(data.key, item.tsDays)"
									:labelStatus="getLabelStatus(getStatus(data.key, item.tsDays))"
									:labelStatusColor="getStatusColor(getStatus(data.key, item.tsDays))"
									:isPerDiem="isPerDiem"
									:isAnalyTicalCode="isAnalyTicalCode"
									:isPrestations="isPrestations"
									:currentValidationLevel="currentValidationLevel"
									:isProduction="isProduction"
									:fimalacCellItem="fimalacCellItem(data.key, item.tsDays)"
									:isShowBarStatus="isShowBarStatus(data.key, item.tsDays)"
									:keyCell="data.key"
									:arrayTsDays="item.tsDays"
									:widthCell="widthCell"
									:sizeCell="sizeCell"
									:indexField="columnIndex"
									:uniqueID="secureIdsForItem(data.key, item)"
									:setTextInfoBullWorkFlow="(text) => setTextInfoBullWorkFlow(text)"
									:isGestionnaryOfPaie="isGestionnaryOfPaie"
									@on-refresh-planning="onRefreshParent"
								/>
							</b-td>
							<b-td v-if="showVerified" id="bodyRight" class="sticky-column-body-right default-body-right">
								<cell-fixed-tables-paie
									:item="item"
									:isProduction="isProduction"
									:isGrouping="false"
									:widthCell="widthCell"
									:sizeCell="sizeCell"
								/>
							</b-td>
							<b-td
								v-if="showVerified === false"
								id="bodyRight"
								class="sticky-column-body-right-init default-row-some"
								@click.stop.prevent="handleShowContentVerified"
							>
								<div class="d-flex flex-row justify-content-center align-items-center" style="width: 27px"></div>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
				<div v-else class="d-flex flex-row justify-content-center align-items-center card-gray text-muted fs-14 w-100">
					{{ this.FormMSG(2, 'No data available') }}
				</div>
				<div v-if="tsfields.length !== 0 && isBusy" class="d-flex justify-content-center align-items-center" style="height: 20px; gap: 5px">
					<div class="loading">{{ this.FormMSG(9, 'Loading') }}</div>
					<div class="bars-5"></div>
				</div>
				<div
					v-else-if="tsfields.length !== 0"
					class="d-flex flex-row justify-content-center align-items-center"
					style="height: 20px; position: relative"
				>
					<div class="end-of-list">{{ sizeOfUsers() }}</div>
				</div>
				<div class="show-total">
					<div class="w-75"></div>
					<cell-fixed-tables-paie
						:totalGrouping="totalGrouping"
						:isProduction="isProduction"
						:isGrouping="true"
						:showVerified="showVerified"
						:widthCell="widthCell"
						:sizeCell="sizeCell"
					/>
				</div>
			</template>
			<template>
				<context-menu ref="ContextMenu" />
			</template>
		</div>
	</div>
</template>
<script>
import ListHiddenColumn from './ListHiddenColumn.vue';
import HeaderRowTables from './HeaderRowTables.vue';
import CellFixedTablesMember from './CellFixedTablesMember.vue';
import CellFixedTablesPaie from './CellFixedTablesPaie.vue';
import FlagItems from './FlagItems.vue';
import CellInfo from './CellInfo.vue';
import ContextMenu from './ContextMenu.vue';
import MonthSelect from '@/modules/fimalac/components/planning/MonthSelect';
import ShowDetailPlanningCellFimalac from '@/modules/fimalac/components/planning/ShowDetailPlanningCell';
import CustomTooltip from './CustomTooltip.vue';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import { getValidations } from '@/cruds/validations.crud';
import { ACTION_EFC_TIMESHEET } from '@/utils/utils';
import { rendCurrency } from '~helpers';
import { generateSecureId } from '@/shared/utils';
import { classStatus } from '@/shared/global-status';
import { addTsDaysUsersForPlanning } from '@/cruds/timesheet.crud';
import { addOrDeletePlanningInactiveDate, updateCompletionStatus } from '@/cruds/fimalac.crud';
import { store } from '@/store';
import * as _ from 'lodash';
import {
	FIMALAC_PROJECT_FLAG,
	CELL,
	VALIDATION_TYPE,
	PLANNING_LEVEL,
	STATUS_VALIDATE_PLANNING,
	COMPLETION_STATUS,
	TYPE_DELETED,
	formatDatesInString,
	OPTION_CONTEXT_MENU,
	TYPE_ASK_TO_DELETE,
	INTERNAL_STATUS
} from '@/shared/constants';
import { truncate } from 'fs';

export default {
	name: 'PlanningViewTablesFimalac',
	components: {
		MonthSelect,
		ShowDetailPlanningCellFimalac,
		FlagItems,
		ContextMenu,
		CellInfo,
		CustomTooltip,
		HeaderRowTables,
		CellFixedTablesMember,
		CellFixedTablesPaie,
		ListHiddenColumn
	},
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	props: {
		id: {
			type: String,
			required: false
		},
		previousPlanningStatus: {
			type: Number || null,
			required: false,
			default: null
		},
		lockPlanning: {
			type: Boolean,
			required: false,
			default: false
		},
		isManagerPresent: {
			type: Boolean,
			required: false,
			default: false
		},
		locationDatesSize: {
			type: Number,
			required: false,
			default: 0
		},
		isActiveBusy: {
			type: Boolean,
			required: false,
			default: false
		},
		statusPlanningValidateAll: {
			type: Number,
			required: false
		},
		setFilter: {
			type: Function,
			required: false
		},
		setPages: {
			type: Function,
			required: false
		},
		onSearch: {
			type: Function,
			required: false
		},
		selectDate: {
			type: Array,
			required: false
		},
		hover: {
			type: Boolean,
			required: false
		},
		timesheets: {
			type: Array,
			required: false
		},
		tsfields: {
			type: Array,
			required: false
		},
		sizeCell: {
			type: Number,
			required: false
		},
		currentpage: {
			type: Number,
			required: false
		},
		perpage: {
			type: Number,
			required: false
		},
		hv: {
			type: String,
			required: false
		},
		isPersistDataPlanning: {
			type: Boolean,
			required: false
		},
		isActionCrud: {
			type: String,
			required: false
		},
		initCustomFilter: {
			type: Boolean,
			required: false
		},
		hideContextMenuPlanning: {
			type: Boolean,
			required: false
		},
		initPlanningSelect: {
			type: Boolean,
			required: false
		},
		isAnalyTicalCode: {
			type: Boolean,
			required: false
		},
		isPrestations: {
			type: Boolean,
			required: false
		},
		isPerDiem: {
			type: Boolean,
			required: false
		},
		totalOfUsers: {
			type: Number,
			required: false
		},
		customFilterValue: {
			type: Number,
			required: false
		},
		totalGrouping: {
			type: Array,
			required: false,
			default: () => ({})
		},
		isGestionnaryOfPaie: {
			type: Boolean,
			required: false,
			default: () => false
		}
	},
	data: () => {
		const { addData, updateData, deleteData } = ACTION_EFC_TIMESHEET;
		return {
			filter: '',
			currentForm: {
				value: 0,
				text: 'Select Month'
			},
			addData,
			updateData,
			deleteData,
			setPage: 0,
			reset: true,
			notReset: false,
			isNoCtrolSelect: false,
			isKeyDate: '',
			dataForPlanning: [],
			generateSecureId,
			isBusy: false,
			showMenu: false,
			showMenuMultiple: false,
			menuX: 0,
			menuY: 0,
			selectedItem: null,
			MyFunctionHideContextMenu: false,
			scrollPosition: 0,
			isActiveClickCell: 1,
			sendDataToModalPlanning: [],
			isDragging: false,
			scrollLeftStart: 0,
			startX: 0,
			selectedCells: [],
			stateGlobalData: [],
			indexActif: 0,
			dateSelectedCells: [],
			stateGlobalDates: [],
			startIndex: 0,
			endIndex: 0,
			vuexStoreForPlanning: [],
			datePersist: [],
			arraysId: [],
			dataArraysStores: [],
			ctrlDataPersist: [],
			activeStart: false,
			dragging: false,
			classStatus,
			dataToValidate: [],
			dataToValidate: [],
			isValid: false,
			isReject: false,
			showDetailModalCell: false,
			rejectComment: '',
			showDetailData: {},
			rejectData: [],
			showVerified: false,
			arraysPlanningData: [],
			isSelectMultiple: false,
			copyItem: null,
			currentValidationLevel: 0,
			userValidationLevelData: [],
			minHeight: 300,
			isFixedAppBar: false,
			isResizeScreen: false,
			windowWidth: window.innerWidth,
			codePaie: 421,
			validationPlanningType: false,
			FIMALAC_PROJECT_FLAG,
			CELL,
			rendCurrency,
			textToCopy: '',
			pastedText: '',
			copied: false,
			isFixedAppBarValue: null,
			widthCell: CELL.WIDTH,
			mapSelectedCells: [],
			TYPE_SELECT_PLANNING: {
				CLICK: 'ONLY_CLICK',
				SHIFT: 'SHIFT_CLICK',
				CTRL: 'CTRL_CLICK'
			},
			TYPE_CONTEXT_MENU: {
				ONE_SELECT: 'one-select',
				MULTI_SELECT: 'multi-select'
			},
			popoversActiveList: [],
			isMountedHeaderLocation: false
		};
	},
	directives: {
		'column-resize': {
			async bind(el, _, vnode) {
				if (el) {
					let startX, startWidth;
					let element = el.children[0];
					const elementTh = vnode.elm;
					const { sizeCell: defaultCellWidth } = vnode.context.$options.propsData;
					const setOptionSizeDefault = () => {
						const { width } = elementTh.getBoundingClientRect();
						const indexTh = elementTh.cellIndex;
						const headerOption = document.getElementById('headerBarOption');
						if (headerOption) {
							const optionTh = headerOption.children;
							const elementOption = optionTh[indexTh];
							elementOption.style.setProperty('width', `${width}px`, 'important');
						}
					};
					new Promise((resolve, _) => {
						resolve(elementTh);
					}).then((res) => {
						if (res) {
							setOptionSizeDefault();
						}
					});
					const onMouseMove = (e) => {
						const cellIndex = +elementTh.cellIndex;
						const itemCellInfos = document.querySelectorAll(`#item-cell-info-${cellIndex - 1}`);
						let resizeWidth = 0;
						const newWidth = startWidth + e.clientX - startX;
						const maxWidth = defaultCellWidth + 300;
						if (newWidth > defaultCellWidth && newWidth <= maxWidth) {
							resizeWidth = newWidth;
							el.style.width = `${resizeWidth + 4}px`;
							element.style.width = `${resizeWidth}px`;
							setOptionSizeDefault();
							element.style.setProperty('cursor', 'w-resize', 'important');
							itemCellInfos.forEach((itemCellInfo) => itemCellInfo.style.setProperty('width', `${resizeWidth}px`, 'important'));
							vnode.context.elementHeaderRow(cellIndex, resizeWidth);
						}
					};

					const onMouseUp = () => {
						document.removeEventListener('mousemove', onMouseMove);
						document.removeEventListener('mouseup', onMouseUp);
						element.style.setProperty('cursor', 'pointer', 'important');
					};
					const onMouseDown = (e) => {
						startX = e.clientX;
						startWidth = parseInt(document.defaultView.getComputedStyle(el).width, 10);
						document.addEventListener('mousemove', onMouseMove);
						document.addEventListener('mouseup', onMouseUp);
					};
					el.addEventListener('mousedown', onMouseDown);
					el._onMouseMove = onMouseMove;
					el._onMouseUp = onMouseUp;
					el._onMouseDown = onMouseDown;
				}
			},
			unbind(el) {
				el.removeEventListener('mousemove', el._onMouseMove);
				el.removeEventListener('mouseup', el._onMouseUp);
				el.removeEventListener('mousedown', el._onMouseDown);
				delete el._onMouseMove;
				delete el._onMouseUp;
				delete el._onMouseDown;
			}
		},
		'column-resize-left': {
			async bind(el, _, vnode) {
				if (el) {
					let startX, startWidth;
					let element = el.children[0];
					const elementTh = vnode.elm;
					const setOptionSizeDefault = () => {
						const { width } = elementTh.getBoundingClientRect();
						const indexTh = elementTh.cellIndex;
						const headerOption = document.getElementById('headerBarOption');
						if (headerOption) {
							const optionTh = headerOption.children;
							const elementOption = optionTh[indexTh];
							elementOption.style.setProperty('width', `${width}px`, 'important');
						}
					};
					new Promise((resolve, _) => {
						resolve(elementTh);
					}).then((res) => {
						if (res) {
							setOptionSizeDefault();
						}
					});
					const onMouseMove = (e) => {
						if (vnode.context.showVerified) {
							const dx = e.clientX - startX;
							let resizeWidth = 0;
							const newWidth = startWidth - dx;
							let elementGrouping = document.getElementById('grouping-paie');
							const cellSize = CELL.WIDTH + 38;
							const maxWidth = cellSize * 2;
							if (newWidth >= cellSize && newWidth <= maxWidth) {
								resizeWidth = newWidth;
								element.style.setProperty('width', `${resizeWidth}px`, 'important');
								element.style.setProperty('cursor', 'w-resize', 'important');
								elementGrouping.style.setProperty('width', `${resizeWidth + 50}px`, 'important');
								setOptionSizeDefault();
							}
						}
					};

					const onMouseUp = () => {
						document.removeEventListener('mousemove', onMouseMove);
						document.removeEventListener('mouseup', onMouseUp);
						element.style.setProperty('cursor', 'pointer', 'important');
					};
					const onMouseDown = (e) => {
						startX = e.clientX;
						startWidth = el.offsetWidth;
						document.addEventListener('mousemove', onMouseMove);
						document.addEventListener('mouseup', onMouseUp);
					};
					el.addEventListener('mousedown', onMouseDown);
					el._onMouseMove = onMouseMove;
					el._onMouseUp = onMouseUp;
					el._onMouseDown = onMouseDown;
				}
			},
			unbind(el) {
				el.removeEventListener('mousemove', el._onMouseMove);
				el.removeEventListener('mouseup', el._onMouseUp);
				el.removeEventListener('mousedown', el._onMouseDown);
				delete el._onMouseMove;
				delete el._onMouseUp;
				delete el._onMouseDown;
			}
		},
		'column-resize-right': {
			async bind(el, _, vnode) {
				if (el) {
					let startX, startWidth;
					let element = el.children[0];
					const elementTh = vnode.elm;
					const { sizeCell: defaultCellWidth } = vnode.context.$options.propsData;
					const setOptionSizeDefault = () => {
						const { width } = elementTh.getBoundingClientRect();
						const indexTh = elementTh.cellIndex;
						const headerOption = document.getElementById('headerBarOption');
						if (headerOption) {
							const optionTh = headerOption.children;
							const elementOption = optionTh[indexTh];
							elementOption.style.setProperty('width', `${width}px`, 'important');
						}
					};
					new Promise((resolve, _) => {
						resolve(elementTh);
					}).then((res) => {
						if (res) {
							setOptionSizeDefault();
						}
					});
					const onMouseMove = (e) => {
						const dx = e.clientX - startX;
						let resizeWidth = 0;
						const newWidth = startWidth + dx;
						const maxWidth = defaultCellWidth * 2;
						const minWidth = defaultCellWidth + 50;
						if (newWidth >= minWidth && newWidth <= maxWidth) {
							resizeWidth = newWidth;
							element.style.setProperty('width', `${resizeWidth}px`, 'important');
							element.style.setProperty('cursor', 'w-resize', 'important');
							setOptionSizeDefault();
						}
					};

					const onMouseUp = () => {
						document.removeEventListener('mousemove', onMouseMove);
						document.removeEventListener('mouseup', onMouseUp);
						element.style.setProperty('cursor', 'pointer', 'important');
					};
					const onMouseDown = (e) => {
						startX = e.clientX;
						startWidth = el.offsetWidth;
						document.addEventListener('mousemove', onMouseMove);
						document.addEventListener('mouseup', onMouseUp);
					};
					el.addEventListener('mousedown', onMouseDown);
					el._onMouseMove = onMouseMove;
					el._onMouseUp = onMouseUp;
					el._onMouseDown = onMouseDown;
				}
			},
			unbind(el) {
				el.removeEventListener('mousemove', el._onMouseMove);
				el.removeEventListener('mouseup', el._onMouseUp);
				el.removeEventListener('mousedown', el._onMouseDown);
				delete el._onMouseMove;
				delete el._onMouseUp;
				delete el._onMouseDown;
			}
		},
		'column-hover': {
			bind(el, _, vnode) {
				if (el) {
					const onMouseMove = () => {
						const index = Array.prototype.indexOf.call(el.parentNode.children, el) + 1;
						const tdElements = vnode.elm.closest('table').querySelectorAll(`td:nth-child(${index})`);
						tdElements.forEach((td) => {
							const isNeedToEdit = [...td.classList].includes('is-need-to-edit');
							const isInitBackground = [...td.classList].includes('init-background');
							if (!isNeedToEdit) {
								if (!isInitBackground) {
									td.classList.add('hover-of-column');
								}
							}
						});
					};
					const onMouseLeave = () => {
						const index = Array.prototype.indexOf.call(el.parentNode.children, el) + 1;
						const tdElements = vnode.elm.closest('table').querySelectorAll(`td:nth-child(${index})`);
						tdElements.forEach((td) => td.classList.remove('hover-of-column'));
					};
					el.addEventListener('mousemove', onMouseMove);
					el.addEventListener('mouseleave', onMouseLeave);
					el._onMouseMove = onMouseMove;
					el._onMouseLeave = onMouseLeave;
				}
			},
			unbind(el) {
				el.removeEventListener('mouseover', el._onMouseMove);
				el.removeEventListener('mouseleave', el._onMouseLeave);
				delete el._onMouseMove;
				delete el._onMouseLeave;
			}
		},
		'row-hover': {
			bind(el, _, vnode) {
				const onStopSelected = () => {
					return (vnode.context.dragging = false);
				};
				const onMouseMove = () => {
					const trElements = el.parentNode.children;
					trElements.forEach((td, index) => {
						if (index !== 0) {
							const isNeedToEdit = [...td.classList].includes('is-need-to-edit');
							const isInitBackground = [...td.classList].includes('init-background');
							if (!isNeedToEdit) {
								if (!isInitBackground) {
									td.classList.add('hover-of-row-column');
								}
							}
						} else {
							td.classList.add('hover-of-row-column-fixed');
						}
					});
					onStopSelected();
				};
				const onMouseLeave = () => {
					const trElements = el.parentNode.children;
					trElements.forEach((td, index) => {
						if (index !== 0) {
							td.classList.remove('hover-of-row-column');
						} else {
							td.classList.remove('hover-of-row-column-fixed');
						}
					});
					onStopSelected();
				};
				el.addEventListener('mouseover', onMouseMove);
				el.addEventListener('mouseleave', onMouseLeave);
				el._onMouseMove = onMouseMove;
				el._onMouseLeave = onMouseLeave;
			},
			unbind(el) {
				el.removeEventListener('mouseover', el._onMouseMove);
				el.removeEventListener('mouseleave', el._onMouseLeave);
				delete el._onMouseMove;
				delete el._onMouseLeave;
			}
		}
	},
	watch: {
		isActiveBusy: {
			handler(newVal) {
				this.isBusy = newVal;
				return newVal;
			},
			immediate: true,
			deep: true
		},
		tsfields: {
			async handler(newVal) {
				if (newVal.length !== 0) {
					this.isMountedHeaderLocation = false;
					this.getValidationLevel();
					this.$nextTick(() => {
						const myTable = this.$refs['my-table'];
						if (myTable) {
							const tableScrollBody = myTable.$el;
							tableScrollBody.addEventListener('mousemove', this.detectDirection);
							tableScrollBody.addEventListener('mouseup', this.stopDrag);
							tableScrollBody.addEventListener('scroll', this.onScroll);
							tableScrollBody.addEventListener('scroll', (e) => this.handleContentScroll(e));
							this.isMountedHeaderLocation = true;
						}
					});
				}
				return newVal;
			},
			immediate: true,
			deep: true
		},
		timesheets: {
			async handler(newVal) {
				const { data } = this.generateCellSelected(this.selectedItem, null);
				await Promise.all(data).then((res) => {
					if (res) {
						this.activeCellsPlanningColor(res);
						return true;
					}
				});
				return newVal;
			},
			immediate: true,
			deep: true
		},
		initPlanningSelect: {
			handler(newVal) {
				if (newVal) {
					this.$emit('restore-init-planning-select');
				}
				return newVal;
			},
			immediate: true,
			deep: true
		},
		dataForPlanning: {
			handler(newVal) {
				const data = newVal;
				if (data.length > 1) {
					this.isSelectMultiple = true;
				} else {
					this.isSelectMultiple = false;
				}
				this.$emit('setDataSizeModalPlanning', data);
				return newVal;
			},
			immediate: true,
			deep: true
		},
		showMenu: {
			handler(newVal) {
				if (newVal === false) {
					this.menuX = 0;
					this.menuY = 0;
				}
				return newVal;
			},
			immediate: true,
			deep: true
		},
		showMenuMultiple: {
			handler(newVal) {
				if (newVal === false) {
					this.menuX = 0;
					this.menuY = 0;
				}
				return newVal;
			},
			immediate: true,
			deep: true
		},
		hideContextMenuPlanning: {
			handler(newVal) {
				if (newVal) {
					this.hideContextMenu();
					this.showMenu = false;
					this.showMenuMultiple = false;
					this.$refs['ContextMenu'].$refs['menu'].hide();
					this.$refs['ContextMenu'].optionContextMenu = null;
					this.$emit('hideContextMenuPlanningInit', false);
				}
				return newVal;
			},
			immediate: true,
			deep: true
		},
		isFixedAppBar: {
			handler(newVal) {
				if (newVal) {
					const sideBar = this.$refs['my-table'].$parent.$parent.$parent.$parent.$parent.$refs['side-bar'];
					if (sideBar) {
						sideBar.$el.addEventListener('click', (e) =>
							setTimeout(() => {
								this.handleResize(e);
							}, 250)
						);
					}
				}
				return newVal;
			},
			immediate: true,
			deep: true
		}
	},
	async created() {
		await this.getAccessValidationPlanning();
		this.getValidationLevel();
	},
	mounted() {
		let main = document.getElementById('app-body');
		window.addEventListener('resize', this.handleResize);
		main.addEventListener('scroll', this.handleScroll);
		let buttons = Array.from(document.body.querySelectorAll('button'))[1];
		buttons.addEventListener('click', this.resizeScreen);
		window.addEventListener('keydown', this.selectedWithKeyDown);
	},
	beforeDestroy() {
		if (this.$refs['my-table'] !== undefined) {
			const tableScrollBody = this.$refs['my-table'].$el;
			let main = document.getElementById('app-body');
			if (tableScrollBody) {
				tableScrollBody.removeEventListener('scroll', this.onScroll);
			}
			if (main) {
				main.removeEventListener('scroll', this.handleScroll);
			}
			window.removeEventListener('keydown', this.selectedWithKeyDown);
		}
	},
	computed: {
		mapFields() {
			return this.tsfields.slice(1);
		},
		isActiveHeaderGlobalOption() {
			const { active } = this.isHeaderGlobalOption();
			return active;
		},
		getHeaderHeight() {
			let WindowInnerHeight = window.innerHeight;
			let screenHeightPlanning = Math.round((WindowInnerHeight * 86.58) / 100);
			return screenHeightPlanning;
		},
		mapCustomFilter() {
			return [
				{ id: 0, label: this.FormMSG(370, 'Select filter'), isDisabled: true },
				{ id: 1, label: this.FormMSG(98, 'Filter by name') },
				{ id: 2, label: this.FormMSG(152, 'Filter by function') },
				{ id: 3, label: this.FormMSG(101, 'Custom filter') }
			];
		},
		setDataTableFields() {
			return this.dataTable;
		},
		mapPrestations() {
			if (this.$refs['my-table'] !== undefined) {
				const tableScrollBody = this.$refs['my-table'].$el;
				tableScrollBody.addEventListener('scroll', this.onScroll);
			}
			return this.timesheets;
		},
		isProduction() {
			return this.validationPlanningType;
		},
		isToValidate() {
			return false;
		}
	},
	methods: {
		accessChildrenRowWidthSet() {
			const columnRight = document.getElementById('headRefLeft');
			if (columnRight) {
				const { width } = columnRight.getBoundingClientRect();
				return width;
			}
			return this.widthCell + 50;
		},
		accessChildrenRowWidthSetByLocation(index) {
			const row = document.getElementById(`headRefLocation-${index}`);
			if (row) {
				const { width } = row.getBoundingClientRect();
				return width;
			}
			return this.sizeCell;
		},
		generateElementHeaderRow(index) {
			const elements = this.$refs[`row-header-${index}`];
			if (elements) {
				if (elements[0]) {
					if (elements[0].$el) {
						const { width } = elements[0].$el.getBoundingClientRect();
						return width;
					}
					return this.sizeCell;
				}
				return this.sizeCell;
			}
			return this.sizeCell;
		},
		getCellClass(key, tsDays) {
			const isNeedEdit = this.isNeedToEdit(key, tsDays);
			const isChecked = this.checkCell(key, tsDays);
			const isToday = this.isToday(key);
			return {
				'init-background': isChecked && !isNeedEdit,
				'is-need-to-edit': isChecked && isNeedEdit,
				'hover-not-empty': !isNeedEdit,
				'position-relative': isChecked,
				'edit-background hover-is-empty': !isChecked,
				'default-cell-content': true,
				'bg-row-today': isChecked || isNeedEdit ? false : isToday,
				[this.isTracing(key, tsDays)]: true
			};
		},
		onRefreshParent() {
			this.$emit('refersh-on-planning');
		},
		secureIdsForItem(key, item) {
			return this.generateSecureId(`${item.id + item.fullName + item.departmentName + item.functionName + key}`);
		},
		async selectedWithKeyDown(event) {
			if (event.ctrlKey && event.key === 'a') {
				this.inactiveCellsPlanningColor(this.dataForPlanning);
				this.dataForPlanning = [];
				this.mapSelectedCells = [];
				this.vuexStoreForPlanning = [];
				event.preventDefault();
				const sizeFields = this.mapFields.length;
				const sizePrestations = this.mapPrestations.length;
				const data = this.mapFields[0];
				const { key } = data;
				const item = this.mapPrestations[0];
				const CTRL_EVENT = this.handleClickOnly(event, item, key, data);
				return await new Promise((resolve, _) => resolve(CTRL_EVENT)).then((res) => {
					if (res) {
						const new_data = this.mapFields[sizeFields - 1];
						const { key: new_key } = new_data;
						const new_item = this.mapPrestations[sizePrestations - 1];
						this.handleShiftWithClick(event, new_item, new_key, new_data);
						return res;
					}
				});
			}
		},
		async handleSubmitted(props) {
			const { data } = props;
			const isHiddens = data.every((item) => item.checked === true);
			const formatDates = data.map((item) => formatDatesInString(item.date, 'YYYY-MM-DD') + 'T00:00:00Z');
			if (this.popoversActiveList && this.popoversActiveList.length !== 0) {
				this.popoversActiveList[0].popover.doClose();
				const contentListItem = this.$refs[`list-hidden-column`];
				if (contentListItem && contentListItem.length !== 0) {
					for (let i = 0; i < contentListItem.length; i++) {
						contentListItem[i].optionColumnConfig = null;
					}
				}
			}
			if (formatDates.length !== 0) {
				const result = await addOrDeletePlanningInactiveDate(formatDates, !isHiddens)
					.then((res) => {
						if (res) {
							this.$emit('hide-column-header', { keyColumn: null, isHiddens: !isHiddens, data });
							return true;
						}
						return false;
					})
					.catch((err) => {
						return false;
					});
				return result;
			}
			return true;
		},
		getDifferenceColumn(dateStart, dateEnd, isEnd) {
			let data = [];
			const dateSplitEndSelected = dateStart.split('/').slice(1).join('/');
			const start = +dateStart.toString().split('/')[0];
			const end = +dateEnd.toString().split('/')[0];
			if (isEnd) {
				const nombre = end - start;
				if (nombre !== 0) {
					for (let i = 1; i <= nombre; i++) {
						data.push({ id: Math.random(), date: `${i + start}`.padStart(2, '0') + '/' + dateSplitEndSelected, checked: false });
					}
				}
				return { data, nombre };
			} else {
				const nombre = end - start - 1;
				if (nombre !== 0) {
					for (let i = 1; i <= nombre; i++) {
						data.push({ id: Math.random(), date: `${i + start}`.padStart(2, '0') + '/' + dateSplitEndSelected, checked: false });
					}
				}
				return { data, nombre };
			}
		},
		handleCloseOption() {
			if (this.popoversActiveList.length !== 0) {
				const contentListItem = this.$refs[`list-hidden-column`];
				const contentListItemWhenStartActive = this.$refs[`list-hidden-column-when-start-active`];
				if (contentListItem && contentListItem.length !== 0) {
					for (let i = 0; i < contentListItem.length; i++) {
						contentListItem[i].optionColumnConfig = null;
					}
				}
				if (contentListItemWhenStartActive) {
					contentListItemWhenStartActive.optionColumnConfig = null;
				}
				this.popoversActiveList[0].popover.doClose();
			}
		},
		isHeaderGlobalOption() {
			const date = this.mapFields;
			let data = [];
			const { data: dataExistWhenStartHidde } = this.checkHeaderOptionWhenStartActive();
			if (dataExistWhenStartHidde && dataExistWhenStartHidde.length !== 0) {
				data.push(...dataExistWhenStartHidde);
			}
			if (date && date.length !== 0) {
				const dateSplitEndSelected = date[0].key.toString().split('/').slice(1).join('/');
				for (let i = 0; i < date.length; i++) {
					const startDate = date[i];
					const nextDate = date[i + 1];
					let keyStart = 0,
						keyNext = 0;
					if (startDate) {
						const { key: _keyStart } = startDate;
						keyStart = +_keyStart.split('/')[0];
					}
					if (nextDate) {
						const { key: _keyNext } = nextDate;
						keyNext = +_keyNext.split('/')[0];
					} else {
						keyNext = this.locationDatesSize + 1;
					}
					const stay = keyNext - keyStart;
					if (stay > 1) {
						for (let j = 0; j < stay - 1; j++) {
							const day = keyStart + j + 1;
							data.push({ id: Math.random(), date: `${day}`.padStart(2, '0') + '/' + dateSplitEndSelected, checked: false });
						}
					}
				}
			}
			const isActive = data && data.length !== 0 ? true : false;
			return {
				data,
				active: isActive
			};
		},
		async handleHeaderGlobalOption() {
			if (this.popoversActiveList && this.popoversActiveList.length !== 0) {
				this.popoversActiveList[0].popover.doClose();
				const contentListItem = this.$refs[`list-hidden-column`];
				if (contentListItem && contentListItem.length !== 0) {
					for (let i = 0; i < contentListItem.length; i++) {
						contentListItem[i].optionColumnConfig = null;
					}
				}
			}
			const tooltipActive = this.$refs[`tootlip-button-event-global-option`];
			if (tooltipActive) {
				tooltipActive.doClose();
			}
			const { data } = this.isHeaderGlobalOption();
			const popoverElement = this.$refs[`popover-button-event-global-option`];
			popoverElement.doOpen();
			await new Promise((resolve, _) => {
				resolve(popoverElement.localShow);
			}).then((res) => {
				if (res) {
					const contentListItem = this.$refs[`list-hidden-column-global-option`];
					if (contentListItem) {
						contentListItem.optionColumnConfig = { data };
					}
					if (tooltipActive) {
						tooltipActive.doClose();
					}
					this.popoversActiveList = [{ popover: popoverElement }];
				}
			});
		},
		async handleHeaderOption(_item) {
			const popoverElementWhenActiveStart = this.$refs[`popover-button-event-when-start-active`];
			const listWhenActiveStart = this.$refs[`list-hidden-column-event-when-start-active`];
			const { securedId: popoverSecuredId } = _item;
			const index = this.mapFields.findIndex((item) => item.securedId === popoverSecuredId);
			if (popoverElementWhenActiveStart) {
				popoverElementWhenActiveStart.doClose();
			}
			if (listWhenActiveStart) {
				listWhenActiveStart.optionColumnConfig = null;
			}
			const tooltipActive = this.$refs[`tootlip-button-event-${popoverSecuredId}`];
			if (tooltipActive) {
				tooltipActive[0].doClose();
			}
			if (this.popoversActiveList.length !== 0) {
				this.popoversActiveList[0].popover.doClose();
			}
			const nextIndex = index + 1;
			const { key: keyStart } = this.mapFields[index];
			const { key: keyNext } = this.mapFields[nextIndex] ? this.mapFields[nextIndex] : { key: null };
			let keyDefault = '';
			let isEnd = false;
			if (keyNext === null) {
				const stayDates = keyStart.toString().split('/').slice(1);
				const _keyNext = this.locationDatesSize.toString().padStart(2, '0') + '/' + stayDates.join('/');
				keyDefault = _keyNext;
				isEnd = true;
			}
			const { data } = this.getDifferenceColumn(keyStart, keyNext || keyDefault, isEnd);
			const keyPopover = `popover-item-${_item.key}`;
			const popoverElement = this.$refs[`popover-button-event`];
			const getPopoverList = (array, key) => {
				const activePopover = array.filter((item) => item.id === key);
				const otherPopover = array.filter((item) => item.id !== key);
				return { activePopover, otherPopover };
			};
			const { activePopover, otherPopover } = getPopoverList(popoverElement, keyPopover);
			otherPopover.forEach((pop) => pop.doClose());
			activePopover[0].doOpen();
			await new Promise((resolve, _) => {
				resolve(activePopover[0].localShow);
			}).then((res) => {
				if (res) {
					const contentListItem = this.$refs[`list-hidden-column`];
					if (contentListItem.length !== 0) {
						for (let i = 0; i < contentListItem.length; i++) {
							contentListItem[i].optionColumnConfig = { data };
						}
					}
					if (tooltipActive) {
						tooltipActive[0].doClose();
					}
					this.popoversActiveList = [{ popover: activePopover[0] }];
				}
			});
		},
		checkHeaderOptionWhenStartActive() {
			const startDate = 1;
			if (this.mapFields[0]) {
				const { key } = this.mapFields[0];
				const dateSplitEndSelected = key.toString().split('/').slice(1).join('/');
				const numberDay = +key.toString().split('/')[0];
				const stay = numberDay - startDate;
				let data = [];
				for (let i = 0; i < stay; i++) {
					data.push({ id: Math.random(), date: `${i + 1}`.padStart(2, '0') + '/' + dateSplitEndSelected, checked: false });
				}
				return { data };
			}
			return { data: [] };
		},
		async handleHeaderOptionWhenStartActive() {
			if (this.popoversActiveList && this.popoversActiveList.length !== 0) {
				this.popoversActiveList[0].popover.doClose();
				const contentListItem = this.$refs[`list-hidden-column`];
				if (contentListItem && contentListItem.length !== 0) {
					for (let i = 0; i < contentListItem.length; i++) {
						contentListItem[i].optionColumnConfig = null;
					}
				}
			}
			const tooltipActive = this.$refs[`tootlip-button-event-when-start-active`];
			if (tooltipActive) {
				tooltipActive.doClose();
			}
			const { data } = this.checkHeaderOptionWhenStartActive();
			const popoverElement = this.$refs[`popover-button-event-when-start-active`];
			popoverElement.doOpen();
			await new Promise((resolve, _) => {
				resolve(popoverElement.localShow);
			}).then((res) => {
				if (res) {
					const contentListItem = this.$refs[`list-hidden-column-when-start-active`];
					if (contentListItem) {
						contentListItem.optionColumnConfig = { data };
					}
					if (tooltipActive) {
						tooltipActive.doClose();
					}
					this.popoversActiveList = [{ popover: popoverElement }];
				}
			});
		},
		checkNexDateWhenStartActive() {
			const { data } = this.checkHeaderOptionWhenStartActive();
			if (data && data.length !== 0) {
				return true;
			} else {
				if (this.mapFields[0]) {
					const startDate = 1;
					const { key } = this.mapFields[0];
					const numberDay = +key.toString().split('/')[0];
					const stay = numberDay - startDate;
					if (stay !== 0) {
						return true;
					} else {
						return false;
					}
				}
				return false;
			}
		},
		checkNexDate(_, _index) {
			const { securedId: popoverSecuredId } = _;
			const index = this.mapFields.findIndex((item) => item.securedId === popoverSecuredId);
			const sizeFields = this.mapFields.length;
			const indexLastColumn = sizeFields - 1;
			if (indexLastColumn === index) {
				const { key } = this.mapFields[indexLastColumn];
				const numberDay = +key.toString().split('/')[0];
				const stay = this.locationDatesSize - numberDay;
				if (stay !== 0) {
					return true;
				}
				return false;
			} else {
				const nextIndex = index + 1;
				const { key: keyStart } = this.mapFields[index];
				const { key: keyNext } = this.mapFields[nextIndex];
				const { nombre } = this.getDifferenceColumn(keyStart, keyNext, false);
				if (nombre !== 0) {
					return true;
				}
				return false;
			}
		},
		returnHeaderRow() {
			return `<div class="row-today-popover">${this.FormMSG(378, "This is today's date column")}</div>`;
		},
		isToday(date) {
			const dateNow = new Date();
			const year = dateNow.getFullYear();
			const month = dateNow.getMonth() + 1;
			const day = dateNow.getDate();
			const toDay = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year.toString().padStart(2, '0')}`;
			return date === toDay;
		},
		hideColumn(item, index) {
			this.$bvModal
				.msgBoxConfirm('Please confirm that you want to hide this column', {
					title: 'Hide column',
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: 'Yes',
					cancelTitle: 'No',
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false,
					modalClass: 'mui-animation'
				})
				.then(async (v) => {
					if (!v) return false;
					else {
						this.$emit('on-show-is-loading', true);
						const _index = index + 1;
						const { key, isHidden } = item;
						const dates = `${formatDatesInString(key, 'YYYY-MM-DD')}T00:00:00Z`;
						const formatDate = [dates];
						const result = await addOrDeletePlanningInactiveDate(formatDate, !isHidden)
							.then((res) => {
								if (res) {
									this.mapSelectedCells = this.mapSelectedCells.filter((_item) => _item.date !== key);
									this.dataForPlanning = this.mapSelectedCells;
									this.activeCellsPlanningColor(this.dataForPlanning);
									this.$emit('hide-column-header', { keyColumn: _index, isHiddens: !isHidden, data: [item] });
									this.$emit('on-show-is-loading', false);
									return true;
								} else {
									this.$emit('on-show-is-loading', false);
									return false;
								}
							})
							.catch((_) => {
								this.$emit('on-show-is-loading', false);
								return false;
							});
						return result;
					}
				})
				.catch((err) => {
					this.$toast({
						message: err.message,
						title: 'Error',
						variant: 'danger'
					});
				});
		},
		handleResetInitCustomFilter(item) {
			const { type, value } = item;
			this.$emit('reset-init-custom-filter', type, value);
		},
		async getAccessValidationPlanning() {
			const userId = store.userID();
			let data = await getValidations(4);
			let result = data !== undefined ? data.find((item) => +item.user.id === +userId) : undefined;
			if (result !== undefined) {
				let type_status = result.validationLevelTs;
				this.validationPlanningType = !!type_status;
				return this.validationPlanningType;
			}
			return this.validationPlanningType;
		},
		sizeOfUsers() {
			const size = this.mapPrestations.length;
			if (size === this.totalOfUsers) {
				return (
					size.toString().padStart(2, '0') +
					' ' +
					this.FormMSG(3001, 'Of') +
					' ' +
					this.totalOfUsers.toString().padStart(2, '0') +
					' ' +
					this.FormMSG(3002, 'records') +
					' ' +
					this.FormMSG(3000, 'end of list')
				);
			}
			return (
				size.toString().padStart(2, '0') +
				' ' +
				this.FormMSG(3001, 'Of') +
				' ' +
				this.totalOfUsers.toString().padStart(2, '0') +
				' ' +
				this.FormMSG(3002, 'records') +
				' '
			);
		},
		async onOutsideClick() {
			this.showMenu = false;
			this.showMenuMultiple = false;
			this.$refs['ContextMenu'].$refs['menu'].hide();
			this.$refs['ContextMenu'].optionContextMenu = null;
			this.selectedItem = null;
			const { data } = this.generateCellSelected(this.selectedItem, null);
			await Promise.all(data).then((res) => {
				if (res) {
					this.activeCellsPlanningColor(res);
					return true;
				}
			});
		},
		getFuncStatus(item) {
			const newItem = this.filterTypeDataAuth(item);
			if (newItem.length !== 0) {
				let result = newItem[0].tsDay;
				return result.validated;
			}
			return 0;
		},
		isDeleting(raw) {
			const { data } = this.generateCellSelected(this.selectedItem, raw);
			const defaultData = this.filterTypeDataAuth(data);
			if (defaultData.length !== 0) {
				return true;
			}
			return false;
		},
		changeToBooleanStatusAskToDelete(status) {
			if (status && status !== -1) {
				return true;
			}
			return false;
		},
		isAskToDeleteSelected(item) {
			const { MANAGER, CHARG_PROD, ADM_PROD } = PLANNING_LEVEL;
			const { DEFAULT_ASK, FOR_MANAGER, FOR_LVL_ONE } = TYPE_ASK_TO_DELETE;
			const { DEFAULT_INTERNAL, SENT_TO_PAY } = INTERNAL_STATUS;
			const newItem = this.filterTypeDataAuth(item);
			const currentValidationLevel = this.currentValidationLevel;
			if (newItem.length !== 0 && currentValidationLevel !== ADM_PROD) {
				const actions = newItem.map(({ tsDay }) => {
					const { internalStatus, askToDeletes } = tsDay;
					if (internalStatus === DEFAULT_INTERNAL && askToDeletes === DEFAULT_ASK) {
						return {
							DELETE: true,
							UNDELETE: false,
							ASK_DELETE: false
						};
					} else if (internalStatus === DEFAULT_INTERNAL && askToDeletes === FOR_MANAGER) {
						if (currentValidationLevel === MANAGER) {
							return {
								DELETE: false,
								UNDELETE: true,
								ASK_DELETE: false
							};
						}
						if (currentValidationLevel === CHARG_PROD) {
							return {
								DELETE: false,
								UNDELETE: false,
								ASK_DELETE: true
							};
						}
						return {
							DELETE: true,
							UNDELETE: false,
							ASK_DELETE: false
						};
					} else if (internalStatus === SENT_TO_PAY && askToDeletes === FOR_MANAGER) {
						if (currentValidationLevel === MANAGER) {
							return {
								DELETE: false,
								UNDELETE: true,
								ASK_DELETE: false
							};
						}
						if (currentValidationLevel === CHARG_PROD) {
							return {
								DELETE: false,
								UNDELETE: false,
								ASK_DELETE: true
							};
						}
						return {
							DELETE: true,
							UNDELETE: false,
							ASK_DELETE: false
						};
					} else if (internalStatus === SENT_TO_PAY && askToDeletes === FOR_LVL_ONE) {
						if (currentValidationLevel === MANAGER) {
							return {
								DELETE: false,
								UNDELETE: false,
								ASK_DELETE: false
							};
						}
						return {
							DELETE: false,
							UNDELETE: true,
							ASK_DELETE: false
						};
					} else if (internalStatus === SENT_TO_PAY && askToDeletes === DEFAULT_ASK) {
						return {
							DELETE: false,
							UNDELETE: false,
							ASK_DELETE: true
						};
					} else {
						return {
							DELETE: false,
							UNDELETE: false,
							ASK_DELETE: false
						};
					}
				});
				const { DELETE, UNDELETE, ASK_DELETE } = actions[0];
				const isHaveContent = newItem.map(({ tsDay }) => tsDay).some(({ internalStatus }) => internalStatus === SENT_TO_PAY);
				const isAllInternalStatus = newItem.map(({ tsDay }) => tsDay).every(({ internalStatus }) => internalStatus === SENT_TO_PAY);
				return newItem.length === 1
					? {
							DELETE,
							UNDELETE,
							ASK_DELETE,
							isHaveContent: false
					  }
					: {
							DELETE: this.isGestionnaryOfPaie ? true : isAllInternalStatus ? !isAllInternalStatus : true,
							UNDELETE: false,
							ASK_DELETE: this.isGestionnaryOfPaie ? false : isAllInternalStatus ? isAllInternalStatus : false,
							isHaveContent: isAllInternalStatus ? false : isHaveContent
					  };
			}
			return {
				DELETE: false,
				UNDELETE: false,
				ASK_DELETE: false,
				isHaveContent: false
			};
		},
		handleAddNewUser() {
			this.$emit('on-open-modal-new-user', true);
		},
		handleFilterUser(value) {
			this.$emit('filter-custom-user', value);
		},
		async getValidationLevel() {
			const userId = store.userID();
			let data = await getValidations(4);
			let result = data !== undefined ? data.find((item) => +item.user.id === +userId) : undefined;
			if (result !== undefined) {
				this.currentValidationLevel = +result.validationLevelTs;
			} else {
				this.currentValidationLevel = 0;
			}
			this.$emit('setCurrentValidationLevel', this.currentValidationLevel);
		},
		handleOpenFlagHeader(item) {
			this.$emit('planning-view-tables:edit-location', item);
		},
		getStatus(key, arrays) {
			const { DEFAULT_INTERNAL } = INTERNAL_STATUS;
			let _data = arrays;
			if (_data !== undefined) {
				let result = _data.filter((item) => {
					const isValidCell = formatDatesInString(item.date, 'DD/MM/YYYY') === key;
					if (isValidCell) {
						return true;
					}
					return false;
				});
				if (result.length !== 0) {
					if (result[0].internalStatus === DEFAULT_INTERNAL) {
						return result[0].validated;
					}
					return this.codePaie;
				}
			}
		},
		getStatusColor(validated) {
			const { SUBMITTED, VALIDATED_LEVEL_1, REFUSED_LEVEL_1, VALIDATED_LEVEL_2, REFUSED_LEVEL_2 } = VALIDATION_TYPE;
			const statusMap = {
				[SUBMITTED]: 'info',
				[VALIDATED_LEVEL_1]: 'pending',
				[VALIDATED_LEVEL_2]: 'validated',
				[REFUSED_LEVEL_1]: 'refused',
				[REFUSED_LEVEL_2]: 'refused'
			};
			return statusMap[validated] || 'not-submitted';
		},
		setTextInfoBullWorkFlow(text) {
			if (!_.isNil(text)) {
				switch (text) {
					case 'Not found':
						return this.FormMSG(375, 'Not found');
					case 'Submitted':
						return this.FormMSG(376, 'Transmitted Manager');
					case 'Validated HOD':
						return this.FormMSG(377, 'Validated Charged / Dir. by Prod.');
					case 'Validated':
						return this.FormMSG(388, 'Validated Production Admin.');
					case 'Refused':
						return this.FormMSG(399, 'Refused');
					default:
						return text;
				}
			}
		},
		getLabelStatus(value) {
			if (value === this.codePaie) {
				return this.FormMSG(374, 'Validated payroll manager');
			} else {
				const text = this.GetTextFromMenuNumberAndMenuValue(1008, value);
				const response = this.setTextInfoBullWorkFlow(text);
				return response;
			}
		},

		async handleShowContentVerified() {
			this.showVerified = !this.showVerified;
			const { data } = this.generateCellSelected(this.selectedItem, null);
			if (!this.showVerified) {
				new Promise((resolve, _) => {
					resolve(!this.showVerified);
				}).then((res) => {
					if (res) {
						const { width } = document.getElementById('headRefRight').getBoundingClientRect();
						const optionFixedEnd = document.getElementById('option-fixed-end');
						if (optionFixedEnd) {
							optionFixedEnd.style.setProperty('width', `${width}px`);
						}
					}
				});
			}
			this.activeCellsPlanningColor(data);
		},
		getUniqueCombination(data) {
			let uniqueCombinations = new Set();
			let uniqueData = data.filter((item) => {
				let combination = `${item.date}-${item.userId}`;
				if (!uniqueCombinations.has(combination)) {
					uniqueCombinations.add(combination);
					return true;
				}
				return false;
			});
			return uniqueData;
		},
		isTracing(key, arrays) {
			if (this.checkCell(key, arrays)) {
				let result = this.fimalacCellItem(key, arrays);
				if (result) {
					const { MANAGER, LVL_ONE, LVL_TWO, PAYROLL } = COMPLETION_STATUS;
					const { completionStatus } = result;
					if (completionStatus === MANAGER) {
						return 'tracing-manager';
					}
					if (completionStatus === LVL_ONE) {
						return 'tracing-charge-production';
					}
					if (completionStatus === LVL_TWO) {
						return 'tracing-production-admin';
					}
					if (completionStatus === PAYROLL) {
						return 'tracing-charge-paied';
					}
					return '';
				}
				return '';
			}
			return '';
		},
		isNeedToEdit(key, arrays) {
			const { NOT_SUBMITTED, SUBMITTED, VALIDATED_LEVEL_1, REFUSED_LEVEL_1, REFUSED_LEVEL_2 } = VALIDATION_TYPE;
			if (this.checkCell(key, arrays)) {
				let result = this.fimalacCellItem(key, arrays);
				if (result) {
					if (this.isProduction) {
						if (this.currentValidationLevel === 1) {
							if (result.validated === SUBMITTED || result.validated === REFUSED_LEVEL_2) {
								return true;
							}
							return false;
						}
						if (this.currentValidationLevel === 2) {
							if (result.validated === VALIDATED_LEVEL_1) {
								return true;
							}
							return false;
						}
						return false;
					} else {
						if (result.validated === NOT_SUBMITTED || result.validated === REFUSED_LEVEL_1) {
							return true;
						} else {
							return false;
						}
					}
				}
				return false;
			}
			return false;
		},
		checkCellWhenSelected(raw) {
			const { data } = this.generateCellSelected(this.selectedItem, raw);
			const defaultData = this.filterTypeDataAuth(data);
			const { NOT_SUBMITTED } = VALIDATION_TYPE;
			let new_data = [...defaultData].filter((item) => {
				if (this.isProduction) {
					const isAuth = item.tsDay.validated !== NOT_SUBMITTED;
					if (isAuth) {
						return true;
					}
					return false;
				} else {
					return true;
				}
			});
			if (new_data.length !== 0) {
				return true;
			}
			return false;
		},
		checkCell(key, arrays) {
			let _data = arrays;
			const { NOT_SUBMITTED } = VALIDATION_TYPE;
			if (_data !== undefined) {
				let result = _data.filter((item) => {
					const isValidCell = formatDatesInString(item.date, 'DD/MM/YYYY') === key;
					if (isValidCell) {
						return true;
					}
					return false;
				});
				if (result.length !== 0) {
					if (result[0].tsDayDetails.length !== 0) {
						if (this.isProduction) {
							if (result[0].validated === NOT_SUBMITTED) {
								return false;
							}
							return true;
						} else {
							return true;
						}
					}
					return false;
				}
				return false;
			}
		},
		isValidationStatus(key, arrays) {
			let _data = arrays;
			const { NOT_SUBMITTED } = VALIDATION_TYPE;
			if (_data !== undefined) {
				let result = _data.filter((item) => {
					const isValidCell = formatDatesInString(item.date, 'DD/MM/YYYY') === key;
					if (isValidCell) {
						return true;
					}
					return false;
				});
				if (result.length !== 0) {
					let validation = result[0].validated;
					if (validation === NOT_SUBMITTED) {
						return true;
					}
					return false;
				}
				return true;
			}
		},
		isNeedToSolve(item) {
			const data = this.filterTypeDataAuth(item);
			const isNeedToUpdate = data
				.map(({ tsDay }) => tsDay.tsDayDetails)
				.flat(Infinity)
				.map((item) => {
					if (+item.activity.id === 0 || item.category === 0) {
						return true;
					}
					if (item.amount === 0) {
						return true;
					}
					return false;
				});
			return isNeedToUpdate.some((status) => status === true);
		},
		isAuthaurizeUpdate(item) {
			const { FOR_MANAGER, FOR_LVL_ONE } = TYPE_ASK_TO_DELETE;
			const data = this.filterTypeDataAuth(item);
			const isNeedToUpdate = data
				.map(({ tsDay }) => tsDay.tsDayDetails)
				.flat(Infinity)
				.map((item) => {
					if (+item.activity.id === 0 || item.category === 0) {
						return true;
					}
					if (item.amount === 0) {
						return true;
					}
					return false;
				});
			const isAskAll = data.map(({ tsDay }) => tsDay).some(({ askToDeletes }) => [FOR_MANAGER, FOR_LVL_ONE].includes(askToDeletes));
			const response = isNeedToUpdate.some((status) => status === true);
			return response ? response : !isAskAll;
		},
		isShowBarStatus(key, arrays) {
			let _data = arrays;
			if (_data !== undefined) {
				let result = _data.filter((item) => {
					const isValidCell = formatDatesInString(item.date, 'DD/MM/YYYY') === key;
					if (isValidCell) {
						return true;
					}
					return false;
				});
				if (result.length !== 0 && result[0] !== undefined) {
					let checkBarStatus = result[0].tsDayDetails.map((item) => {
						if (+item.activity.id === 0 || item.category === 0) {
							return true;
						}
						if (item.amount === 0) {
							return true;
						}
						return false;
					});
					let response = checkBarStatus.some((status) => status === true);
					if (response) {
						return true;
					}
					return false;
				}
				return true;
			}
		},
		fimalacCellItem(key, arrays) {
			// return objects or null
			let _data = arrays;
			if (_data !== undefined) {
				let result = _data.filter((item) => {
					const isValidCell = formatDatesInString(item.date, 'DD/MM/YYYY') === key;
					if (isValidCell) {
						return true;
					}
					return false;
				});
				if (result.length !== 0) {
					return result[0];
				}
				return null;
			}
			return null;
		},
		getDataOnCell(key, arrays) {
			let _data = arrays.tsDays;
			let item = undefined;
			if (_data !== undefined) {
				let result = _data.filter((item) => {
					const isValidCell = formatDatesInString(item.date, 'DD/MM/YYYY') === key;
					if (isValidCell) {
						return true;
					}
					return false;
				});
				if (result.length > 0) {
					item = result[0];
				}
				return { ...arrays, tsDay: item };
			}
		},
		activeCellsPlanningColor(data) {
			if (data !== undefined) {
				for (let i = 0; i < data.length; i++) {
					const checkSecuredIdElement = document.getElementById(data[i].securedId);
					if (checkSecuredIdElement) {
						const style = checkSecuredIdElement.style;
						document.getElementById(data[i].securedId).style;
						document.getElementById(data[i].securedId).style = style;
						document.getElementById(data[i].securedId).style.backgroundColor = '#00a09c';
						document.getElementById(data[i].securedId).style.color = '#fff';
						document.getElementById(data[i].securedId).style.border = '0.005rem solid rgba(226, 230, 235, 0.85)';
						document.getElementById(data[i].securedId).style.height = '50px';
					}
				}
			}
		},
		inactiveCellsPlanningColor(data) {
			if (data !== undefined) {
				for (let i = 0; i < data.length; i++) {
					const checkSecuredIdElement = document.getElementById(data[i].securedId);
					if (checkSecuredIdElement) {
						const style = checkSecuredIdElement.style;
						document.getElementById(data[i].securedId).style = style;
						document.getElementById(data[i].securedId).style.border = '0.005rem solid rgba(226, 230, 235, 0.85)';
						document.getElementById(data[i].securedId).style.height = '50px';
					}
				}
			}
		},
		startDrag(event) {
			if (event.button === 0) {
				this.dragging = true;
			}
		},
		selectCell(event, item, key, data, rowIndex, columnIndex) {
			if (event.button === 0) {
				if (this.dragging) {
					this.handleShiftWithClick(event, item, key, data, rowIndex, columnIndex);
				}
			}
		},
		handleStopDragging() {
			this.dragging = false;
		},
		endDrag(event) {
			if (event.button === 0) {
				this.dragging = false;
			}
		},
		getTsDayOneCell(item, key) {
			if (item !== undefined || item !== null) {
				if (!_.isNil(item.tsDays)) {
					let tsDay = item.tsDays.find((item) => formatDatesInString(item.date, 'DD/MM/YYYY') === key);
					return tsDay;
				}
				return null;
			}
		},
		generateSecureIdDefault(item, key) {
			const text = `${item.id + item.fullName + item.departmentName + item.functionName + key}`;
			return generateSecureId(text);
		},
		generateCellsItem(cell, raw) {
			const { securedId, item, locations, key, tsDay, isNoActiveChift } = cell;
			const isRaw = !!Object.values(raw).filter(Boolean).length;
			const generateRaw = isRaw ? raw : this.graphiquePlacement(key, item);
			const { rowIndex, columnIndex } = generateRaw;
			let elementElementIndex = -1;
			const indexOfItem = [...this.mapPrestations].findIndex(
				(userData) =>
					+userData.id === +item.id &&
					userData.fullName === item.fullName &&
					userData.functionName === item.functionName &&
					userData.departmentName === item.departmentName &&
					userData.date === item.date
			);
			if (indexOfItem !== -1) {
				const newItem = this.mapPrestations[indexOfItem];
				const { tsDays } = newItem;
				elementElementIndex = tsDays.findIndex(({ date }) => formatDatesInString(date, 'DD/MM/YYYY') === key);
			}
			return {
				securedId: securedId,
				userId: +item.id,
				fullName: item.fullName,
				functionName: item.functionName,
				managerSalaryAcess: item.managerSalaryAcess,
				date: key,
				indexOfItem: indexOfItem,
				dataAdd: item,
				locations: locations,
				tsDay: tsDay,
				isNoActiveChift: isNoActiveChift,
				rowIndex,
				columnIndex,
				elementElementIndex
			};
		},
		onRunDefaultAfterSelected() {
			this.mapSelectedCells = this.mapSelectedCells.map((item) => ({ ...item, isNoActiveChift: true }));
			return this.mapSelectedCells;
		},
		arrayDiffPlanning(rowItem, TYPE) {
			let arrayActive = [],
				arrayDisabled = [];
			const indexOfItem = this.mapSelectedCells.findIndex((item) => item.securedId === rowItem.securedId);
			if (TYPE === this.TYPE_SELECT_PLANNING.CLICK) {
				this.vuexStoreForPlanning = [];
				this.onRunDefaultAfterSelected();
				if (indexOfItem === -1) {
					const filterItem = this.mapSelectedCells.filter((item) => item.securedId !== rowItem.securedId);
					this.mapSelectedCells = [rowItem];
					arrayActive = this.mapSelectedCells;
					arrayDisabled = filterItem;
				} else {
					const typeActionPlanning = this.mapSelectedCells.splice(indexOfItem, 1)[0];
					if (!typeActionPlanning.isNoActiveChift) {
						const item = { ...typeActionPlanning, isNoActiveChift: true };
						arrayActive = [item];
						arrayDisabled = this.mapSelectedCells.filter((item) => item.securedId !== typeActionPlanning.securedId);
						this.mapSelectedCells = arrayActive;
					} else {
						if (this.mapSelectedCells.length !== 0) {
							const filterItem = this.mapSelectedCells.filter((item) => item.securedId !== rowItem.securedId);
							arrayDisabled = filterItem;
							arrayActive = [rowItem];
							this.mapSelectedCells = arrayActive;
						} else {
							arrayDisabled = [...this.mapSelectedCells, typeActionPlanning];
							arrayActive = this.mapSelectedCells;
							this.mapSelectedCells = arrayActive;
						}
					}
				}
				return { arrayActive, arrayDisabled };
			}
			if (TYPE === this.TYPE_SELECT_PLANNING.CTRL) {
				this.vuexStoreForPlanning = [];
				this.onRunDefaultAfterSelected();
				if (indexOfItem === -1) {
					arrayActive = [...this.mapSelectedCells, rowItem];
					this.mapSelectedCells = arrayActive;
				} else {
					arrayDisabled = this.mapSelectedCells.splice(indexOfItem, 1);
					arrayActive = this.mapSelectedCells;
					this.mapSelectedCells = arrayActive;
				}
				return { arrayActive, arrayDisabled };
			}
			if (TYPE === this.TYPE_SELECT_PLANNING.SHIFT) {
				if (this.mapSelectedCells.length === 0) {
					arrayActive = [rowItem];
					this.mapSelectedCells = arrayActive;
				} else {
					const mapLastSelectPlanning = this.mapSelectedCells.filter((item) => item.isNoActiveChift === true);
					const size = mapLastSelectPlanning.length - 1;
					const startItem = mapLastSelectPlanning[size];
					const endItem = rowItem;
					const mapBeforeActiveShiftItems = mapLastSelectPlanning.slice(0, size) || [];
					const dataPlanning = [startItem, endItem];
					const { arrayActiveShift, cellPreviousInactive } = this.getShiftPlanningActive(dataPlanning);
					const _mapSelectedCells = this.getUniqueCombination([...this.mapSelectedCells, ...arrayActiveShift]);
					const getDifference = (array1, array2) => {
						const ArrayToActive = array1.filter((obj1) => !array2.some((obj2) => obj1.securedId === obj2.securedId));
						const ArrayInActive = array2.filter((obj2) => array1.some((obj1) => obj2.securedId === obj1.securedId));
						return { ArrayToActive, ArrayInActive };
					};
					const { ArrayToActive, ArrayInActive } = getDifference(_mapSelectedCells, cellPreviousInactive);
					arrayActive = [...mapBeforeActiveShiftItems, ...ArrayToActive];
					this.mapSelectedCells = arrayActive;
					arrayDisabled = ArrayInActive;
				}

				return { arrayActive, arrayDisabled };
			}
		},
		graphiquePlacement(key, item) {
			const columnIndex = this.mapFields.findIndex((item) => item.key === key);
			const rowIndex = [...this.mapPrestations].findIndex(
				(userData) =>
					+userData.id === +item.id &&
					userData.fullName === item.fullName &&
					userData.functionName === item.functionName &&
					userData.departmentName === item.departmentName &&
					userData.date === item.date
			);
			return {
				rowIndex,
				columnIndex
			};
		},
		getShiftPlanningActive(data) {
			let shiftData = [];
			const uniques = data.filter((item) => !_.isNil(item)).map((item) => ({ ...item, date: item.date, locations: item.locations }));
			const rest = uniques[0].date.split('/').slice(1).join('/');
			const dateHeader = uniques
				.map((item) => {
					const startDate = item.date.toString().split('/')[0];
					return { ...item, startDate };
				})
				.map((item) => +item.startDate);
			let min_date = Math.min(...dateHeader);
			let max_date = Math.max(...dateHeader, 0);
			const ArrayOfIndex = data.filter((item) => !_.isNil(item)).map((item) => item.indexOfItem);
			const startOfIndex = Math.min(...ArrayOfIndex);
			const endOfIndex = Math.max(...ArrayOfIndex, 0);
			const start = startOfIndex;
			const end = endOfIndex + 1;
			const rows = [...this.mapPrestations].slice(start, end);
			const getLocation = (key, userId) => {
				const isLocations = uniques.find((unique) => unique.date === key && +unique.userId === +userId);
				if (!_.isNil(isLocations)) {
					return isLocations.locations;
				}
				return null;
			};
			for (let i = 0; i < rows.length; i++) {
				let item = rows[i];
				let j = 0;
				let startDate = min_date;
				const headerCulumn = this.mapFields;
				while (startDate <= max_date) {
					const key = startDate.toString().padStart(2, '0') + '/' + rest;
					const isExistInHeaderColumn = headerCulumn.findIndex((item) => item.key === key);
					if (isExistInHeaderColumn !== -1) {
						const locations = getLocation(key, item.id);
						const generateID = generateSecureId(`${item.id + item.fullName + item.departmentName + item.functionName + key}`);
						const tsDay = this.getTsDayOneCell(item, key);
						const securedId = generateID;
						const isNoActiveChift = false;
						const cell = { securedId, item, locations, key, tsDay, isNoActiveChift };
						const { rowIndex, columnIndex } = this.graphiquePlacement(key, item);
						const raw = {
							rowIndex,
							columnIndex
						};
						const rowItem = this.generateCellsItem(cell, raw);
						shiftData = [...shiftData, rowItem];
					}
					startDate++;
					j++;
				}
			}
			const size = this.vuexStoreForPlanning.length - 1;
			const cellPrevious = this.vuexStoreForPlanning[size] || [];
			this.vuexStoreForPlanning = [...this.vuexStoreForPlanning, shiftData];
			let arrayToClear = [];
			for (let z = 0; z < cellPrevious.length; z++) {
				const _item = shiftData.find((item) => item.securedId === cellPrevious[z].securedId);
				if (_.isNil(_item)) {
					arrayToClear.push(cellPrevious[z]);
				}
			}
			return { arrayActiveShift: shiftData, cellPreviousInactive: arrayToClear };
		},
		setDefaultSelectForPlanning(cell, type, raw) {
			const TYPE = type;
			const rowItem = this.generateCellsItem(cell, raw);
			const { arrayActive, arrayDisabled } = this.arrayDiffPlanning(rowItem, TYPE);
			this.dataForPlanning = this.getUniqueCombination(arrayActive);
			this.inactiveCellsPlanningColor(arrayDisabled);
			this.activeCellsPlanningColor(arrayActive);
		},
		handleCtrlClick(event, item, key, headerLocation, rowIndex, columnIndex) {
			const TYPE = this.TYPE_SELECT_PLANNING.CTRL;
			const securedId = this.generateSecureIdDefault(item, key);
			const locations = headerLocation.locations;
			const tsDay = this.getTsDayOneCell(item, key);
			const isNoActiveChift = true;
			const cell = { securedId, item, locations, key, tsDay, isNoActiveChift };
			const raw = {
				rowIndex,
				columnIndex
			};
			this.setDefaultSelectForPlanning(cell, TYPE, raw);
			return true;
		},
		handleClickOnly(event, item, key, headerLocation, rowIndex, columnIndex) {
			const TYPE = this.TYPE_SELECT_PLANNING.CLICK;
			const securedId = this.generateSecureIdDefault(item, key);
			const locations = headerLocation.locations;
			const tsDay = this.getTsDayOneCell(item, key);
			const isNoActiveChift = true;
			const cell = { securedId, item, locations, key, tsDay, isNoActiveChift };
			const raw = {
				rowIndex,
				columnIndex
			};
			this.setDefaultSelectForPlanning(cell, TYPE, raw);
			return true;
		},
		handleShiftWithClick(event, item, key, headerLocation, rowIndex, columnIndex) {
			const TYPE = this.TYPE_SELECT_PLANNING.SHIFT;
			const securedId = this.generateSecureIdDefault(item, key);
			const locations = headerLocation.locations;
			const tsDay = this.getTsDayOneCell(item, key);
			let isNoActiveChift = false;
			if (this.mapSelectedCells.length === 0) {
				isNoActiveChift = true;
			}
			const cell = { securedId, item, locations, key, tsDay, isNoActiveChift };
			const raw = {
				rowIndex,
				columnIndex
			};
			this.setDefaultSelectForPlanning(cell, TYPE, raw);
			return true;
		},
		async handleDoubleClick(event, item, key, headerLocation, rowIndex, columnIndex) {
			const { SOLVE_UPDATE, SEE_INFO } = OPTION_CONTEXT_MENU;
			const { DEFAULT_ASK } = TYPE_ASK_TO_DELETE;
			const isExist = this.checkCell(key, item.tsDays);
			const { ADM_PROD } = PLANNING_LEVEL;
			const isAdmin = this.currentValidationLevel === ADM_PROD;
			const raw = {
				rowIndex,
				columnIndex
			};
			if (isExist) {
				const { data } = this.generateCellSelected(this.selectedItem, raw);
				const defaultData = this.filterTypeDataAuth(data);
				const newItem = this.getDataOnCell(key, item);
				this.selectedItem = newItem;
				delete newItem.tsDays;
				const isAsk = newItem.tsDay ? newItem.tsDay.askToDeletes : DEFAULT_ASK;
				const statusCell = this.changeToBooleanStatusAskToDelete(isAsk);
				const { data: newData } = this.generateCellSelected(this.selectedItem, raw);
				const newDefaultData = this.filterTypeDataAuth(newData);
				const isAuthaurizeUpdate = !this.isAuthaurizeUpdate(newDefaultData);
				const sameItems = JSON.stringify(newDefaultData) === JSON.stringify(defaultData);
				let isActive = true;
				if (!sameItems) {
					isActive = this.handleClickOnly(event, item, key, headerLocation, rowIndex, columnIndex);
				}
				if (statusCell === false) {
					return new Promise((resolve, _) => resolve(isActive)).then((res) => {
						if (res) {
							if (newDefaultData.length === 1) {
								const action =
									this.lockPlanning || this.isGestionnaryOfPaie || isAdmin ? SEE_INFO : isAuthaurizeUpdate ? SEE_INFO : SOLVE_UPDATE;
								return this.onMenuItemClick(action, raw);
							}
						}
					});
				} else {
					return new Promise((resolve, _) => resolve(isActive)).then((res) => {
						if (res) {
							return this.onMenuItemClick(SEE_INFO, raw);
						}
					});
				}
			}
		},
		handleAllActionPlanning(event, item, key, headerLocation, rowIndex, columnIndex) {
			if (this.showMenu) {
				this.showMenu = false;
				this.$refs['ContextMenu'].$refs['menu'].hide();
				this.$refs['ContextMenu'].optionContextMenu = null;
			}
			if (this.showMenuMultiple) {
				this.showMenuMultiple = false;
				this.$refs['ContextMenu'].$refs['menu'].hide();
				this.$refs['ContextMenu'].optionContextMenu = null;
			}
			if (event.ctrlKey || event.metaKey) {
				this.handleCtrlClick(event, item, key, headerLocation, rowIndex, columnIndex);
			} else if (event.shiftKey) {
				this.handleShiftWithClick(event, item, key, headerLocation, rowIndex, columnIndex);
			} else {
				this.handleClickOnly(event, item, key, headerLocation, rowIndex, columnIndex);
			}
		},
		isElseWhere(item, newItem, raw) {
			const { data } = this.generateCellSelected(item, raw);
			const { key } = newItem;
			const newSecuredId = this.generateSecureIdDefault(item, key);
			const inCase = data.map(({ securedId }) => securedId).includes(newSecuredId);
			return {
				inCase
			};
		},
		async onActiveContextMenu(event, data, item, rowIndex, columnIndex) {
			this.hideContextMenu();
			const raw = { rowIndex, columnIndex };
			const { inCase } = this.isElseWhere(item, data, raw);
			const excuteAction = async () => {
				const numberSelectedPlanning = [...this.dataForPlanning].length; // unique data for planning selection even cell empty (don't clear)
				const isCheckCell = this.checkCell(data.key, item.tsDays);
				const isActive = isCheckCell && this.isSelectMultiple === false;
				const type = isActive
					? this.TYPE_CONTEXT_MENU.ONE_SELECT
					: numberSelectedPlanning > 1
					? this.TYPE_CONTEXT_MENU.MULTI_SELECT
					: this.TYPE_CONTEXT_MENU.ONE_SELECT;
				const worked = this.getDataOnCell(data.key, item);
				const result = await this.showContextMenu(type, event, worked, raw);
				return result;
			};
			if (inCase) {
				excuteAction();
			} else {
				const { key } = data;
				const response = this.handleClickOnly(event, item, key, data);
				return await new Promise((resolve, _) => resolve(response)).then((res) => {
					if (res) {
						excuteAction();
					}
				});
			}
		},
		filterItemCellAuthorizationObject(item) {
			const { NOT_SUBMITTED } = VALIDATION_TYPE;
			if (item) {
				const { validated } = item;
				return this.isProduction ? validated !== NOT_SUBMITTED : true;
			}
			return false;
		},
		isAuthorizedShowContext(item, raw) {
			let isBoolean = false;
			const { data: rowItem } = this.generateCellSelected(item, raw);
			const tsDays = rowItem
				.map((item) => {
					const arrays = Object.keys(item);
					const index = arrays.findIndex((item) => item === 'tsDay');
					if (index !== -1) {
						if (!_.isNil(item.tsDay)) {
							const { tsDay } = item;
							const unique = [...Object.keys(tsDay)];
							if (unique.length > 1) {
								return tsDay;
							} else {
								return null;
							}
						}
						return null;
					} else {
						return null;
					}
				})
				.filter((isTsDay) => isTsDay && this.filterItemCellAuthorizationObject(isTsDay));
			if (tsDays.length !== 0 || this.textToCopy.length !== 0) {
				isBoolean = true;
			}
			return {
				authorizedShow: isBoolean
			};
		},
		async showContextMenu(type, event, worked, raw) {
			this.showMenu = false;
			this.showMenuMultiple = false;
			const { authorizedShow } = this.isAuthorizedShowContext(worked, raw);
			if (authorizedShow) {
				const isContextMenu = this.$refs['ContextMenu'];
				isContextMenu.$refs['menu'].hide();
				isContextMenu.optionContextMenu = null;
				const response = await Promise.all([isContextMenu.optionContextMenu]).then((res) => {
					if (res) {
						const showMenu = type === this.TYPE_CONTEXT_MENU.ONE_SELECT ? 'showMenu' : 'showMenuMultiple';
						this[showMenu] = true;
						const optionContextMenu = (isContextMenu.optionContextMenu = this.setOptionContextMenu(worked, event, raw));
						if (!_.isNil(optionContextMenu)) {
							isContextMenu.$refs['menu'].show(event);
						}
						return true;
					}
					return false;
				});
				return response;
			}
		},
		setOptionContextMenu(worked, event, raw) {
			const { MANAGER, ADM_PROD } = PLANNING_LEVEL;
			const { FINAL, CLOSED } = STATUS_VALIDATE_PLANNING;
			const { REFUSED_LEVEL_1, REFUSED_LEVEL_2 } = VALIDATION_TYPE;
			const { FOR_MANAGER, FOR_LVL_ONE } = TYPE_ASK_TO_DELETE;
			const isLastActionG_PROD = [FINAL, CLOSED].includes(this.previousPlanningStatus);
			const currentValidationLevel = this.currentValidationLevel;
			const lockPlanning = this.lockPlanning;
			this.selectedItem = worked;
			const table = this.$refs['my-table'].$el;
			const tableRect = table.getBoundingClientRect();
			const { data: rowItem } = this.generateCellSelected(this.selectedItem, raw);
			if (this.dataForPlanning.length === 0 && this.selectedItem) {
				const { arrayActive, arrayDisabled } = this.arrayDiffPlanning(rowItem[0], this.TYPE_SELECT_PLANNING.CLICK);
				this.dataForPlanning = this.getUniqueCombination(arrayActive);
				this.inactiveCellsPlanningColor(arrayDisabled);
				this.activeCellsPlanningColor(arrayActive);
				this.mapSelectedCells = arrayActive;
			}
			const { isValid, isReject } = this.isValidated(rowItem);
			const defaultData = this.filterTypeDataAuth(rowItem);
			const isAuthaurizeUpdateWhenAsk =
				this.isGestionnaryOfPaie && this.showMenu
					? defaultData
							.map(({ tsDay }) => tsDay.tsDayDetails)
							.flat()
							.some(({ askToDelete }) => [FOR_LVL_ONE].includes(askToDelete))
					: false;
			const isAskAll = defaultData
				.map(({ tsDay }) => tsDay)
				.every(({ askToDeletes }) => {
					if (currentValidationLevel === MANAGER) {
						return [FOR_MANAGER, FOR_LVL_ONE].includes(askToDeletes);
					}
					return [FOR_LVL_ONE].includes(askToDeletes);
				});
			const condRefused = [REFUSED_LEVEL_1, REFUSED_LEVEL_2];
			const isAskExist = defaultData
				.map(({ tsDay }) => tsDay)
				.some(({ askToDeletes, validated: v }) => [FOR_MANAGER, FOR_LVL_ONE].includes(askToDeletes) || condRefused.includes(v));
			const statusOption = () => {
				if (this.isGestionnaryOfPaie) {
					return isAskAll;
				} else {
					return false;
				}
			};
			const isAuthTracing = () => {
				const { MANAGER: STATUS_MANAGER, LVL_ONE: STATUS_LVL_ONE, LVL_TWO: STATUS_LVL_TWO, PAYROLL: STATUS_PAYROLL } = COMPLETION_STATUS;
				const { MANAGER, CHARG_PROD, ADM_PROD, PAI } = PLANNING_LEVEL;
				const isAuthTrace = () => {
					const levelAuthorization = this.isGestionnaryOfPaie ? PAI : this.currentValidationLevel;
					const exc = (invert = false) =>
						defaultData.every(({ tsDay: { completionStatus } }) => {
							const levelStatus = {
								[MANAGER]: STATUS_MANAGER,
								[CHARG_PROD]: STATUS_LVL_ONE,
								[ADM_PROD]: STATUS_LVL_TWO,
								[PAI]: STATUS_PAYROLL
							}[levelAuthorization];

							return invert ? ![levelStatus].includes(completionStatus) : [levelStatus].includes(completionStatus);
						});

					const undoTrace = exc();
					const redoTrace = exc(true);

					return !redoTrace && !undoTrace ? { redoTrace: true, undoTrace: true } : { redoTrace, undoTrace };
				};
				return isAuthTrace();
			};
			const isAuthTraceItem = isAskExist ? { redoTrace: false, undoTrace: false } : isAuthTracing();
			let defaultCopyReplace = {
				isCopy: true,
				isReplace: true
			};
			let generateOption = {
				checkDeletePlanning: this.checkDeletePlanning(raw),
				isSolve: this.isNeedToSolve(rowItem) && this.isShowMenuItem(rowItem),
				isAskToDeleteSelected: this.isAskToDeleteSelected(rowItem),
				isValid: isValid,
				isReject: isReject,
				getFuncStatus: this.getFuncStatus(rowItem),
				isDeleting: this.isDeleting(raw),
				textToCopy: this.textToCopy,
				pastedText: this.pastedText,
				isAuthTracing: isAuthTraceItem,
				isAuthaurizeUpdate: this.isAuthaurizeUpdate(rowItem),
				defaultCopyReplace
			};

			for (let option in generateOption) {
				if (lockPlanning && !this.isGestionnaryOfPaie) {
					const typeItem = generateOption[option];
					if (typeof typeItem === 'string') {
						generateOption[option] = '';
					} else {
						generateOption[option] = false;
						generateOption['isAskToDeleteSelected'] = {
							DELETE: false,
							UNDELETE: false,
							ASK_DELETE: false
						};
						generateOption['isAuthTracing'] = {
							redoTrace: false,
							undoTrace: false
						};
						generateOption['defaultCopyReplace'] = {
							isCopy: false,
							isReplace: false
						};
					}
				} else {
					if (currentValidationLevel === ADM_PROD && !isLastActionG_PROD) {
						generateOption = {
							...generateOption,
							isDeleting: statusOption(),
							isValid: false,
							isReject: false,
							isAuthTracing: {
								redoTrace: false,
								undoTrace: false
							},
							isAuthaurizeUpdate: isAuthaurizeUpdateWhenAsk
						};
					} else {
						if (this.isGestionnaryOfPaie) {
							generateOption = {
								...generateOption,
								isDeleting: statusOption(),
								isValid: false,
								isReject: false,
								isAuthaurizeUpdate: isAuthaurizeUpdateWhenAsk,
								isAuthTracing: isAuthTraceItem
							};
						} else if (currentValidationLevel === ADM_PROD) {
							generateOption = {
								...generateOption,
								isAuthaurizeUpdate: false
							};
						}
					}
				}
			}

			return {
				isProduction: this.isProduction,
				showMenu: this.showMenu,
				showMenuMultiple: this.showMenuMultiple,
				checkCellWhenSelected: this.checkCellWhenSelected(raw),
				isExistCell: this.isExistCell(raw),
				checkIsSelectMultipleAndOnly: this.checkIsSelectMultipleAndOnly(raw),
				checkMultipleSelect: this.checkMultipleSelect(raw),
				onMenuItemClick: (type) => this.onMenuItemClick(type, raw),
				currentValidationLevel: this.currentValidationLevel,
				isAskToDeleteSelected: this.isAskToDeleteSelected(rowItem),
				hideContextMenuStopPropagation: () => this.hideContextMenuStopPropagation(),
				isGestionnaryOfPaie: this.isGestionnaryOfPaie,
				statusPlanningValidateAll: this.statusPlanningValidateAll,
				tableRect,
				event,
				widthCell: this.widthCell,
				isFixedAppBar: this.isFixedAppBar,
				rowItem,
				...generateOption
			};
		},
		cleanGlobalAction() {
			// active in action parent index.vue
			const { data } = this.generateCellSelected(this.selectedItem, null);
			this.inactiveCellsPlanningColor(data);
			this.selectedItem = null;
			this.dataForPlanning = [];
			this.mapSelectedCells = [];
			return true;
		},
		hideContextMenu() {
			this.showMenu = false;
			this.showMenuMultiple = false;
			this.selectedItem = null;
			this.$refs['ContextMenu'].$refs['menu'].hide();
			this.$refs['ContextMenu'].optionContextMenu = null;
		},
		hideContextMenuStopPropagation() {
			this.$refs['ContextMenu'].$refs['menu'].hide();
			this.$refs['ContextMenu'].optionContextMenu = null;
		},
		hideContextMenuScrolled() {
			if (this.showMenu) {
				this.showMenu = false;
				this.showMenuMultiple = false;
				this.selectedItem = null;
				this.dataForPlanning = [];
				this.$refs['ContextMenu'].$refs['menu'].hide();
				this.$refs['ContextMenu'].optionContextMenu = null;
			} else {
				if (this.dataForPlanning.length !== 0) {
					this.showMenu = false;
					this.showMenuMultiple = false;
					this.selectedItem = null;
					this.$refs['ContextMenu'].$refs['menu'].hide();
					this.$refs['ContextMenu'].optionContextMenu = null;
				}
			}
		},
		isValidated(item) {
			this.isValid = false;
			this.isReject = false;
			const { FOR_MANAGER, FOR_LVL_ONE } = TYPE_ASK_TO_DELETE;
			const { SENT_TO_PAY } = INTERNAL_STATUS;
			if (item) {
				const newItem = this.filterTypeDataAuth(item)
					.map(({ tsDay }) => tsDay)
					.filter(({ validated: v }) => ![NOT_SUBMITTED].includes(v));
				const { MANAGER, CHARG_PROD, ADM_PROD } = PLANNING_LEVEL;
				const { NOT_SUBMITTED, SUBMITTED, VALIDATED_LEVEL_1, REFUSED_LEVEL_1, VALIDATED_LEVEL_2 } = VALIDATION_TYPE;
				if (this.currentValidationLevel === MANAGER) {
					return {
						isValid: this.isValid,
						isReject: this.isReject
					};
				} else {
					const isNeedToSolve = this.isNeedToSolve(item);
					const isAuthorization = !newItem.some(({ askToDeletes: isAsk }) => [FOR_MANAGER, FOR_LVL_ONE].includes(isAsk));
					const itemToValid = newItem.filter(({ validated: v }) => {
						if (this.currentValidationLevel === CHARG_PROD) {
							return [SUBMITTED, REFUSED_LEVEL_1].includes(v);
						}
						return false;
					});
					const itemToReject = newItem.filter(({ validated: v, askToDeletes: isAsk, internalStatus }) => {
						if (this.currentValidationLevel === CHARG_PROD) {
							return this.isManagerPresent ? [SUBMITTED, VALIDATED_LEVEL_1].includes(v) && ![FOR_MANAGER, FOR_LVL_ONE].includes(isAsk) : false;
						}
						if (this.currentValidationLevel === ADM_PROD) {
							return [SUBMITTED, VALIDATED_LEVEL_1, VALIDATED_LEVEL_2].includes(v);
						}
						return false;
					});
					const isExistReject = newItem.map(({ internalStatus }) => [SENT_TO_PAY].includes(internalStatus)).some((status) => status);
					this.isValid = !isNeedToSolve && itemToValid.length !== 0;
					this.isReject = itemToReject.length !== 0;
					return {
						isValid: isAuthorization && this.isValid,
						isReject: isAuthorization && this.isReject
					};
				}
			}
			return {
				isValid: false,
				isReject: false
			};
		},
		checkMultipleSelect(raw) {
			const { data } = this.generateCellSelected(this.selectedItem, raw);
			if (data.length !== 0) {
				if (data.length === 1) {
					return false;
				}
				return true;
			}
			return false;
		},
		isExistCell(raw) {
			const { data } = this.generateCellSelected(this.selectedItem, raw);
			let isCellValid = data.map((item) => (item.tsDay ? true : false)).some((isExist) => isExist === true);
			if (isCellValid) {
				return true;
			}
			return false;
		},
		checkIsSelectMultipleAndOnly(raw) {
			const { MANAGER, CHARG_PROD, ADM_PROD } = PLANNING_LEVEL;
			const { NOT_SUBMITTED, SUBMITTED, VALIDATED_LEVEL_1, VALIDATED_LEVEL_2 } = VALIDATION_TYPE;
			const { data } = this.generateCellSelected(this.selectedItem, raw);
			let status = null;
			if (data.length !== 0) {
				if (data.length === 1) {
					if (this.isProduction === false && this.currentValidationLevel === MANAGER) {
						const { tsDay } = data[0];
						if (tsDay) {
							status = tsDay.validated;
							if (status) return true;
							return false;
						}
						return ![SUBMITTED, VALIDATED_LEVEL_1, VALIDATED_LEVEL_2].includes(status);
					}
					return true;
				} else {
					if (this.isProduction === false && this.currentValidationLevel === MANAGER) {
						let statusAll = data.map((item) => item.tsDay && item.tsDay.validated).some((status) => status === NOT_SUBMITTED);
						if (statusAll) {
							return true;
						}
						return false;
					}
					return true;
				}
			} else {
				if (this.selectedItem !== null) {
					status = this.selectedItem.tsDay.validated;
				}
				if ([SUBMITTED, VALIDATED_LEVEL_1, VALIDATED_LEVEL_2].includes(status)) {
					if (this.isProduction && this.currentValidationLevel !== MANAGER) {
						return ![CHARG_PROD, ADM_PROD].includes(this.currentValidationLevel);
					}
					return this.currentValidationLevel !== MANAGER;
				}
				return true;
			}
		},
		isShowMenuItem(item) {
			const newItem = this.filterTypeDataAuth(item);
			if (newItem !== null) {
				let data = newItem[0].tsDay.tsDayDetails;
				if (data !== undefined) {
					if (data.length !== 0) {
						return true;
					}
					return false;
				}
			}
		},
		isCheckTsDetails(item) {
			if (item !== undefined) {
				let data = item.map((item) => {
					if (+item.activity.id === 0 || item.category === 0) {
						return true;
					}
					if (item.amount === 0) {
						return true;
					}
					return false;
				});
				let response = data.some((status) => status === true);
				if (response) {
					return true;
				}
				return false;
			}
			return false;
		},
		filterDataTsDetails(item) {
			return item.tsDays
				.map(({ id, validated, tsDayDetails }) =>
					tsDayDetails.length ? { id, validated, tsDayDetails, solveStatus: this.isCheckTsDetails(tsDayDetails) } : null
				)
				.filter(Boolean);
		},
		sendAllForValidation(item, rowIndex) {
			if (item) {
				const { tsDays } = item;
				const { NOT_SUBMITTED } = VALIDATION_TYPE;
				const conditions = [NOT_SUBMITTED];
				const uniqueID = tsDays.filter(({ validated: v }) => conditions.includes(v)).map(({ id }) => +id);
				if (uniqueID && uniqueID.length !== 0) {
					this.$emit('row-validate-change', { generateNewID: uniqueID, rowIndex });
				}
				this.hideContextMenu();
			}
		},
		async validateAllTsDays(item, rowIndex) {
			const { NOT_SUBMITTED, VALIDATED_LEVEL_1, REFUSED_LEVEL_1, VALIDATED_LEVEL_2, REFUSED_LEVEL_2 } = VALIDATION_TYPE;
			const { CHARG_PROD, ADM_PROD } = PLANNING_LEVEL;
			const filterConditions = (data, conditions) => data.filter(({ validated: v, solveStatus: s }) => conditions.every((cond) => v !== cond) && !s);

			let data = this.filterDataTsDetails(item);
			const validationLevel = this.currentValidationLevel;
			if (this.isProduction) {
				const conditions =
					validationLevel === CHARG_PROD
						? [NOT_SUBMITTED, VALIDATED_LEVEL_1, VALIDATED_LEVEL_2, REFUSED_LEVEL_2]
						: validationLevel === ADM_PROD
						? [NOT_SUBMITTED, VALIDATED_LEVEL_2]
						: [];
				data = filterConditions(data, conditions);
			}
			if (data.length !== 0) {
				const conditions = [REFUSED_LEVEL_1, REFUSED_LEVEL_2];
				const mapType = (data) => {
					const isHaveReject = data.some(({ validated: v }) => conditions.includes(v));
					return {
						isHaveReject,
						generateItems: data,
						uniqueID: data.map(({ id }) => +id)
					};
				};
				const { isHaveReject, generateItems, uniqueID } = mapType(data);
				const onValidatedItems = (uniqueID, filterRefused) => {
					const generateNewID = filterRefused ? generateItems.filter(({ validated: v }) => !conditions.includes(v)).map(({ id }) => +id) : uniqueID;
					return this.$emit('row-validate-change', { generateNewID, rowIndex });
				};
				if (isHaveReject) {
					try {
						const message = this.FormMSG(500, 'In this line, there are rejected items. Would you like to validate them?');
						return this.confirmModal(
							() => onValidatedItems(uniqueID, false),
							message,
							'danger',
							() => onValidatedItems(uniqueID, true)
						);
					} catch (e) {
						return false;
					}
				} else {
					return onValidatedItems(uniqueID, false);
				}
			}
			this.hideContextMenu();
		},
		haveSolved(item) {
			const { NOT_SUBMITTED, SUBMITTED, VALIDATED_LEVEL_1, REFUSED_LEVEL_1 } = VALIDATION_TYPE;
			const { CHARG_PROD, ADM_PROD } = PLANNING_LEVEL;
			let data = this.filterDataTsDetails(item);
			if (this.isProduction) {
				const level = this.currentValidationLevel;
				const filterLevel =
					level === CHARG_PROD
						? (v) => ![NOT_SUBMITTED, VALIDATED_LEVEL_1].includes(v)
						: level === ADM_PROD
						? (v) => ![NOT_SUBMITTED].includes(v)
						: () => true;

				data = data.filter(({ validated: v }) => filterLevel(v));
				if (data.every(({ solveStatus: s }) => !s)) return true;
				const isLevelOne = data.some(({ validated: v }) => [SUBMITTED, REFUSED_LEVEL_1].includes(v));
				const isLevelTwo = data.some(({ validated: v }) => [VALIDATED_LEVEL_1, REFUSED_LEVEL_1].includes(v));

				if ((level === CHARG_PROD && isLevelOne) || (level === ADM_PROD && isLevelTwo)) return false;
			}
			return !data.length;
		},
		checkValidatedAll(item) {
			const { NOT_SUBMITTED, SUBMITTED, VALIDATED_LEVEL_1, REFUSED_LEVEL_1, REFUSED_LEVEL_2, VALIDATED_LEVEL_2 } = VALIDATION_TYPE;
			const { CHARG_PROD, ADM_PROD } = PLANNING_LEVEL;
			let data = this.isProduction ? this.filterDataTsDetails(item) : [];
			const level = this.currentValidationLevel;

			if (level === CHARG_PROD) {
				data = data.filter(
					({ validated: v, solveStatus: s }) => ![NOT_SUBMITTED, VALIDATED_LEVEL_1, VALIDATED_LEVEL_2, REFUSED_LEVEL_2].includes(v) && !s
				);
			} else if (level === ADM_PROD) {
				const { FINAL, CLOSED } = STATUS_VALIDATE_PLANNING;
				const isLastActionG_PROD = [FINAL, CLOSED].includes(this.previousPlanningStatus);
				const newData = data.filter(({ validated: v }) => ![NOT_SUBMITTED, VALIDATED_LEVEL_2].includes(v));
				data = this.isGestionnaryOfPaie && isLastActionG_PROD ? newData : [];
			}

			if (data.length) {
				const isLevelOne = data.some(({ validated: v }) => [SUBMITTED, REFUSED_LEVEL_1].includes(v));
				const isLevelTwo = data.some(({ validated: v }) => [VALIDATED_LEVEL_1, REFUSED_LEVEL_1].includes(v));

				if ((level === CHARG_PROD && isLevelOne) || (level === ADM_PROD && isLevelTwo)) {
					return false;
				}
			}

			return !data.length;
		},
		generateCellSelected(_item, raw) {
			const arrays = [...this.dataForPlanning];
			let defaultSelected = [];
			let tsDay = null;
			let securedId = null;
			let locations = null;
			let key = null;
			let tsDays = [];
			let item = null;
			if (_item && _item.tsDay) {
				item = _item;
				let headerLocation = this.tsfields.find((_item) => _item.key === formatDatesInString(item.tsDay.date, 'DD/MM/YYYY'));
				const { date } = item.tsDay;
				tsDay = item.tsDay;
				tsDays = item.tsDay;
				key = formatDatesInString(date, 'DD/MM/YYYY'); // date format 01/01/2023;
				locations = headerLocation ? headerLocation.locations : null;
				securedId = this.generateSecureIdDefault(item, key);
				const isNoActiveChift = true;
				const cell = { securedId, item, locations, key, tsDay, isNoActiveChift };
				const generateRaw = raw ? raw : this.graphiquePlacement(key, _item);
				const data = [this.generateCellsItem(cell, generateRaw)];
				defaultSelected = data;
			}
			return { data: arrays.length > 0 ? arrays : defaultSelected, tsDay, tsDays, securedId, securedId, key, item };
		},
		filterTypeDataAuth(data) {
			const { NOT_SUBMITTED } = VALIDATION_TYPE;
			return data.filter(({ tsDay }) => tsDay !== undefined && (this.isProduction ? tsDay.validated !== NOT_SUBMITTED : true));
		},
		async onMenuItemClick(option, raw) {
			const { SIMPLE, ASK, UNDELED } = TYPE_DELETED;
			const {
				SOLVE_UPDATE,
				SEE_INFO,
				COPY,
				PASTE,
				CLEAR_COPY,
				TRACE,
				REPLACE,
				DELETE,
				ASK_DELETE,
				UNDELETE,
				SEND_FOR_VALIDATION,
				VALIDATE,
				REJECT,
				VALIDATE_ALL,
				SEE_INFO_MULTIPLE,
				UNTRACE
			} = OPTION_CONTEXT_MENU;
			const { NOT_SUBMITTED, VALIDATED_LEVEL_2 } = VALIDATION_TYPE;
			const { ADM_PROD } = PLANNING_LEVEL;
			const { SENT_TO_PAY } = INTERNAL_STATUS;
			const { data } = this.generateCellSelected(this.selectedItem, raw);
			const defaultData = this.filterTypeDataAuth(data);
			if (option === SOLVE_UPDATE) {
				return this.$emit('showModalPlanningContextUpdate', defaultData);
			}
			if (option === VALIDATE) {
				if (data.length !== 0) {
					const newItem = data[0].tsDay;
					return this.$emit('validateTsDays', newItem);
				}
			}
			if (option === REJECT) {
				const newData = defaultData.map((item) => item.tsDay);
				const rejectData = newData.filter((item) => !_.isNil(item) && ![VALIDATED_LEVEL_2].includes(item.validated));
				return this.$emit('onOpenRejectModal', { successModalRejectedTsDay: true, rejectData });
			}
			if (option === SEE_INFO) {
				const { fullName } = defaultData[0];
				this.showDetailData = { ...defaultData[0].tsDay, fullName };
				this.$emit('showModalDetailsCell', { showDetailModalCell: true, showDetailData: this.showDetailData });
			}
			if (option === REPLACE) {
				const payload = [{ ...defaultData[0] }];
				return this.$emit('replacement', payload);
			}
			if (option === ASK_DELETE) {
				const key = ASK;
				return this.$emit('deleteItemPlanning', defaultData, { key, undo: false });
			}
			if (option === DELETE) {
				const key = SIMPLE;
				return this.$emit('deleteItemPlanning', defaultData, { key, undo: false });
			}
			if (option === UNDELETE) {
				const key = UNDELED;
				return this.$emit('deleteItemPlanning', defaultData, { key, undo: true });
			}
			if (option === SEND_FOR_VALIDATION) {
				const uniqueID = defaultData
					.map(({ tsDay }) => tsDay)
					.filter(({ validated: v }) => v === NOT_SUBMITTED)
					.map(({ id }) => +id);
				return this.$emit('row-validate-change', { generateNewID: uniqueID, rowIndex: null });
			}
			if (option === VALIDATE_ALL) {
				const items = { tsDays: defaultData.map(({ tsDay }) => tsDay) };
				return this.validateAllTsDays(items, null);
			}
			if (option === SEE_INFO_MULTIPLE) {
				this.$emit('showModalDetailsCellMultiple', { showDetailModalCellMultiple: true, showDetailDataMultiple: defaultData });
			}
			if (option === COPY) {
				setTimeout(async () => {
					this.showMenu = false;
					this.showMenuMultiple = false;
					this.textToCopy = JSON.stringify(defaultData);
					this.$refs['ContextMenu'].$refs['menu'].hide();
					this.$refs['ContextMenu'].optionContextMenu = null;
					await this.copyText();
					this.hideContextMenu();
				}, 400);
			}
			if (option === PASTE) {
				const onCopied = async () => {
					this.showMenu = false;
					this.showMenuMultiple = false;
					this.$refs['ContextMenu'].$refs['menu'].hide();
					this.$refs['ContextMenu'].optionContextMenu = null;
					const response = await this.pasteText();
					const dataToSend = [...JSON.parse(response)];
					const copiedIn = data
						.filter((item) => item.tsDay === undefined || item.tsDay === null)
						.map((item) => ({ date: item.date.toString().split('/').reverse().join('-') + 'T00:00:00Z', userId: item.userId }));
					const tsDayIdToCopied = +dataToSend[0].tsDay.id;
					const result = await addTsDaysUsersForPlanning(copiedIn, tsDayIdToCopied, false)
						.then((responses) => {
							if (responses) {
								this.createToastForMobile('Success', 'copied with success');
								this.hideContextMenu();
								this.$emit('quick-change-load:submitted', { responses });
							} else {
								this.createToastForMobile('Error', 'copied failed', '', 'danger');
								this.hideContextMenu();
							}
						})
						.catch((_) => {
							this.createToastForMobile('Error', 'copied failed', '', 'danger');
							this.hideContextMenu();
						});
					return result;
				};
				return await this.showLoader(onCopied, 'fimalac-planning-item');
			}
			if (option === CLEAR_COPY) {
				this.showMenu = false;
				this.showMenuMultiple = false;
				this.$refs['ContextMenu'].$refs['menu'].hide();
				this.$refs['ContextMenu'].optionContextMenu = null;
				this.textToCopy = '';
				this.pastedText = '';
				this.hideContextMenu();
			}
			if (option === TRACE || option === UNTRACE) {
				let completed = option === TRACE;
				this.showMenu = false;
				this.showMenuMultiple = false;
				this.$refs['ContextMenu'].$refs['menu'].hide();
				this.$refs['ContextMenu'].optionContextMenu = null;
				this.textToCopy = '';
				this.pastedText = '';
				this.hideContextMenu();
				const action = async () => {
					const tsDaysIdsTracingByADM_PROD = defaultData
						.map(({ tsDay }) => tsDay)
						.filter(({ validated: v, internalStatus: status_paie }) => [VALIDATED_LEVEL_2].includes(v) || [SENT_TO_PAY].includes(status_paie))
						.map(({ id }) => +id);
					const tsDaysIds = defaultData
						.map(({ tsDay }) => {
							if (tsDay) {
								const { id } = tsDay;
								return +id;
							}
							return null;
						})
						.filter(Boolean);
					const newTsDayID = this.currentValidationLevel === ADM_PROD && !this.isGestionnaryOfPaie ? tsDaysIdsTracingByADM_PROD : tsDaysIds;
					const result = await updateCompletionStatus(newTsDayID, completed).then((res) => {
						if (res) {
							const confirmMessage = completed
								? this.FormMSG(401, 'The tsDay was successfully traced')
								: this.FormMSG(405, 'The tsDay was successfully untraced');
							this.createToastForMobile('Success', confirmMessage);
							this.$emit('refersh-on-planning');
							return res;
						}
						return false;
					});
					return result;
				};
				const message = completed ? this.FormMSG(402, 'Do you really want to trace ?') : this.FormMSG(404, 'Do you really want to untrace ?');
				this.confirmModal(action, message, 'success');
			}
		},
		async copyText() {
			return await navigator.clipboard
				.writeText(this.textToCopy)
				.then((text) => {
					this.pastedText = '';
					this.copied = true;
					this.createToastForMobile('Success', this.FormMSG(403, 'copied item successfully'));
					setTimeout(() => {
						this.copied = false;
					}, 2000);
					return text;
				})
				.catch((err) => {
					return err;
				});
		},
		async pasteText() {
			return await navigator.clipboard
				.readText()
				.then((text) => {
					this.pastedText = text;
					return text;
				})
				.catch((err) => {
					return err;
				});
		},
		checkDeletePlanning(raw) {
			const { NOT_SUBMITTED } = VALIDATION_TYPE;
			const { data: new_data } = this.generateCellSelected(this.selectedItem, raw);
			const defaultData = this.filterTypeDataAuth(new_data);
			if (defaultData.length !== 0) {
				if (this.isProduction) {
					let data = defaultData.map(({ tsDay }) => tsDay).filter((item) => item.validated !== NOT_SUBMITTED && item.tsDayDetails.length !== 0);
					if (data.length !== 0) {
						return true;
					}
					return false;
				} else {
					if (defaultData.length !== 0) {
						return true;
					}
					return false;
				}
			}
			return false;
		},
		checkNotSubmitted(item) {
			const { NOT_SUBMITTED } = VALIDATION_TYPE;
			const { MANAGER } = PLANNING_LEVEL;
			let data = this.filterDataTsDetails(item).filter(({ validated: v }) => [NOT_SUBMITTED].includes(v));
			if (data.length !== 0) {
				if (this.isProduction === false && this.currentValidationLevel === MANAGER && this.statusPlanningValidateAll !== 0) return false;
				if (this.isProduction && this.currentValidationLevel !== MANAGER) return false;
				if (this.isProduction === false && this.currentValidationLevel !== MANAGER) return false;
				return true;
			}
			return true;
		},
		handleResize(e) {
			let tableScrollBody = this.$refs['my-table'];
			if (tableScrollBody !== undefined) {
				let widthTable = 0;
				if (tableScrollBody && tableScrollBody.$el) {
					widthTable = tableScrollBody.$el.clientWidth;
				}
				this.windowWidth = widthTable;
				this.resizeScreen(e);
			}
		},
		elementHeaderRow(indexField, width) {
			const refCellInfo = this.$refs[`cell-info-${indexField - 1}`];
			if (refCellInfo) {
				[...refCellInfo].forEach((option) => {
					option.newSizeWidthCellContent = width;
				});
			}
		},
		clearFixedAppBar() {
			const headRefLocation = Array.from(document.querySelectorAll('th'));
			const itemTh = headRefLocation;
			for (let i = 0; i < itemTh.length; i++) {
				itemTh[i].classList.remove('fixed-app-bar');
				itemTh[i].style.top = `0px`;
			}
		},
		activeFixedAppBar() {
			const headRefLocation = Array.from(document.querySelectorAll('th'));
			const itemTh = headRefLocation;
			for (let i = 0; i < itemTh.length; i++) {
				itemTh[i].classList.add('fixed-app-bar');
				itemTh[i].style.setProperty('top', `${Math.abs(this.isFixedAppBarValue - 2) || 55}px`, 'important');
			}
		},
		resizeScreen(event) {
			event.preventDefault();
			this.isResizeScreen = !this.isResizeScreen;
			this.clearFixedAppBar();
			if (this.isResizeScreen) {
				if (this.isFixedAppBar) {
					this.activeFixedAppBar();
					return true;
				} else {
					this.clearFixedAppBar();
					return true;
				}
			} else {
				if (this.isFixedAppBar) {
					this.activeFixedAppBar();
					return true;
				} else {
					this.clearFixedAppBar();
					return true;
				}
			}
		},
		handleScroll(event) {
			const scrollTop = event.target.scrollTop;
			const headerContext = document.getElementById('header-context');
			const headerOption = document.getElementById('headerBarOption');
			let headerOptionHeight = 0;
			if (headerOption) {
				let { height } = headerOption.getBoundingClientRect();
				headerOptionHeight = height;
			}
			let _headerContext = headerContext ? headerContext.offsetHeight + headerOptionHeight : 0;
			let isFixed = scrollTop - (_headerContext + 36);
			this.isFixedAppBarValue = isFixed;
			if (isFixed > 0) {
				this.isFixedAppBar = true;
				this.activeFixedAppBar();
			} else {
				this.isFixedAppBar = false;
				this.clearFixedAppBar();
			}
		},
		handleHeaderScroll(event) {
			const tableScrollBody = this.$refs['my-table'];
			if (tableScrollBody !== undefined) {
				this.hideContextMenuScrolled();
				tableScrollBody.$el.scrollTo({ left: event.target.scrollLeft });
			}
		},
		handleContentScroll(event) {
			let headBar = document.getElementById('headBar');
			const headBarOption = document.getElementById('headerBarOption');
			this.hideContextMenuScrolled();
			if (headBar) {
				headBar.scrollTo({ left: event.target.scrollLeft });
			}
			if (headBarOption) {
				headBarOption.scrollTo({ left: event.target.scrollLeft });
			}
		},
		onScroll(event) {
			this.hideContextMenuScrolled();
			const size = this.mapPrestations.length;
			if (size < this.totalOfUsers && event.target.scrollTop) {
				if (Math.ceil(event.target.scrollTop + event.target.clientHeight + 1) >= event.target.scrollHeight) {
					if (!this.isBusy) {
						if (event.target.scrollLeft) {
							this.isBusy = false;
						}
						const recordOffSet = 1;
						this.$emit('getDataFromPagePlanning', recordOffSet);
					}
				}
			}
		},
		startScrollingLeft(event) {
			event.preventDefault();
			const scrollContainer = this.$refs['my-table'].$el;
			this.isDragging = true;
			this.startX = event.clientX;
			this.scrollLeftStart = scrollContainer.scrollLeft;
		},
		stopScrollingLeft(event) {
			event.preventDefault();
			const scrollContainer = this.$refs['my-table'].$el;
			scrollContainer.removeEventListener('mousedown', this.startScrollingLeft);
		},
		detectDirection(event) {
			event.preventDefault();
			if (this.isDragging) {
				const mouseX = event.clientX;
				const scrollContainer = this.$refs['my-table'].$el;
				if (mouseX > this.startX) {
					scrollContainer.scrollLeft = this.scrollLeftStart + (mouseX - this.startX);
				} else if (mouseX < this.startX) {
					scrollContainer.scrollLeft = this.scrollLeftStart - (this.startX - mouseX);
				}
			}
		},
		stopDrag() {
			this.isDragging = false;
		}
	}
};
</script>
<style scoped lang="scss">
.middle {
	vertical-align: middle;
}
.show-hover:hover {
	background-color: #cad0d7;
	border-radius: 5px;
	cursor: pointer;
}
.is-need-to-edit {
	background-color: #bdb2b2;
}
.edit-background {
	background-color: #f3f4f5;
}
.init-background {
	background-color: #ffff;
}
.sticky-column-body-left {
	position: sticky;
	left: 0;
	z-index: 25;
	background-color: #fff;
	width: 150px;
}
.sticky-column-body-right {
	position: sticky;
	right: 0;
	z-index: 25;
	background-color: rgba(226, 230, 235, 0.85);
	border-left: 1px solid #06263e !important;
	height: auto;
}
.sticky-column-body-right-init {
	position: sticky;
	right: 0;
	z-index: 1;
	background-color: #fff;
	width: 10xp;
}
.hover-of-column {
	background-color: hsla(206, 96%, 90%, 0.5);
}
.hover-of-row-column {
	background-color: hsla(206, 96%, 90%, 0.5);
}
.hover-of-row-column-fixed {
	background-color: hsl(206, 96%, 90%);
}
.hover-not-empty:hover {
	background-color: hsla(206, 96%, 90%, 0.5);
}
.hover-is-empty:hover {
	background-color: rgba(71, 199, 191, 0.5);
}
.default-cell-content {
	border: 0.005rem solid rgba(226, 230, 235, 0.85) !important;
	vertical-align: middle;
}
.menu-context {
	height: 30px;
}
.menu-context:hover {
	background-color: rgba(71, 199, 191, 0.5);
	border-radius: 10px;
}

.show-total {
	position: absolute;
	width: 100% !important;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: end;
	margin-top: -36px;
	//padding: 10px;
}
.loading,
.end-of-list {
	color: rgba(6, 38, 62, 0.84);
	font-weight: 900;
	font-size: 0.95rem;
}
.bars-5 {
	width: 20px;
	height: 10px;
	aspect-ratio: 0.75;
	--c: no-repeat linear-gradient(rgba(6, 38, 62, 0.84) 0 0);
	background: var(--c) 0% 100%, var(--c) 50% 100%, var(--c) 100% 100%;
	background-size: 20% 65%;
	animation: b5 1s infinite linear;
}
@keyframes b5 {
	20% {
		background-position: 0% 50%, 50% 100%, 100% 100%;
	}
	40% {
		background-position: 0% 0%, 50% 50%, 100% 100%;
	}
	60% {
		background-position: 0% 100%, 50% 0%, 100% 50%;
	}
	80% {
		background-position: 0% 100%, 50% 100%, 100% 0%;
	}
}

::-webkit-scrollbar {
	width: 10px;
	background: #eee;
}
::-webkit-scrollbar:horizontal {
	height: 10px;
}
::-webkit-scrollbar-button {
	width: 5px;
	height: 5px;
	background: #666;
}
::-webkit-scrollbar-piece {
	background: #eee;
}
::-webkit-scrollbar-piece:start {
	background: #eee;
}
::-webkit-scrollbar-thumb {
	background: #333;
}
::-webkit-scrollbar-corner {
	background: #333;
}

.w-18 {
	width: 18%;
}
.w-82 {
	width: 82%;
}

.gap-1 {
	gap: 4px;
}
.gap-10 {
	gap: 10px;
}
.card-gray {
	padding: 16px;
	background: #f5f7f9;
}

.item {
	display: flex;
	align-items: center;
	flex-direction: row;
	width: 100%;
	margin-left: 22px;
	height: 20px;
	text-transform: capitalize;
	font-weight: 700 !important;
}

#headBar::-webkit-scrollbar {
	height: 0px;
}
.w-60 {
	width: 60% !important;
}
.w-30 {
	width: 30% !important;
}
.fixed-app-bar {
	position: fixed !important;
	animation-duration: 0.5s;
	animation-name: showTransitionBar;
}

@keyframes showTransitionBar {
	from {
		height: 0;
	}
	to {
		height: 76px;
	}
}

.row-header-fixed {
	position: sticky !important;
	left: 0 !important;
	z-index: 100 !important;
	background-color: #00a09c !important;
	border: 1px solid #dee2e6 !important;
	color: #ffff !important;
	top: -1px !important;
}
.row-header-locations {
	background-color: #00a09c !important;
	border: 1px solid #dee2e6 !important;
	color: #ffff !important;
	top: -1px !important;
	z-index: 50;
}
.default-data-empty {
	border: 0.005rem solid rgba(226, 230, 235, 0.85) !important;
	height: 50px !important;
}
.default-data-no-empty {
	border: 0.005rem solid rgba(226, 230, 235, 0.85) !important;
}
.default-row-some {
	background: #cad0d7;
	width: auto;
	height: 110px;
	border-radius: 8px 0px 0px 8px;
	border: 0.005rem solid rgba(226, 230, 235, 0.85);
	width: 14px;
	margin-left: 22px;
}
.default-body-right {
	background: rgb(202, 208, 215) !important;
	width: 14px !important;
	height: 110px !important;
	border: 0.005rem solid rgba(226, 230, 235, 0.85) !important;
}
.bg-row-today {
	background-color: hsla(206, 96%, 90%, 0.5);
}
.tracing-manager {
	background-color: #a0d2f780;
}
.tracing-charge-production {
	background-color: #fce0d5;
}
.tracing-production-admin {
	background-color: #e6ffe6;
}
.tracing-charge-paied {
	background-color: #e5d1ed;
}
.bg-header-today {
	background-color: #036d6b !important;
}

.scroll-container {
	display: flex;
	overflow-x: scroll;
	width: 100%;
	scrollbar-width: none;
	-ms-overflow-style: none;
	background-color: rgb(243, 244, 245);
}
.scroll-item-sticky {
	flex: 0 0 auto;
	width: auto;
	height: 25px;
	background-color: rgb(243, 244, 245);
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	position: sticky !important;
	z-index: 10 !important;
	button {
		display: flex;
		align-items: center;
		border: none;
		font-weight: 700;
		font-size: 14px;
		justify-content: center;
		border-radius: 4px 4px 0px 0px;
		background-color: #8fb5bd;
		height: 24px;
		width: 25px;
		&:hover {
			border: 1px solid black;
		}
	}
}
.scroll-item-start {
	display: flex;
	flex: 0 0 auto;
	width: auto;
	height: 25px;
	color: black;
	align-items: flex-start;
	justify-content: flex-start;
	position: relative;
	button {
		display: flex;
		align-items: center;
		border: none;
		font-weight: 700;
		font-size: 14px;
		justify-content: center;
		border-radius: 4px 4px 0px 0px;
		// position: absolute;
		// float: right;
		// z-index: 1;
		// right: -10px;
		background-color: #8fb5bd;
		height: 24px;
		&:hover {
			border: 1px solid black;
		}
	}
}
.scroll-item {
	display: flex;
	flex: 0 0 auto;
	width: auto;
	height: 25px;
	color: black;
	align-items: flex-end;
	justify-content: flex-end;
	position: relative;
	button {
		display: flex;
		align-items: center;
		border: none;
		font-weight: 700;
		font-size: 14px;
		justify-content: center;
		border-radius: 4px 4px 0px 0px;
		position: absolute;
		float: left;
		z-index: 1;
		left: -10px;
		background-color: #8fb5bd;
		height: 24px;
		&:hover {
			border: 1px solid black;
		}
	}
}
</style>
<style lang="scss">
.default-location {
	.btn-hide-cell-header {
		display: none;
	}
	&:hover {
		.btn-hide-cell-header {
			display: flex;
			position: absolute !important;
			flex-direction: row;
			justify-content: end;
			align-items: center;
			bottom: 44px;
			font-size: 0.8rem;
			flex: 1;
			.btn-close-hide-header {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 20px;
				width: 20px;
				font-weight: 400;
				color: #fff;
				background: #ea4e2c;
				border-color: #ea4e2c;
				outline: none;
				border-radius: 50%;
				border: none;
			}
		}
	}
}
.popover-header-option {
	.popover-body {
		width: auto;
		max-width: 250px !important;
	}
}
.popover:has(.row-today-popover) {
	background-color: #000 !important;
	top: -120px !important;
	.arrow::before {
		display: none;
	}
	.arrow::after {
		border-bottom-color: #000 !important;
		top: 42px !important;
		transform: rotate(180deg) !important;
	}
	.popover-body {
		.row-today-popover {
			color: #fff !important;
			font-weight: 700;
		}
	}
}
.resizable {
	cursor: w-resize;
	user-select: none;
}
.cursor-wait {
	cursor: wait !important;
}
.margin-before-last-header-active {
	margin-right: 50px;
}
.margin-last-header-active {
	left: -50px;
}
</style>
